import { createSelector } from "reselect";

const selectRaw = (state) => state.billing.list;

const selectLoading = createSelector([selectRaw], (raw) => raw.loading);

const selectRows = createSelector([selectRaw], (raw) => raw.rows);

const selectDownloadRows = createSelector(
  [selectRaw],
  (raw) => raw.downloadRows
);

const selectAtributes = createSelector([selectRaw], (raw) => raw.atributes);

const selectCount = createSelector([selectRaw], (raw) => raw.count);

const selectHasRows = createSelector([selectRaw], (raw) => raw.count > 0);

const selectSorter = createSelector([selectRaw], (raw) => raw.sorter || {});

const selectOrderBy = createSelector([selectRaw], (raw) => {
  const sorter = raw.sorter;

  if (!sorter) {
    return null;
  }

  if (!sorter.field) {
    return null;
  }

  let direction = sorter.order === "desc" ? "DESC" : "ASC";

  return `${sorter.field} ${direction}`;
});

const selectFilter = createSelector([selectRaw], (raw) => {
  return raw.filter;
});

const selectLimit = createSelector([selectRaw], (raw) => {
  const pagination = raw.pagination;
  return pagination.pageSize;
});

const selectOffset = createSelector([selectRaw], (raw) => {
  const pagination = raw.pagination;

  if (!pagination || !pagination.pageSize) {
    return 0;
  }

  const current = pagination.current || 1;

  return current - 1;
});

const selectPagination = createSelector(
  [selectRaw, selectCount],
  (raw, count) => {
    return {
      ...raw.pagination,
      total: count,
    };
  }
);

const selectTypeOfBills = createSelector([selectRaw], (raw) => raw.typeOfBills
.map((e) => ({
  label: e,
  value: e,
})).sort((a, b) => a?.label?.localeCompare(b.label))
);

const selectDownload = createSelector([selectRaw], (raw) => raw.download);

const billingListSelectors = {
  selectLoading,
  selectRows,
  selectDownloadRows,
  selectCount,
  selectHasRows,
  selectAtributes,
  selectSorter,
  selectOrderBy,
  selectLimit,
  selectFilter,
  selectOffset,
  selectPagination,
  selectTypeOfBills,
  selectDownload
};

export default billingListSelectors;
