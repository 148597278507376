import actions from './exportTrucksActions';

const INITIAL_PAGE_SIZE = 10;

const initialState = {
	rows: [],
	count: 0,
	loading: false,
	filter: {},
	pagination: {
		current: 1,
		pageSize: INITIAL_PAGE_SIZE,
	},
	sorter: {},
	filterLabel: {},

};

const exportTrucksReducers = (state = initialState, { type, payload }) => {
	if (type === actions.RESET_PAGINATION) {
		return {
			...state,
			pagination: {
				current: 1,
				pageSize: INITIAL_PAGE_SIZE,
			},
		};
	}

	if (type === actions.FETCH_STARTED) {
		return {
			...state,
			loading: true,
			pagination: state.pagination,
		};
	}

	if (type === actions.FETCH_SUCCESS) {
		return {
			...state,
			loading: false,
			rows: payload.data,
			count: payload.total,
		};
	}

	if (type === actions.FETCH_ERROR) {
		return {
			...state,
			loading: false,
			rows: [],
			count: 0,
		};
	}

	if (type === actions.PAGINATION_CHANGED) {
		return {
			...state,
			pagination: payload || {
				current: 1,
				pageSize: INITIAL_PAGE_SIZE,
			},
		};
	}

	if (type === actions.SORTER_CHANGED) {
		return {
			...state,
			sorter: payload || {},
		};
	}

	if( type === actions.CHANGE_FILTER_LABELS) {
    return {
      ...state,
      filterLabel: payload,
    };
  }

	if (type === actions.CHANGE_FILTER) {
		return {
			...state,
			filter: payload,
		};
	}

	return state;
};

export default exportTrucksReducers;
