import authAxios2 from "../shared/axios/authAxios2";
import authAxios3 from "../shared/axios/authAxios3";
import Message from "src/view/shared/message";

export default class EmptyService {
  //tabla stakeholders
  static async getStakeholder(orderBy?, limit?, offset?, filter?) {
    const params = {
      sort: orderBy,
      size: limit,
      page: offset,
      clientCompanyId: filter?.clientCompanyId || undefined,
      stakeholderId: filter?.stakeholderId || undefined,
    };
    const response = await authAxios2.get(`/portal/stakeholder/`, { params });
    return response.data;
  }

  static async getStakeholdersNotPagination(orderBy, limit, offset, filter) {
    const params = {
      sort: orderBy,
      size: limit,
      page: offset,
      filter: filter,
    };

    const response = await authAxios2.get(`/portal/stakeholder/`, {
      params,
    });
    return response.data;
  }

  static async getStakeholderByCompanyId(clientCompanyId?) {
    const params = {
      filter: {
        clientCompanyId: clientCompanyId
      }
    };

    const response = await authAxios2.get(`/portal/stakeholder`, { params });
    return response.data;
  }

  static async getAttachment(emptyHeaderId: number) {
    const response = await authAxios2.get(`/portal/emptyHeader/${emptyHeaderId}/attachment`);
    return response.data;
  }

  static async updateStakeholder(orderId, stakeholderId) {
    const params = {
      stakeholderId,
    };

    const response = await authAxios2.put(`/portal/order/${orderId}/stakeholder`, null, { params });
    Message.success(`${response.data.message}`)
    return response.data;
  }

  static async removeStakeholder(orderId) {
    const response = await authAxios2.delete(
      `/portal/order/${orderId}/stakeholder`
    );
    Message.success(`${response.data.message}`)

    return response.data;
  }

  static async bookingForwarder(data) {
    const response = await authAxios2.get(
      `/portal/order/booking-forwarder?booking=${data.booking}&cuit=${data.cuit}`
    );
    // Message.success(`${response?.data?.message}`)

    return response.data;
  }

  static async stakeholderForwarder(data) {
    const response = await authAxios2.put(
      `/portal/order/${data.orderId}/stakeholder?stakeholderId=${data.stakeholderId}`
    );
    return response.data;
  }

  static async autoStakeholderForwarder(data, stakeholderId?) {
    const params = {
      stakeholderId: stakeholderId,
      booking: data.booking,
      CompanyId: data.CompanyId,
    }
    const response = await authAxios2.put(
      `/portal/order/assign-booking`, null, { params }
    );//chequear que pasaria si no se envia un stakeholder id

    return response.data;
  }



  static async cancelFormContainerEmpty(data) {
    const response = await authAxios2.put(
      `/portal/formEmptyOrFullRemoval/deactivateForm`,
      data
    );
    return response.data;
  }

  static async createEmptyHeader(data, files = []) {
    let formData = new FormData();
    for (let file of files) {
      formData.append("file", file);
    }
    const emptyHeader = JSON.stringify(data);
    const blob = new Blob([emptyHeader], {
      type: "application/json",
    });

    formData.append("emptyHeader", blob);

    const response = await authAxios3.post(`/portal/emptyHeader`, formData);

    return response.data;
  }

  static async emptyHeaderStatus() {
    const response = await authAxios2.get(`/portal/emptyHeader/status`)
    return response.data
  }

  static async allEmptyHeader(orderBy, limit, offset, filter) {
    const params = {
      sort: orderBy,
      size: limit,
      page: offset,
      filter: filter,
    };
    const response = await authAxios2.get(`/portal/emptyHeader`, {
      params,
    });
    return response.data;
  }

  static async createEmptyBody(data) {
    const response = await authAxios2.post(`/portal/emptyBody`, data);
    return response.data;
  }

  static async EditEmptyBody(data) {
    const response = await authAxios2.patch(
      "/portal/emptyBody/edit-emptyBody",
      data
    );
    return response.data;
  }

  static async deleteEmptyBody(data) {
    const response = await authAxios2.delete(`/portal/emptyBody/${data}`);
    return response.data;
  }

  static async deleteEmptyHeader(data) {
    const response = await authAxios2.delete(`/portal/emptyHeader/${data}`);
    return response.data;
  }

  static async findEmptyHeaderById(data: any) {
    const response = await authAxios2.get(`/portal/emptyHeader/${data}`);
    return response.data;
  }


  static async findEmptyBodyByEmptyHeaderId(data: any) {
    const response = await authAxios2.get(`/portal/emptyBody/${data}`);
    return response.data;
  }

  static async emptyBodyByHeaderID(data, orderBy?, limit?, offset?) {
    const params = {
      sort: orderBy,
      size: limit,
      page: offset,
    };

    const response = await authAxios2.get(`/portal/emptyBody/emptyHeader/${data}`, { params })
    return response.data
  }

  static async createReservationEmptyBody(data) {
    const response = await authAxios2.post(
      `/portal/reservation/${data.turnId}/reservationByEmptyBody?date=${data.date}&emptyBodyId=${data.id}`
    );
    return response.data;
  }

  static async getclientCompanies(orderBy, limit, offset, filter) {
    const params = {
      sort: orderBy,
      size: limit,
      page: offset,
      filter: filter,
    };

    const response = await authAxios2.get(`/portal/clientCompany/`, {
      params,
    });

    return response.data;
  }
  static async getOrders(orderBy, limit, offset, filter) {
    const params = {
      sort: orderBy,
      size: limit,
      page: offset,
      filter: filter,
    };
    const response = await authAxios2.get(`/portal/order`, {
      params,
    });
    return response.data;
  }
  static async searchBooking(data) {
    const { bookingNumber, clientCompanyCUIT } = data;

    const response = await authAxios2.get(
      `/portal/navis/booking?booking=${bookingNumber}&cuit=${clientCompanyCUIT}`
    );
    return response.data;
  }

  static async searchCuit(data) {
    const response = await authAxios2.get(
      `/portal/softland/softlandByClienteFacturable?cuit=${data}`

    );
    return response.data;
  }

  static async scheduledOther(data) {

    const response = await authAxios2.post(
      `/portal/reservation/reservationOutOfRangeByEmptyBody?date=${data.date}&emptyBodyId=${data.id}`
    );
    return response.data;
  }
  //se obtiene el tipo de conenedor
  static async typeContainer(data) {
    const response = await authAxios2.get(
      `/portal/emptyBody/itemList?bookingNumber=${data}`
    );
    return response.data;
  }
}
