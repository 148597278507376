import authAxios2 from "../shared/axios/authAxios2";
import authAxios3 from "../shared/axios/authAxios3";

export default class ExpoFullService {
  static async getAllExpoFull(orderBy, limit, offset, filter) {
    const params = {
      sort: orderBy,
      size: limit,
      page: offset,
      filter: filter,
    };
    const response = await authAxios2.get(`portal/formFullDelivery/user/23`, {
      params,
    });
    return response.data;
  }

  static async getPreaviso(id) {
    const response = await authAxios2.get(`/portal/log/all/preaviso/${id}`);
    return response.data;
  }
  static async createFormExpoFull(data) {
    const response = await authAxios2.post(
      `/portal/formFullDelivery/newForm/`,
      data
    );
    return response.data;
  }

  static async cancelFormFull(data) {
    const response = await authAxios2.put(
      `/portal/formFullDelivery/deactivateForm/`,
      data
    );

    return response.data;
  }

  static async createHeaderFull(data) {
    const response = await authAxios2.post("/portal/fullHeader", data);
    return response.data;
  }

  static async findFullHeaderById(orderBy, limit, offset, filter, data) {
    const params = {
      sort: orderBy,
      size: limit,
      page: offset,
      filter: filter,
    };
    const response = await authAxios2.get(`/portal/fullHeader/${data}`, {
      params,
    });

    return response.data;
  }

  static async createFullBody(data) {
    const response = await authAxios2.post("/portal/fullBody", data);
    return response.data;
  }

  static async findFullBodyByFullHeaderId(
    data: any,
    orderBy?,
    limit?,
    offset?,
    filter?
  ) {
    const params = {
      sort: orderBy,
      size: limit,
      page: offset,
      filter: filter,
    };

    const response = await authAxios2.get(`/portal/fullBody/${data}`, {
      params,
    });

    return response.data;
  }

  static async getServicies(data: any) {
    const response = await authAxios2.get(`/portal/operation/${data}/services`);

    return response.data;
  }

  static async EditFullBody(data) {
    const response = await authAxios2.put("/portal/fullBody/", data);

    return response.data;
  }

  static async getAllFullHeader(orderBy, limit, offset, filter) {
    const params = {
      sort: orderBy,
      size: limit,
      page: offset,
      filter: filter,
    };
    const response = await authAxios2.get(
      `/portal/fullHeader/findAllFullHeader`,
      {
        params,
      }
    );
    return response.data;
  }

  static async fullHeaderStatus() {
    const response = await authAxios2.get(`/portal/fullHeader/status`);
    return response.data;
  }

  static async createReservationFullBody(data) {
    const params = {
      date: data.date,
      fullBodyId: data.id,
      cutOffdate: data.cutOffDate,
      validationCuttoffDate: Boolean(data.validationCuttffDate),
    }
    const response = await authAxios2.post(
      `/portal/reservation/${data.turnId}/reservationByFullBody`,null,{params}
    );
    return response.data;
  }

  static async getclientCompanies(orderBy, limit, offset, filter) {
    const params = {
      sort: orderBy,
      size: limit,
      page: offset,
      filter: filter,
    };
    const response = await authAxios2.get(`/portal/clientCompany/`, {
      params,
    });

    return response.data;
  }
  static async getOrders(orderBy, limit, offset, filter) {
    const params = {
      sort: orderBy,
      size: limit,
      page: offset,
      filter: filter,
    };
    const response = await authAxios2.get(`/portal/order`, {
      params,
    });

    return response.data;
  }
  static async searchBooking(orderBy, limit, offset, filter, data) {
    const { bookingNumber, clientCompanyCUIT } = data;
    const params = {
      sort: orderBy,
      size: limit,
      page: offset,
      filter: filter,
    };
    const response = await authAxios2.get(
      `/portal/navis/booking?booking=${bookingNumber}&cuit=${clientCompanyCUIT}`,
      {
        params,
      }
    );

    return response.data.data;
  }

  static async searchCuit(data) {
    const response = await authAxios2.get(
      `/portal/softland/softlandByClienteFacturable?cuit=${data}`
    );

    return response.data.data;
  }

  static async searchContainer(data, id) {
    const response = await authAxios2.get(
      `/portal/navis/cnt?cntNbr=${data}&fullHeaderId=${id}`
    );

    return response.data;
  }

  static async deleteHeader(data) {
    const response = await authAxios2.delete(`/portal/fullHeader/${data}`);
    return response.data;
  }

  static async deleteBody(data) {
    const response = await authAxios2.delete(`/portal/fullBody/${data.id}`);
    return response.data;
  }

  static async scheduledOtherFull(data) {
    const response = await authAxios2.post(
      `/portal/reservation/reservationOutOfRangeByFullBody?date=${data.date}&fullBodyId=${data.id}`
    );
    return response.data;
  }

  // static async getAllCommodity() {
  //   const response = await authAxios2.get(`/portal/commodity/`);
  //   return response.data;
  // }

  static async preAviso(id, pdf) {
    let formData = new FormData();
    formData.append("file", pdf);
    const response = await authAxios3.post(
      `/portal/fullBody/${id}/preaviso`,
      formData
    );
    return response.data;
  }

  static async createContianer(nroContainer) {
    const response = await authAxios3.post(
      `/portal/fullBody/alta-contenedor/${nroContainer}`
    );
    return response.data;
  }
}
