import actions from "./GeneralListActions";

const initialState = {
  vesselPhases: [],
  stakeholders: [],
  count: 0,
  loading: false,
  loadingBooking: false,
  filter: {},
  loadingCompanies: false,
  companies: {},
  commodity: [],
  ordersNotPagination: [],
  orderFilter: {},
  container: [],
  booking: {},
  bookingError: "",
  filterPreview: {},
  filterPreviewCC: {},
  filterPreviewBilling: {},
  filterPreviewReceipt: {},
};

const GeneralReducers = (state = initialState, { type, payload }) => {
  if (type === actions.GET_VESSEL_PHASES_STARTED) {
    return {
      ...state,
      loading: true,
    };
  }

  if (type === actions.GET_VESSEL_PHASES_SUCCESS) {
    return {
      ...state,
      loading: false,
      vesselPhases: payload,
    };
  }

  if (type === actions.GET_VESSEL_PHASES_ERROR) {
    return {
      ...state,
      loading: false,
      vesselPhases: [],
    };
  }

  if (type === actions.CLIENT_COMPANIES_NOT_PAGINATION_STARTED) {
    return {
      ...state,
      loadingCompanies: true,
    };
  }
  if (type === actions.CLIENT_COMPANIES_NOT_PAGINATION_SUCCESS) {
    return {
      ...state,
      loadingCompanies: false,
      companies: payload,
    };
  }
  if (type === actions.CLIENT_COMPANIES_NOT_PAGINATION_ERROR) {
    return {
      ...state,
      loadingFullBody: false,
      companies: [],
    };
  }

  if (type === actions.FETCH_STAKEHOLDERS_NOT_PAGINATION) {
    return {
      ...state,
      stakeholders: payload.list,
      total: payload.total,
    };
  }

  if (type === actions.GET_COMMODITY) {
    return {
      ...state,
      commodity: payload.data,
    };
  }

  if (type === actions.GET_COMMODITY_OPTIONS) {
    return {
      ...state,
      commodity: payload.data,
    };
  }

  if (type === actions.ORDERS_NOT_PAGINATION) {
    return {
      ...state,
      orders: payload.list.filter((f) => f.status === "NOT_ASSIGNED"),
      ordersNotPagination: payload.list,
    };
  }

  if (type === actions.CHANGE_ORDER_FILTER) {
    return {
      ...state,
      orderFilter: payload,
    };
  }
  if (type === actions.ERROR_GET_BOOKING_NAVIS) {
    return {
      ...state,
      bookingError: "Booking no encontrado",
      loadingBooking: false,
    };
  }

  if (type === actions.FETCH_BOOKING_STARTED) {
    return {
      ...state,
      booking: {},
      loadingBooking: true,
    };
  }

  if (type === actions.GET_BOOKING_NAVIS) {
    return {
      ...state,
      booking: payload,
      loadingBooking: false,
      cantContainer: [payload?.items?.item].reduce(
        (accumulator, item) => accumulator + item?.quantity,
        0
      ),
    };
  }

  if (type === actions.GET_FILTERPREVIEW_BILL) {
    return {
      ...state,
      filterPreviewBilling: payload,
    };
  }

  if (type === actions.GET_FILTERPREVIEW_CC) {
    return {
      ...state,
      filterPreviewCC: payload,
    };
  }

  if (type === actions.GET_FILTERPREVIEW_RECEIPT) {
    return {
      ...state,
      filterPreviewReceipt: payload,
    };
  }

  if (type === actions.GET_FILTERPREVIEW) {
    return {
      ...state,
      filterPreview: payload,
    };
  }

  return state;
};

export default GeneralReducers;
