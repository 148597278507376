import actions from 'src/modules/singleLoad/form/singleLoadFormActions';

const initialData = {
  initLoading: false,
  saveLoading: false,
  saveUserLoading:false,
  message: null,
  code: null,
  admin: null
};

export default (state = initialData, { type, payload }) => {

  if (type === actions.INIT_STARTED) {
    return {
      ...state,
      saveLoading: true,
      admin: null
    };
  }

  if (type === actions.CODE_RESET) {
    return {
      ...state,
      message: null,
      code: null
    };
  }
  
  if (type === actions.INIT_SUCCESS) {
    return {
      ...state,
      saveLoading: false,
      admin: payload
    };
  }

  if (type === actions.INIT_ERROR) {
    return {
      ...state,
      saveLoading: false,
      admin: null,
    };
  }

  if (type === actions.ADD_STARTED) {
    return {
      ...state,
      saveUserLoading: true,
      message: null
    };
  }

  if (type === actions.ADD_SUCCESS) {
    return {
      ...state,
      saveUserLoading: false,
      message: payload.message || payload.mensaje,
      code: payload.code
    };
  }

  if (type === actions.ADD_ERROR) {
    return {
      ...state,
      saveUserLoading: false,
      message: null,
      code: payload.code
    };
  }

  if (type === actions.UPDATE_STARTED) {
    return {
      ...state,
      saveLoading: true,
    };
  }

  if (type === actions.UPDATE_SUCCESS) {
    return {
      ...state,
      saveLoading: false,
    };
  }

  if (type === actions.UPDATE_ERROR) {
    return {
      ...state,
      saveLoading: false,
    };
  }

  return state;
};
