import actions from 'src/modules/providerCompany/list/providerCompanyListActions';

const INITIAL_PAGE_SIZE = 10;

const initialData = {
  loading: false,
  message: {},
  code: '',
  rows: [],
  stakehoder: {}, 
	pagination: {
		current: 1,
		pageSize: INITIAL_PAGE_SIZE,
	},
	orderPagination: {
		current: 1,
		pageSize: INITIAL_PAGE_SIZE,
	},
	sorter: {},
	count: 0,
	orderCount: 0,
	filter: {},
	orderFilter: {},
  total: 0,
  relation:'',
  stakeholdersUsers: [],
	filterPreview: {}

};

export default (state = initialData, { type, payload }) => {
  if(type === actions.RESET_PAGINATION){
    return {
      ...state,
      pagination: {
        current: 1,
        pageSize: INITIAL_PAGE_SIZE,
      }
    }
  }

  if(type === actions.CLEAN_FILTER){
    return {
      ...state,
      filter: {} 
    }
  }

   if (type === actions.FETCH_STARTED) {
    return {
      ...state,
      loading: true,
   message:{},
   code: payload.code
    }
  }

  if(type === actions.RESET_CODE){
    return {
      ...state,
      message: {},
      code: '',
      stakehoder: {}, 
    }
  }
  
  if (type === actions.FETCH_SUCCESS) {
    return {
      ...state,
      loading: false,
      message: payload.message,
      code: payload.code,
      stakehoder: payload?.data?.stakeholder  || {}, 
    };
  }


  if (type === actions.FETCH_ERROR) {
    return {
      ...state,
      companyName:{},
      code: payload
    };
  }

  if (type === actions.USERS_STARTED) {
    return {
      ...state,
      loading: true,
      message:{},
      rows: []
    }
  }

  //tabla despachantes /providers
  if (type === actions.USERS_SUCCESS) {
    return {
      ...state,
			rows: payload.list,
			count:payload.total,
			loading: false,
    };
  }

  if (type === actions.PAGINATION_CHANGED) {
		return {
			...state,
			pagination: payload || {
				current: 1,
				pageSize: INITIAL_PAGE_SIZE,
			},
		};
	}

	if (type === actions.SORTER_CHANGED) {
		return {
			...state,
			sorter: payload || {},
		};
	}

	if (type === actions.CHANGE_FILTER) {
		return {
			...state,
			filter: payload,
		};
	}

  if (type === actions.USERS_ERROR) {
    return {
      ...state,
      loading: false,
      rows: []
    };
  }

  if (type === actions.RELATION_STARTED) {
    return {
      ...state,
      // loading: true,
   message:{},
   code: payload.code
    }
  }

  if (type === actions.RELATION_SUCCESS) {
    return {
      ...state,
      loading: false,
      relation: payload.message, // ????
      code: payload.code
    };
  }


  if (type === actions.RELATION_ERROR) {
    return {
      ...state,
      companyName:{},
      code: payload
    };
  }

  if (type === actions.USER_PROVIDER_STARTED) {
    return {
      ...state,
      loading: true,
   message:{},
    }
  }

  if (type === actions.USER_PROVIDER_SUCCESS) {
    return {
      ...state,
      loading: false,
      message: payload?.message || {}, 
      code: payload?.code || "" 
    };
  }


  if (type === actions.USER_PROVIDER_ERROR) {
    return {
      ...state,
      companyName:{},
      code: payload,
      loading: false
    };
  }

  if (type === actions.GET_STAKEHOLDER_USERS_STARTED) {
    return {
      ...state,
      loadingStakeholdersUsers: true,
      stakeholdersUsers: [] 
    }
  }

  if (type === actions.GET_STAKEHOLDER_USERS_SUCCESS) {
    return {
      ...state,
      loadingStakeholdersUsers: false,
      stakeholdersUsers: payload 
    };
  }

  if (type === actions.GET_STAKEHOLDER_USERS_ERROR) {
    return {
      ...state,
      stakeholdersUsers: [] ,
      loadingStakeholdersUsers: false
    };
  }
	if (type === actions.GET_FILTERPREVIEW) {
		return {
			...state,
			filterPreview: payload,
		};
	}
  return state;
};
