import { createSelector } from "reselect";

const selectRaw = (state) => state.receipt.list;

const selectLoading = createSelector([selectRaw], (raw) => raw.loading);

const selectRows = createSelector([selectRaw], (raw) => raw.rows);

const selectDownloadRows = createSelector(
  [selectRaw],
  (raw) => raw.downloadRows
);

const selectAtributes = createSelector([selectRaw], (raw) => raw.atributes);

const selectCount = createSelector([selectRaw], (raw) => raw.count);

const selectHasRows = createSelector([selectRaw], (raw) => raw.count > 0);

const selectSorter = createSelector([selectRaw], (raw) => raw.sorter || {});

const selectOrderBy = createSelector([selectRaw], (raw) => {
  const sorter = raw.sorter;

  if (!sorter) {
    return null;
  }

  if (!sorter.field) {
    return null;
  }

  let direction = sorter.order === "desc" ? "DESC" : "ASC";

  return `${sorter.field} ${direction}`;
});

const selectFilter = createSelector([selectRaw], (raw) => {
  return raw.filter;
});

const selectLimit = createSelector([selectRaw], (raw) => {
  const pagination = raw.pagination;
  return pagination.pageSize;
});

const selectOffset = createSelector([selectRaw], (raw) => {
  const pagination = raw.pagination;

  if (!pagination || !pagination.pageSize) {
    return 0;
  }

  const current = pagination.current || 1;

  return current - 1;
});

const selectPagination = createSelector(
  [selectRaw, selectCount],
  (raw, count) => {
    return {
      ...raw.pagination,
      total: count,
    };
  }
);

const selectLoadingDetails = createSelector(
  [selectRaw],
  (raw) => raw.loadingDetails
);
const selectRowsDetails = createSelector([selectRaw], (raw) => raw.rowsDetails);
const selectCountDetails = createSelector(
  [selectRaw],
  (raw) => raw.countDetails
);
const selectHasRowsDetails = createSelector(
  [selectRaw],
  (raw) => raw.countDetails > 0
);
const selectSorterDetails = createSelector(
  [selectRaw],
  (raw) => raw.sorter || {}
);
const selectOrderByDetails = createSelector([selectRaw], (raw) => {
  const sorter = raw.sorter;

  if (!sorter) {
    return null;
  }

  if (!sorter.field) {
    return null;
  }

  let direction = sorter.order === "desc" ? "DESC" : "ASC";

  return `${sorter.field},${direction}`;
});
const selectLimitDetails = createSelector([selectRaw], (raw) => {
  const pagination = raw.paginationDetails;
  return pagination.pageSize;
});
const selectOffsetDetails = createSelector([selectRaw], (raw) => {
  const pagination = raw.paginationDetails;

  if (!pagination || !pagination.pageSize) {
    return 0;
  }

  const current = pagination.current || 1;

  return current - 1;
});
const selectPaginationDetails = createSelector(
  [selectRaw, selectCountDetails],
  (raw, count) => {
    return {
      ...raw.paginationDetails,
      total: count,
    };
  }
);

const selectTypeOfReceipt = createSelector(
  [selectRaw],
  (raw) => raw.typeOfReceipt
   .map((e) => ({
      label: e,
      value: e,
    })).sort((a, b) => a?.label?.localeCompare(b.label))
);

const selectSaldoTotal = createSelector([selectRaw], (raw) => raw.saldoTotal || {});

const receiptListSelectors = {
  selectLoading,
  selectRows,
  selectDownloadRows,
  selectCount,
  selectHasRows,
  selectAtributes,
  selectSorter,
  selectOrderBy,
  selectLimit,
  selectFilter,
  selectOffset,
  selectPagination,
  selectLoadingDetails,
  selectRowsDetails,
  selectHasRowsDetails,
  selectSorterDetails,
  selectOrderByDetails,
  selectLimitDetails,
  selectOffsetDetails,
  selectPaginationDetails,
  selectTypeOfReceipt,
  selectSaldoTotal
};

export default receiptListSelectors;
