import authAxios2 from "../axios/authAxios2";

export default class GeneralService {
  static async getOrders(orderBy, limit, offset, filter) {
    const params = {
      sort: orderBy,
      size: limit,
      page: offset,
      filter: filter,
    };

    const response = await authAxios2.get(`/portal/order`, {
      params,
    });
    return response.data;
  }
  static async getStakeholdersNotPagination(orderBy, limit, offset, filter) {
    const params = {
      sort: orderBy,
      size: limit,
      page: offset,
      filter: filter,
    };

    const response = await authAxios2.get(`/portal/stakeholder/`, {
      params,
    });
    return response.data;
  }

  static async getclientCompanies(filter) {
    const params = {
      filter: filter,
    };

    const response = await authAxios2.get(`/portal/clientCompany/`, {
      params,
    });

    return response.data;
  }

  static async getVesselPhases() {

    const response = await authAxios2.get(`/portal/navis/vessel-phase-desc`);

    return response.data;
  }

  static async getAllCommodity() {
    const response = await authAxios2.get(`/portal/commodity/`);
    return response.data;
  }

  static async searchBooking(data) {
    const { bookingNumber, clientCompanyCUIT } = data;

    const response = await authAxios2.get(
      `/portal/navis/booking?booking=${bookingNumber}&cuit=${clientCompanyCUIT}`
    );
    return response.data;
  }
}
