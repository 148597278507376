import { createSelector } from 'reselect';

const selectRaw = (state) => state.expoFull.list;

// FULL BODY
const selectLoadingFullBody = createSelector([selectRaw], (raw) => raw.loadingFullBody);
const selectCountFullBody = createSelector([selectRaw], (raw) => raw.countFullBody);
const selectRowsFullBody = createSelector([selectRaw], (raw) => raw.rowsFullBody);
const selectHasRowsFullBody = createSelector([selectRaw], (raw) => raw.countFullBody > 0);
const selectPaginationFullBody = createSelector([selectRaw, selectCountFullBody], (raw, count) => {
    return {
        ...raw.paginationFullBody,
        total: count,
    };
});
const selectSorterFullBody = createSelector([selectRaw], (raw) => raw.sorterFullBody || {});
const selectOffsetFullBody = createSelector([selectRaw], (raw) => {
	const pagination = raw.paginationFullBody;

	if (!pagination || !pagination.pageSize) {
		return 0;
	}

	const current = pagination.current || 1;

	return current - 1;
});
const selectLimitFullBody = createSelector([selectRaw], (raw) => {
	const pagination = raw.paginationFullBody;
	return pagination.pageSize;
});
const selectFilterFullBody = createSelector([selectRaw], (raw) => {
	return raw.filterFullBody;
});
const selectOrderByFullBody = createSelector([selectRaw], (raw) => {
	const sorter = raw.sorterFullBody;

	if (!sorter) {
		return null;
	}

	if (!sorter.field) {
		return null;
	}

	let direction = sorter.order === 'desc' ? 'DESC' : 'ASC';

	return `${sorter.field},${direction}`;
});

// FULL HEADER ID
const selectLoadingHeaderId = createSelector([selectRaw], (raw) => raw.loadingHeaderId);
const selectHeaderId = createSelector([selectRaw], (raw) => raw.headerId);
const selectLoading = createSelector([selectRaw], (raw) => raw.loading);

// FULL
const selectRows = createSelector([selectRaw], (raw) => raw.rows);
const selectStatus = createSelector([selectRaw], (raw) => raw.status)
const selectCount = createSelector([selectRaw], (raw) => raw.count);
const selectHasRows = createSelector([selectRaw], (raw) => raw.count > 0);
const selectSorter = createSelector([selectRaw], (raw) => raw.sorter || {});


// PREAVISO
const selectPreaviso = createSelector([selectRaw], (raw) => raw.preaviso);
const selectLoadingPreaviso = createSelector([selectRaw], (raw) => raw.loadingPreaviso);
const selectHasRowsPreaviso = createSelector([selectRaw], (raw) => raw.countPreaviso > 0);


const selectorNewFullHeader = createSelector([selectRaw], (raw) => raw.newFullHeader)

const selectorClientCompanies = createSelector([selectRaw], (raw) => raw.client)

const selectCommodity = createSelector([selectRaw], (raw) => raw.commodity)

const selectoCommodityOptions = createSelector([selectCommodity], (data) => !Boolean(data) ? [] : data
	.map(({ id, navisId }) => ({ value: id, label: navisId })));

const selectFilterLabel = createSelector([selectRaw], (raw) => {
	return raw.filterLabel;
});

const selectCompanies = createSelector([selectorClientCompanies], ({ data }) => !Boolean(data) ? [] : data
	.map(({ cuit, companyName }) => ({ value: cuit, label: companyName })));

const selectOptionStatusFull = createSelector([selectStatus], (state) => !Boolean(state) ? [] :
	state.map((s: any) => ({ value: s, label: s.replace(/_/g, ' ') })));


const selectorBookingNavis = createSelector([selectRaw], (raw) => raw.booking)

const selectCantContainerBooking = createSelector([selectorBookingNavis], (raw) => raw.items?.itemList.reduceRight((total, item) => total + item.quantity, 0))

const selectorOrders = createSelector([selectRaw], (raw) => raw.orders)

const selectorOrdersOptions = createSelector([selectorOrders], (order) => order.map(({ value }) => ({
	value, label: value
})))

const selectCuitClient = createSelector([selectRaw], (raw) => raw.cuitClient);

const selectCuitClientMessage = createSelector([selectRaw], (raw) => raw.cuitClientMessage);

const selectContainer = createSelector([selectRaw], (raw) => raw.getContainer);

const selectOrderBy = createSelector([selectRaw], (raw) => {
	const sorter = raw.sorter;

	if (!sorter) {
		return null;
	}

	if (!sorter.field) {
		return null;
	}

	let direction = sorter.order === 'desc' ? 'DESC' : 'ASC';

	return `${sorter.field},${direction}`;
});



const selectFilter = createSelector([selectRaw], (raw) => {
	return raw.filter;
});


const selectServices = createSelector([selectRaw], (raw) => {
	return raw.services;
});

const selectLimit = createSelector([selectRaw], (raw) => {
	const pagination = raw.pagination;
	return pagination.pageSize;
});


const selectOffset = createSelector([selectRaw], (raw) => {
	const pagination = raw.pagination;

	if (!pagination || !pagination.pageSize) {
		return 0;
	}

	const current = pagination.current || 1;

	return current - 1;
});



const selectPagination = createSelector([selectRaw, selectCount], (raw, count) => {
	return {
		...raw.pagination,
		total: count,
	};
});

const selectEditBody = createSelector([selectRaw], (raw) => raw.editFullBody)

const selectSaveLoadingPreaviso = createSelector(
	[selectRaw],
	(raw) => Boolean(raw.saveLoadingPreaviso),
);

const expoFullListSelectors = {
	// FULL 
	selectLoading,
	selectRows,
	selectCount,
	selectHasRows,
	selectSorter,
	selectOrderBy,
	selectLimit,
	selectFilter,
	selectOffset,
	selectPagination,

	// FULL BODY
	selectLoadingFullBody,
	selectRowsFullBody,
	selectCountFullBody,
	selectHasRowsFullBody,
	selectSorterFullBody,
	selectOrderByFullBody,
	selectOffsetFullBody,
	selectLimitFullBody,
	selectFilterFullBody,
	selectPaginationFullBody,

	// FULL BODY
	selectLoadingHeaderId,
	selectHeaderId,

	// PREAVISO
	selectLoadingPreaviso,
	selectHasRowsPreaviso,
	selectPreaviso,

	//OTROS
	selectorNewFullHeader,
	selectCompanies,
	selectorBookingNavis,
	selectorOrdersOptions,
	selectCuitClient,
	selectCuitClientMessage,
	selectContainer,
	selectCantContainerBooking,
	selectServices,
	selectStatus,
	selectOptionStatusFull,
	selectCommodity,
	selectoCommodityOptions,
	selectEditBody,
	selectFilterLabel,
};

export default expoFullListSelectors;
