import authAxios2 from "../shared/axios/authAxios2";

export default class billingUserService {
  static async askForBillingUser(companyId?, data?) {
   const params ={
    companyId: companyId,
    email: data
   }
 
     const response = await authAxios2.get(
       `/portal/billingUser/consulta`, {params});
 
     return response.data;
   }

   static async createRelation(companyId?, billingUserId?) {
    const body = {
      companyId: companyId,
      billingUserId: billingUserId
   }
     const response = await authAxios2.post(
       `/portal/billingUser/clientCompanyHasBillingUser?billingUserId=${body?.billingUserId}&companyId=${body?.companyId}`);
 
     return response.data;
   }

  static async createNewBillingUser(companyId?, data?) {
   const body = {
    email: data?.email,
    firstName: data?.firstName,
    lastName: data?.lastName
  }
    const response = await authAxios2.post(
      `/portal/billingUser/?companyId=${companyId}`, body);

    return response.data;
  }

  static async doFetch(orderBy?, limit?, offset?, filter?) {
    const params = {
			sort: orderBy,
			size: limit,
			page: offset,
      companyId: filter?.companyId ? filter?.companyId : undefined,
      billingUserId: filter?.billingUserId ? filter?.billingUserId : undefined,
		};
  
      const response = await authAxios2.get(
        `/portal/billingUser/clientCompanyHasBillingUser`, {params});
  
      return response.data;
    }

    static async doFetchUsers(orderBy?, limit?, offset?, filter?) {
      const params={
			size: limit,

      }
      const response = await authAxios2.get(`/portal/billingUser/`, {params});
      return response.data;
    }

    static async deleteRelation(id) {
      const response = await authAxios2.delete(`/portal/billingUser/clientCompanyHasBillingUser/${id}`);
      return response.data;
    }

    static async editRelation(id, data) {
      const response = await authAxios2.put(`/portal/billingUser/${id}`, data);
      return response.data;
    }
  
}
