import Errors from 'src/modules/shared/error/errors';
import Message from 'src/view/shared/message';
import SingleLoadService from 'src/modules/singleLoad/singleLoadService';
import { getHistory } from 'src/modules/store';
import { i18n } from 'src/i18n';
import authSelectors from 'src/modules/auth/authSelectors';
import authActions from 'src/modules/auth/authActions';
import actions from 'src/modules/users/list/usersListActions'
import generalActions from "src/modules/shared/generals/list/GeneralListActions";

const prefix = 'SINGLELOAD_FORM';

const singleLoadFormActions = {
  INIT_STARTED: `${prefix}_INIT_STARTED`,
  INIT_SUCCESS: `${prefix}_INIT_SUCCESS`,
  INIT_ERROR: `${prefix}_INIT_ERROR`,

  ADD_STARTED: `${prefix}_ADD_STARTED`,
  ADD_SUCCESS: `${prefix}_ADD_SUCCESS`,
  ADD_ERROR: `${prefix}_ADD_ERROR`,

  UPDATE_STARTED: `${prefix}_UPDATE_STARTED`,
  UPDATE_SUCCESS: `${prefix}_UPDATE_SUCCESS`,
  UPDATE_ERROR: `${prefix}_UPDATE_ERROR`,
  
  CODE_RESET: `${prefix}_CODE_RESET`,

  doReset: () => (dispatch) => {
    dispatch({
      type: singleLoadFormActions.CODE_RESET,
    });
  },

  deleteUser: (values: any, funcionLoading: Function) => async (dispatch) => {
    const {
      id, 
      enabled, 
      firstName,
      lastName
    } = values;
    
    enabled && await SingleLoadService.destroyLogic(id);
    enabled || await SingleLoadService.activeLogic(id);

    funcionLoading((backValue: Boolean) => !backValue);
    dispatch(actions.doFetch());
    Message.success(`El usuario ${firstName} ${lastName} se ha ${enabled? "desactivado":"activado"} correctamente`); 
  },

   doFetchUser: (email) => async (dispatch) => {
    try {
      dispatch({
        type: singleLoadFormActions.INIT_STARTED,
      });

      // const isEdit = Boolean(id);
      // let record = {};

      // if (isEdit) {
      //   record = await SingleLoadService.find(email);
      // }
      const response = await SingleLoadService.find(email)
      dispatch({
        type: singleLoadFormActions.INIT_SUCCESS,
        payload: response,
      });
      
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: singleLoadFormActions.INIT_ERROR,
      });

      getHistory().push('/user');
    }
  },

  doAdd: (values,onClose?) => async (dispatch) => {
    
    try {
      dispatch({
        type: singleLoadFormActions.ADD_STARTED,
      });

      const response = await SingleLoadService.create(values);
      dispatch({
        type: singleLoadFormActions.ADD_SUCCESS,
        payload: response
      });
      onClose()
      dispatch(actions.doFetch());
      response.error === "MSC-USER-01" && Message.success('El usuario ya existe');
      response.error === "MSC-USER-02" && Message.success('El usuario no existe en AD')
      response.error === null && Message.success('Se ha creado el usuario')
      response.code === "MS-CO-OP001-OK-002" && Message.success(`${response.message}`)//ok usuario creado correctamente...
      dispatch(generalActions.getClientCompaniesNotPagination()) //actualiza select de empresas
    } catch (error) {
      Errors.handle(error);
    
      dispatch({
        type: singleLoadFormActions.ADD_ERROR,
      });
     
    }
  },

  doUpdateUser: (values, id) => async (dispatch) => {
    
    try {
      dispatch({
        type: singleLoadFormActions.ADD_STARTED,
      });
      values.userRole= "ADMIN";
      const response = await SingleLoadService.update(values, id);
      dispatch(actions.doFetch());
      dispatch({
        type: singleLoadFormActions.ADD_SUCCESS,
        payload: response
      });
      response.error === "MSC-USER-01" && Message.success('El usuario ya existe');
      response.error === "MSC-USER-02" && Message.success('El usuario no existe en AD')
      response.error === null && Message.success('Se ha creado el usuario')
      
    } catch (error) {
      Errors.handle(error);
    
      dispatch({
        type: singleLoadFormActions.ADD_ERROR,
      });
     
    }
  },
  
  doUpdate: (values) => async (dispatch, getState) => {
    try {
      dispatch({
        type: singleLoadFormActions.UPDATE_STARTED,
      });

      await SingleLoadService.edit(values);

      dispatch({
        type: singleLoadFormActions.UPDATE_SUCCESS,
        
      });

      const currentUser = authSelectors.selectCurrentUser(
        getState(),
      );

      if (currentUser.id === values.id) {
        await dispatch(authActions.doRefreshCurrentUser());
      }

      Message.success(i18n('user.doUpdateSuccess'));

      getHistory().push('/user');
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: singleLoadFormActions.UPDATE_ERROR,
      });
    }
  },
};

export default singleLoadFormActions;
