import actions from './billingUserListActions'

const INITIAL_PAGE_SIZE = 10;


const initialState = {
	rows:[],
	data: {},
	count: 0,
	loading: false,
	addLoading: false,
	filter: {},
	pagination: {
		current: 1,
		pageSize: INITIAL_PAGE_SIZE,
	},
	sorter: {},
	message: '',
	users:[],

};

const billingUserListReducer = (state = initialState, { type, payload }) => {
	if (type === actions.ASK_STARTED) {
		return {
			...state,
			addLoading: true,
			data: {},
			message: ''
		};
	}

	if (type === actions.ASK_SUCCESS) {

		return {
			...state,
			addLoading: false,
			data: payload?.data,
			message: payload?.mensaje
		};
	}

	if (type === actions.ASK_ERROR) {
		return {
			...state,
			addLoading: false,
			data: [],
			message: ''
		};
	}

	if (type === actions.CREATE_RELATION_STARTED) {
		return {
			...state,
			loading: true,
			data: {},
			message: ''
		};
	}

	if (type === actions.CREATE_RELATION_SUCCESS) {

		return {
			...state,
			loading: false,
			data: payload.data,
			message: payload.mensaje
		};
	}

	if (type === actions.CREATE_RELATION_ERROR) {
		return {
			...state,
			loading: false,
			rows: {},
			message: ''
		};
	}

	if (type === actions.CREATE_STARTED) {
		return {
			...state,
			loading: true,
			data: {},
			message: ''
		};
	}

	if (type === actions.CREATE_SUCCESS) {

		return {
			...state,
			loading: false,
			data: payload.data,
			message: payload.mensaje
		};
	}

	if (type === actions.CREATE_ERROR) {
		return {
			...state,
			loading: false,
			data: {},
			message: ''
		};
	}

	if (type === actions.GET_USERS_STARTED) {
		return {
			...state,
			loading: true,
			rows: [],
		};
	}

	if (type === actions.GET_USERS_SUCCESS) {

		return {
			...state,
			loading: false,
			rows: payload.row,
			count: payload.count
		};
	}

	if (type === actions.GET_USERS_ERROR) {
		return {
			...state,
			loading: false,
			rows: [],
		};
	}

	if (type === actions.FETCH_BILLING_USERS_STARTED) {
		return {
			...state,
			loading: true,
			users: [],
		};
	}

	if (type === actions.FETCH_BILLING_USERS_SUCCESS) {

		return {
			...state,
			loading: false,
			users: payload.row,
		};
	}

	if (type === actions.FETCH_BILLING_USERS_ERROR) {
		return {
			...state,
			loading: false,
			users: [],
		};
	}


	if (type === actions.PAGINATION_CHANGED) {
		return {
			...state,
			pagination: payload || {
				current: 1,
				pageSize: INITIAL_PAGE_SIZE,
			},
		};
	}

	if (type === actions.SORTER_CHANGED) {
		return {
			...state,
			sorter: payload || {},
		};
	}

	if (type === actions.FILTER_STARTED) {
		return {
			...state,	
			filter: state.filter,
		};
	}
	if (type === actions.FILTER_SUCCESS) {
		return {
			...state,	
			filter: payload,
		};
	}
	if (type === actions.FILTER_ERROR) {
		return {
			...state,	
			filter: state.filter,
		};
	}

	if (type === actions.CLEAN_FILTER_SUCCESS) {
		return {
			...state,	
			filter: {},
		};
	}

	if (type === actions.RESET_PAGINATION) {
		return {
			...state,
			pagination: {
				current: 1,
				pageSize: INITIAL_PAGE_SIZE,
			},
		};
	}

	if (type === actions.RESET_MESSAGE) {
		return {
			...state,
			message: ''
		};
	}

	if (type === actions.DELETE_BILLING_USER_STARTED) {
		return {
			...state,
			loading: true,
		};
	}

	if (type === actions.DELETE_BILLING_USER_SUCCESS) {

		return {
			...state,
			loading: false,
		};
	}

	if (type === actions.DELETE_BILLING_USER_ERROR) {
		return {
			...state,
			loading: false,
		};
	}


	return state;
};

export default billingUserListReducer;
