import actions from './expoFullFormAction';

const initialState = {
	newFullHeader: {},
	editFullBody: {},
	fullBody: {},
	editLoadingFullBody: false,
	createLoadingFullBody: false,
	createLoadingHeader: false,
	createLoadingPreaviso: false,
	createLoadingEditBody:false,
	newFullBody:{},
	pesoNeto:0,
	pesoBruto:0,
	checkSva:false
};

const expoFullFormReducers = (state = initialState, { type, payload }) => {

	// CONFIRMACIÓN DE PREAVISO
	if (type === actions.PREAVISO_STARTED) {
		return {
			...state,
			createLoadingPreaviso: true,
		};
	}

	if (type === actions.PREAVISO_SUCCESS) {
		return {
			...state,
			createLoadingPreaviso: false,
		};
	}

	if (type === actions.PREAVISO_ERROR) {
		return {
			...state,
			createLoadingPreaviso: false,
		};
	}
	
	// CREACIÓN HEADER

	if (type === actions.HEADER_CREATE_STARTED) {
		return {
			...state,
			createLoadingHeader: true,
		};
	}

	if (type === actions.HEADER_CREATE_SUCCESS) {
		return {
			...state,
			createLoadingHeader: false,
			newFullHeader: payload,
		};
	}

	if (type === actions.HEADER_CREATE_ERROR) {
		return {
			...state,
			createLoadingHeader: false,
		};
	}


	// CREACIÓN FULL BODY
	if (type === actions.CREATE_FULL_BODY_STARTED) {
		return {
			...state,
			createLoadingFullBody: true,
		};
	}

	if (type === actions.CREATE_FULL_BODY_SUCCESS) {
		return {
			...state,
			createLoadingFullBody: false,
			editFullBody:{},
			newFullBody: payload,
			pesoBruto: Boolean(payload?.grossWeight !==null) ? payload?.grossWeight : payload?.tareContainers + payload?.loadWeight,
			pesoNeto:Boolean(payload?.loadWeight !==null) ?  payload?.loadWeight : payload?.grossWeight - payload?.tareContainers,
			checkSva: Boolean(payload?.fullBodySVAList?.length > 0)
		};
	}

	if (type === actions.CREATE_FULL_BODY_ERROR) {
		return {
			...state,
			createLoadingFullBody: false,
		};
	}

	// EDICIÓN FULL BODY
	if (type === actions.EDIT_FULL_BODY_STARTED) {
		
		return {
			...state,
			editLoadingFullBody: true,
		}
	}
	
	if (type === actions.EDIT_FULL_BODY_SUCCESS) {
		return {
			...state,
			editLoadingFullBody: false,
			newFullBody:{},
			editFullBody: payload,
			pesoBruto: Boolean(payload?.grossWeight !==null) ? payload?.grossWeight : payload?.tareContainers + payload?.loadWeight,
			pesoNeto:Boolean(payload?.loadWeight !==null) ?  payload?.loadWeight : payload?.grossWeight - payload?.tareContainers,
			checkSva: Boolean(payload?.fullBodySVAList?.length > 0)
		}
	}
	
	if (type === actions.EDIT_FULL_BODY_ERROR) {
		return {
			...state,
			editLoadingFullBody: false,
		}
	}

	return state;
};

export default expoFullFormReducers;