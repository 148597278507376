import { createSelector } from "reselect";

const selectRaw = (state) => state.bookings.list;

const selectLoading = createSelector([selectRaw], (raw) => raw.loading);

const selectRows = createSelector([selectRaw], (raw) => raw.rows);

const selectCount = createSelector([selectRaw], (raw) => raw.count);

const selectHasRows = createSelector([selectRaw], (raw) => raw.count > 0);

const selectSorter = createSelector([selectRaw], (raw) => raw.sorter || {});

const selectOrderBy = createSelector([selectRaw], (raw) => {
  const sorter = raw.sorter;

  if (!sorter) {
    return null;
  }

  if (!sorter.field) {
    return null;
  }

  let direction = sorter.order === "desc" ? "DESC" : "ASC";

  return `${sorter.field},${direction}`;
});

const selectFilter = createSelector([selectRaw], (raw) => {
  return raw.filter;
});

const selectBookingDetail = createSelector([selectRaw], (raw) => {
  return raw.bookingDetail.reduce((acc, booking) => {
    acc[booking.value] = booking;
    return acc;
  }, {});
});

const selectRawFilter = createSelector([selectRaw], (raw) => {
  return raw.rawFilter;
});

const selectLimit = createSelector([selectRaw], (raw) => {
  const pagination = raw.pagination;
  return pagination.pageSize;
});

const selectOffset = createSelector([selectRaw], (raw) => {
  const pagination = raw.pagination;

  if (!pagination || !pagination.pageSize) {
    return 0;
  }

  const current = pagination.current || 1;

  return (current - 1) * pagination.pageSize;
});

const selectPagination = createSelector(
  [selectRaw, selectCount],
  (raw, count) => {
    return {
      ...raw.pagination,
      total: count,
    };
  }
);

const bookingsListSelectors = {
  selectLoading,
  selectRows,
  selectCount,
  selectHasRows,
  selectSorter,
  selectRawFilter,
  selectOrderBy,
  selectLimit,
  selectFilter,
  selectOffset,
  selectPagination,
  selectBookingDetail,
};

export default bookingsListSelectors;
