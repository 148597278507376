import actions from './turnsListActions';

const INITIAL_PAGE_SIZE = 10;

const initialState = {
	rows: [],
	row: {},
	filter: {},
	rawFilter: {},
	sorter: {},
	count: 1,
	pagination: {
		current: 1,
		pageSize: INITIAL_PAGE_SIZE,
	},
	loading: false,
	operation: {},
	turnsForOperation:[],
	rag:[],
	ocupation:[]
};

const turnsListReducer = (state = initialState, { type, payload }) => {
	if (type === actions.FETCH_STARTED) {
		return {
			...state,
			loading: true,
		};
	}

	if (type === actions.FETCH_SUCCESS) {
		return {
			...state,
			loading: false,
			rows: payload,
			count: payload.length,
		};
	}

	if (type === actions.FETCH_ERROR) {
		return {
			...state,
			loading: false,
			rows: [],
			count: 0,
		};
	}

	if (type === actions.PAGINATION_CHANGED) {
		return {
			...state,
			pagination: payload || {
				current: 1,
				pageSize: INITIAL_PAGE_SIZE,
			},
		};
	}

	if (type === actions.SORTER_CHANGED) {
		return {
			...state,
			sorter: payload || {},
		};
	}

	if (type === actions.UPDATE_FILTER) {
		return {
			...state,
			filter: payload,
		};
	}
	if (type === actions.FETCH_SUCCESS_OPERATIONS) {
		return {
			...state,
			operation: payload,
		};
	}
	if (type === actions.FETCH_SUCCESS_TURNSFOROPERATIONS) {
		return {
			...state,
			turnsForOperation: payload,
		};
	}
	if (type === actions.GET_BY_OPERATION_TYPE_SUCCESS) {
		return {
			...state,
			turnsForOperation: payload,
		};
	}
	if (type === actions.FETCH_SUCCESS_RAG) {
		return {
			...state,
			rag: payload,
		};
	}
	if (type === actions.GET_SUCCESS_OCUPATION) {
		return {
			...state,
			ocupation: payload,
		};
	}
	
	


	return state;
};

export default turnsListReducer;