
// validacion de recursos del usuario logueado

var ContainerRoles: any = []
export const validationRoles = (roles?) => {
    const result = roles?.roles[0]
    ContainerRoles.push(result)
    return result
}


export const validationPermission = (value) => {
    const result = ContainerRoles[0]?.resources.filter((f) => f?.code === value);
    return Boolean(result.length >= 1);
};

export const validationPermissionNoMatch = (value) => {
    const result = ContainerRoles[0]?.resources.filter((f) => f?.code === value);
    return Boolean(result.length === 0);
};



