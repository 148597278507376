import actions from '../form/usersFormActions';

const INITIAL_PAGE_SIZE = 10;

const initialState = {
	rows: [],
	loading: false,
};

const usersFormReducer = (state = initialState, { type, payload }) => {
	
	if (type === actions.POST_STARTED) {
		return {
			...state,
			loading:true,
		};
	}
	if (type === actions.POST_SUCCESS) {
		return {
			...state,
			loading: false,
		};
	}
    if (type === actions.POST_ERROR) {
		return {
			...state,
			loading: false,
		};
	}



	

	return state;
};

export default usersFormReducer;