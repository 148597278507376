import Errors from "src/modules/shared/error/errors";
import service from "../billingUserService";
import billingUserListSelectors from "./billingUserListSelectors";
import Message from "src/view/shared/message";

const prefix = "BILL_LIST";

const billingUserListAction = {
  ASK_STARTED: `${prefix}_ASK_STARTED`,
  ASK_SUCCESS: `${prefix}_ASK_SUCCESS`,
  ASK_ERROR: `${prefix}_ASK_ERROR`,

  CREATE_RELATION_STARTED: `${prefix}_CREATE_RELATION_STARTED`,
  CREATE_RELATION_SUCCESS: `${prefix}_CREATE_RELATION_SUCCESS`,
  CREATE_RELATION_ERROR: `${prefix}_CREATE_RELATION_ERROR`,

  CREATE_STARTED: `${prefix}_CREATE_STARTED`,
  CREATE_SUCCESS: `${prefix}_CREATE_SUCCESS`,
  CREATE_ERROR: `${prefix}_CREATE_ERROR`,

  GET_USERS_STARTED: `${prefix}_GET_USERS_STARTED`,
  GET_USERS_SUCCESS: `${prefix}_GET_USERS_SUCCESS`,
  GET_USERS_ERROR: `${prefix}_GET_USERS_ERROR`,

  FETCH_BILLING_USERS_STARTED: `${prefix}_FETCH_BILLING_USERS_STARTED`,
  FETCH_BILLING_USERS_SUCCESS: `${prefix}_FETCH_BILLING_USERS_SUCCESS`,
  FETCH_BILLING_USERS_ERROR: `${prefix}_FETCH_BILLING_USERS_ERROR`,

  PAGINATION_CHANGED: `${prefix}_PAGINATION_CHANGED`,

  SORTER_CHANGED: `${prefix}_SORTER_CHANGED`,

  FILTER_STARTED: `${prefix}_FILTER_STARTED`,
  FILTER_SUCCESS: `${prefix}_FILTER_SUCCESS`,
  FILTER_ERROR: `${prefix}_FILTER_ERROR`,

  CLEAN_FILTER_SUCCESS: `${prefix}_CLEAN_FILTER_SUCCESS`,

  RESET_PAGINATION: `${prefix}_RESET_PAGINATION`,

  RESET_MESSAGE: `${prefix}_RESET_MESSAGE`,

  DELETE_RELATION: `${prefix}_DELETE_RELATION`,

  DELETE_BILLING_USER_STARTED: `${prefix}_DELETE_BILLING_USER_STARTED`,
  DELETE_BILLING_USER_SUCCESS: `${prefix}_DELETE_BILLING_USER_SUCCESS`,
  DELETE_BILLING_USER_ERROR: `${prefix}_DELETE_BILLING_USER_ERROR`,

  askForBillingUser:
    (companyId, data?, setOpen?) => async (dispatch, getState) => {
      try {
        dispatch({
          type: billingUserListAction.ASK_STARTED,
        });
        const response = await service.askForBillingUser(companyId, data);
        dispatch({
          type: billingUserListAction.ASK_SUCCESS,
          payload: response,
        });
        if (
          response.mensaje ===  "El usuario ya se registró en el portal y no corresponde a facturación" ){
            return  Message.error(`${response.mensaje}`);
          };
       if (response.mensaje === "La empresa ya se encuentra relacionada con este usuario de facturación"){
        return Message.error(`${response.mensaje}`), setOpen(false)
      }
        if (
          response.mensaje ===
          "Usuario de facturación no encontrado, crear uno nuevo y asociarlo"
          )
        Message.success(`${response.mensaje}`);
          return setOpen(true);
      } catch (error) {
        dispatch({
          type: billingUserListAction.ASK_ERROR,
        });
      }
    },
  createRelation:
    (companyId, billingUserId?, setOpen?) => async (dispatch, getState) => {
      try {
        dispatch({
          type: billingUserListAction.CREATE_RELATION_STARTED,
        });
        const response = await service.createRelation(companyId, billingUserId);
        dispatch({
          type: billingUserListAction.CREATE_RELATION_SUCCESS,
          payload: response,
        });
        dispatch(billingUserListAction.doFetch());
        dispatch(billingUserListAction.resetMessage());

        Message.success(`${response.mensaje}`);
        setOpen(false);
      } catch (error) {
        dispatch({
          type: billingUserListAction.CREATE_RELATION_ERROR,
        });
      }
    },
  createNewBillingUser:
    (companyId, data?, setOpen?) => async (dispatch, getState) => {
      try {
        dispatch({
          type: billingUserListAction.CREATE_STARTED,
        });
        const response = await service.createNewBillingUser(companyId, data);
        dispatch({
          type: billingUserListAction.CREATE_SUCCESS,
          payload: response,
        });
        dispatch(billingUserListAction.doFetch());
        dispatch(billingUserListAction.doFetchUsers());
        dispatch(billingUserListAction.resetMessage());
      Message.success(`${response.mensaje}`);
      setOpen && setOpen();
      } catch (error) {
        dispatch({
          type: billingUserListAction.CREATE_ERROR,
        });
      }
    },
  doFetch: () => async (dispatch, getState) => {
    try {
      dispatch({
        type: billingUserListAction.GET_USERS_STARTED,
      });
      const response = await service.doFetch(
        billingUserListSelectors.selectOrderBy(getState()),
        billingUserListSelectors.selectLimit(getState()),
        billingUserListSelectors.selectOffset(getState()),
        billingUserListSelectors.selectFilter(getState())
      );
      dispatch({
        type: billingUserListAction.GET_USERS_SUCCESS,
        payload: response,
      });
    } catch (error) {
      dispatch({
        type: billingUserListAction.GET_USERS_ERROR,
      });
    }
  },
  doFetchUsers: () => async (dispatch, getState) => {
    try {
      dispatch({
        type: billingUserListAction.FETCH_BILLING_USERS_STARTED,
      });
      const response = await service.doFetchUsers(
        billingUserListSelectors.selectOrderBy(getState()),
        99999,
        billingUserListSelectors.selectOffset(getState()),
        billingUserListSelectors.selectFilter(getState())
      );
      dispatch({
        type: billingUserListAction.FETCH_BILLING_USERS_SUCCESS,
        payload: response,
      });
    } catch (error) {
      dispatch({
        type: billingUserListAction.FETCH_BILLING_USERS_ERROR,
      });
    }
  },
  doChangePagination: (pagination) => async (dispatch) => {
    dispatch({
      type: billingUserListAction.PAGINATION_CHANGED,
      payload: pagination,
    });

    dispatch(billingUserListAction.doFetch());
  },
  doChangeSort: (sorter, rutSelected) => async (dispatch) => {
    dispatch({
      type: billingUserListAction.SORTER_CHANGED,
      payload: sorter,
    });

    dispatch(billingUserListAction.doFetch());
  },
  deleteRelation: (id) => async (dispatch) => {
    try {
      dispatch({
        type: billingUserListAction.DELETE_BILLING_USER_STARTED,
      });

      const response = await service.deleteRelation(id);

      dispatch({
        type: billingUserListAction.DELETE_BILLING_USER_SUCCESS,
        payload: response,
      });
      dispatch(billingUserListAction.doFetch());
      Message.success(`${response}`);
    } catch (error) {
      dispatch({
        type: billingUserListAction.DELETE_BILLING_USER_ERROR,
      });
      Message.error(`No se pudo eliminar`);
    }
  },

  doChangeFilter: (filter) => async (dispatch) => {
    dispatch(billingUserListAction.doResetPagination());
    dispatch({
      type: billingUserListAction.FILTER_SUCCESS,
      payload: filter,
    });

    dispatch(billingUserListAction.doFetch());
  },
  cleanFilter: (companySelected) => async (dispatch) => {
    dispatch({
      type: billingUserListAction.CLEAN_FILTER_SUCCESS,
    });
  },

  doResetPagination: () => async (dispatch) => {
    dispatch({
      type: billingUserListAction.RESET_PAGINATION,
    });
  },
  resetMessage: () => async (dispatch) => {
    dispatch({
      type: billingUserListAction.RESET_MESSAGE,
    });
  },
  editRelation: (id,data,setOpen?) => async (dispatch) => {
    try {

      const response = await service.editRelation(id,data);
      Message.success(`${response?.mensaje}`)
      setOpen(false)
      dispatch(billingUserListAction.doFetchUsers())
      dispatch(billingUserListAction.doFetch()); 
     
    } catch (error) {
        Errors.showMessage(error)
    }
  },
};

export default billingUserListAction;
