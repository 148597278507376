import actions from "src/modules/scheduledOperations/list/scheduledOperationsListActions";

const INITIAL_PAGE_SIZE = 10;

const initialState = {
  rows: [],
  row: {},
  filter: {},
  filterLabel: {},
  rawFilter: {},
  sorter: {},
  count: 0,
  currentTab: "FULL",
  pagination: {
    current: 1,
    pageSize: INITIAL_PAGE_SIZE,
  },
  loading: false,
};

const scheduledOperationsListReducer = (state = initialState, { type, payload }) => {
  if (type === actions.RESET_OFFSET) {
    return {
      ...state,
      pagination: {
        current: 1,
        pageSize: INITIAL_PAGE_SIZE,
      },
    };
  }

  if( type === actions.CHANGE_FILTER_LABELS) {
    return {
      ...state,
      filterLabel: payload,
    };
  }

  if (type === actions.FETCH_STARTED) {
    return {
      ...state,
      loading: true,
    };
  }

  if (type === actions.FETCH_SUCCESS) {
    return {
      ...state,
      loading: false,
      rows: payload.list,
      count: payload.total,
    };
  }

  if (type === actions.UPDATE_CURRENT_TAB) {
    return {
      ...state,
      currentTab: payload,
      sorter:{}
    };
  }

  if (type === actions.FETCH_ERROR) {
    return {
      ...state,
      loading: false,
      rows: [],
      count: 0,
    };
  }

  if (type === actions.PAGINATION_CHANGED) {
    return {
      ...state,
      pagination: payload || {
        current: 1,
        pageSize: INITIAL_PAGE_SIZE,
      },
    };
  }

  if (type === actions.SORTER_CHANGED) {
    return {
      ...state,
      sorter: payload || {},
    };
  }


  if (type === actions.UPDATE_FILTER) {
    return {
      ...state,
      filter: payload,
    };
  }

  return state;
};

export default scheduledOperationsListReducer;
