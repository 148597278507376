const tableValues = {
    A:10,B:12,C:13,D:14,E:15,
    F:16,G:17,H:18,I:19,J:20,
    K:21,L:23,M:24,N:25,O:26,
    P:27,Q:28,R:29,S:30,T:31,
    U:32,V:34,W:35,X:36,Y:37,Z:38,
  };
  
  const addition = (acc, curr, index) =>  acc + ((tableValues[curr] || curr) * Math.pow(2, index));
  
  class CheckDigit {
      
    static createCheckDigit(identifierCode){
      if(identifierCode.length == 0) throw "IdentifierCode is empty";
      
      let arrayIdentifierCode = identifierCode.replace(" ","").split("");
      let additionArrayCode = arrayIdentifierCode.reduce(addition, 0);
      let operationAddition = Math.floor(additionArrayCode / 11);
  
      operationAddition = operationAddition * 11;
      
      return parseInt(`${additionArrayCode - operationAddition}`.slice(-1));
    }
    
    static validationCreateCheckDigit(identifierCode) {
      if(identifierCode.length == 0) throw "IdentifierCode is empty";
      let checkDigital = CheckDigit.createCheckDigit(identifierCode.slice(0,-1));
      return parseInt(identifierCode.slice(-1)) === checkDigital;
    }
  }


  export default CheckDigit