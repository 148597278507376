import actions from './turnActions';

const initialData = {
	add: false,
	edit: false,
	editData: null,
	rows: [],
	loading: false,
	add_error:null,
	
};

const turnReducer = (state = initialData, { type, payload }) => {
	

	if (type === actions.FETCH_STARTED) {
		return {
			...state,
			loading: true,
		};
	}

	if (type === actions.FETCH_SUCCESS) {
		return {
			...state,
			loading: false,
			rows: payload,
		};
	}

	if (type === actions.FETCH_ERROR) {
		return {
			...state,
			loading: false,
			rows: [],
		};
	}
	if (type === actions.ADD_ERROR) {
		return {
			...state,
			add_error:"Solapamiento de Horarios"
		};
	}
	if (type === actions.RESET_ERROR) {
		return {
			...state,
			add_error:null
		};
	}
	

	return state;
};

export default turnReducer;
