import Errors from 'src/modules/shared/error/errors';
import service from '../usersService';
import selectors from './usersListSelectors';

const prefix = 'USERS_LIST';

const usersListActions = {
	FETCH_STARTED: `${prefix}_FETCH_STARTED`,
	FETCH_SUCCESS: `${prefix}_FETCH_SUCCESS`,
	FETCH_ERROR: `${prefix}_FETCH_ERROR`,

	RESET_OFFSET: `${prefix}_RESET_OFFSET`,

	PAGINATION_CHANGED: `${prefix}_PAGINATION_CHANGED`,

	SORTER_CHANGED: `${prefix}_SORTER_CHANGED`,

	UPDATE_FILTER: `${prefix}_UPDATE_FILTER`,

	FETCH_STARTED_CONTAINERS : `${prefix}_FETCH_STARTED_CONTAINERS`,
	GET_FILTERPREVIEW:`${prefix}_GET_FILTERPREVIEW`,

	

	doFetch: () => async (dispatch, getState) => {
		try {
			dispatch({
				type: usersListActions.FETCH_STARTED,
			});

			const response = await service.getAllUsers(
				selectors.selectOrderBy(getState()),
				selectors.selectLimit(getState()),
				selectors.selectOffset(getState()),
				selectors.selectFilter(getState())
			);
			dispatch({
				type: usersListActions.FETCH_SUCCESS,
				payload: response,
			});
		} catch (error) {
			Errors.handle(error);

			dispatch({
				type: usersListActions.FETCH_ERROR,
			});
		}
	},

	doChangePagination: (pagination) => async (dispatch, getState) => {
		dispatch({
			type: usersListActions.PAGINATION_CHANGED,
			payload: pagination,
		});

		dispatch(usersListActions.doFetch());
	}, 
	//fn que filtra usuarios
	updateFilter: (filter) => async (dispatch, getState) => {
		dispatch({
			type: usersListActions.RESET_OFFSET,
		});

		dispatch({
			type: usersListActions.UPDATE_FILTER,
			payload: filter,
		});

		dispatch(usersListActions.doFetch());
	},

	doChangeSort: (sorter) => async (dispatch, getState) => {
		dispatch({
			type: usersListActions.SORTER_CHANGED,
			payload: sorter,
		});

		dispatch(usersListActions.doFetch());
	},
	FilterPreview: (values) => async (dispatch) => {
		try {
		  dispatch({
			type: usersListActions.GET_FILTERPREVIEW,
			payload: values,
		  });
		} catch (error) {
		  Errors.showMessage(error);
		}
	  },
	
};

export default usersListActions;
