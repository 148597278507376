import Errors from 'src/modules/shared/error/errors';
import service from '../turnsService';
import selectors from './turnsListSelectors';

const prefix = 'TURNS_LIST';

const turnsListActions = {
	FETCH_STARTED: `${prefix}_FETCH_STARTED`,
	FETCH_SUCCESS: `${prefix}_FETCH_SUCCESS`,
	FETCH_ERROR: `${prefix}_FETCH_ERROR`,

	PAGINATION_CHANGED: `${prefix}_PAGINATION_CHANGED`,

	SORTER_CHANGED: `${prefix}_SORTER_CHANGED`,

	UPDATE_FILTER: `${prefix}_UPDATE_FILTER`,

	FETCH_STARTED_CONTAINERS : `${prefix}_FETCH_STARTED_CONTAINERS`,

	FETCH_SUCCESS_OPERATIONS: `${prefix}_FETCH_SUCCESS_OPERATIONS`,

	FETCH_SUCCESS_TURNSFOROPERATIONS: `${prefix}_FETCH_SUCCESS_TURNSFOROPERATIONS`,

	FETCH_SUCCES_ADDTURN :`${prefix}_FETCH_SUCCES_ADDTURN`,

	FETCH_SUCCESS_RAG : `${prefix}_FETCH_SUCCESS_RAG`,

	GET_BY_OPERATION_TYPE_SUCCESS:`${prefix}_GET_BY_OPERATION_TYPE_SUCCESS`,

	GET_BY_OPERATION_TYPE_ERROR:`${prefix}_GET_BY_OPERATION_TYPE_ERROR`,

	GET_SUCCESS_OCUPATION:`${prefix}_GET_SUCCESS_OCUPATION`,
	


	doFetch: () => async (dispatch, getState) => {
		try {
			dispatch({
				type: turnsListActions.FETCH_STARTED,
			});

			const response = await service.getAllReservations(
				selectors.selectOrderBy(getState()),
				selectors.selectLimit(getState()),
				selectors.selectOffset(getState()),
				selectors.selectFilter(getState())
			);

			dispatch({
				type: turnsListActions.FETCH_SUCCESS,
				payload: response,
			});
		} catch (error) {
			Errors.handle(error);

			dispatch({
				type: turnsListActions.FETCH_ERROR,
			});
		}
	},

	doChangePagination: (pagination) => async (dispatch, getState) => {
		dispatch({
			type: turnsListActions.PAGINATION_CHANGED,
			payload: pagination,
		});

		dispatch(turnsListActions.doFetch());
	},

	doChangeSort: (sorter) => async (dispatch, getState) => {
		dispatch({
			type: turnsListActions.SORTER_CHANGED,
			payload: sorter,
		});

		dispatch(turnsListActions.doFetch());
	},
	getAllOperation: () => async (dispatch, getState) => {
		
		try {
			
			const response = await service.getAllOperations();

			dispatch({
				type: turnsListActions.FETCH_SUCCESS_OPERATIONS,
				payload: response,
			});
		} catch (error) {
			Errors.handle(error);

			dispatch({
				type: turnsListActions.FETCH_ERROR,
			});
		}
	},

	getAllTurnsForOperation: (values) => async (dispatch, getState) => {
		
		try {
			
			const response = await service.getAllTurnsForOperation(values);

			dispatch({
				type: turnsListActions.FETCH_SUCCESS_TURNSFOROPERATIONS,
				payload: response,
			});
		} catch (error) {
			Errors.handle(error);

			dispatch({
				type: turnsListActions.FETCH_ERROR,
			});
		}
	},
	addTurn: (data) => async (dispatch) => {
		try {
			await service.addTurn(data);
			dispatch(turnsListActions.doFetch());
		} catch (error) {
			Errors.handle(error);
		}
	},
	turnEdit: (data) => async (dispatch) => {
		try {
			await service.turnEdit(data);
			dispatch(turnsListActions.doFetch());
		} catch (error) {
			Errors.handle(error);
		}
	},

	turnDelete: (data) => async (dispatch) => {
		try {
			 await service.turnDelete(data);
			dispatch(turnsListActions.doFetch());
		} catch (error) {
			Errors.handle(error);
		}
	},

	getRag: () => async (dispatch) => {

		try {

			const response = await service.getRag();
			dispatch({
				type: turnsListActions.FETCH_SUCCESS_RAG,
				payload: response,
			});

		} catch (error) {
			
			Errors.handle(error);
		}
	},

	getByOperationType: (type)=> async (dispatch, getState) => {
        try {
            dispatch({
                type: turnsListActions.FETCH_SUCCESS_TURNSFOROPERATIONS,
            });

            const response = await service.getAllTurnsForOperation(type);

            dispatch({
                type: turnsListActions.GET_BY_OPERATION_TYPE_SUCCESS,
                payload: response,
            });
        } catch (error) {
            Errors.handle(error);

            dispatch({
                type: turnsListActions.GET_BY_OPERATION_TYPE_ERROR,
            });
        }
    },
	ocupationForColor:(data?) => async (dispatch) => {
			
		try {

			const response = await service.ocupationForColor(data);
			dispatch({
				type: turnsListActions.GET_SUCCESS_OCUPATION,
				payload: response,
			});
		} catch (error) {
			
			// Errors.handle(error);
		}
	},


	
};

export default turnsListActions;