import Errors from 'src/modules/shared/error/errors';
import service from '../stakeholderBookingService';
import Message from 'src/view/shared/message';
import stakeholderBooking from './stakeholderBookingListSelectors';

const prefix = 'STAKEHOLDER_BOOKING';

const stakeholderBookingActions = {

	FETCH_STARTED: `${prefix}_FETCH_STARTED`,
	FETCH_SUCCESS: `${prefix}_FETCH_SUCCESS`,
	FETCH_ERROR: `${prefix}_FETCH_ERROR`,

	PAGINATION_CHANGED: `${prefix}_PAGINATION_CHANGED`,

	ASIGNACION_SUCCESS: `${prefix}_ASIGNACION_SUCCESS`,

	SORTER_CHANGED: `${prefix}_SORTER_CHANGED`,

	CHANGE_FILTER: `${prefix}_CHANGE_FILTER`,

	CHANGE_RESET: `${prefix}_CHANGE_RESET`,


	doFetch: () => async (dispatch, getState) => {
		try {
			dispatch({
				type: stakeholderBookingActions.FETCH_STARTED,
			});

			const response = await service.getAllStakeholderBooking(
				stakeholderBooking.selectOrderBy(getState()),
				stakeholderBooking.selectLimit(getState()),
				stakeholderBooking.selectOffset(getState()),
				stakeholderBooking.selectFilter(getState())
			);

			dispatch({
				type: stakeholderBookingActions.FETCH_SUCCESS,
				payload: response,
			});
		} catch (error) {
			// Errors.handle(error);

			dispatch({
				type: stakeholderBookingActions.FETCH_ERROR,
			});
		}
	},

	asignacionBooking: (values) => async (dispatch, getState) => {
		try {

			const response = await service.asignacionBooking(values);
			dispatch({
				type: stakeholderBookingActions.ASIGNACION_SUCCESS,
				payload: response,
			});
			Message.success(`Asignación de booking exitosa`)
		} catch (error) {
		
			Errors.showMessage(error)
		}
	},

	doChangePagination: (pagination) => async (dispatch) => {
		dispatch({
			type: stakeholderBookingActions.PAGINATION_CHANGED,
			payload: pagination,
		});

		dispatch(stakeholderBookingActions.doFetch());
	},

	doChangeSort: (sorter) => async (dispatch) => {
		dispatch({
			type: stakeholderBookingActions.SORTER_CHANGED,
			payload: sorter,
		});

		dispatch(stakeholderBookingActions.doFetch());
	},

	doChangeFilter: (values) => async (dispatch) => {
		dispatch({
			type: stakeholderBookingActions.CHANGE_RESET
		});

		dispatch({
			type: stakeholderBookingActions.CHANGE_FILTER,
			payload: values,
		});

		dispatch(stakeholderBookingActions.doFetch());
	},

	
 

	
	
	






	






	

};

export default stakeholderBookingActions;
