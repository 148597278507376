import authAxios from 'src/modules/shared/axios/authAxios';
import authAxios2 from 'src/modules/shared/axios/authAxios2';

export default class CompanyService {

  static async find(cuit) {
    const response = await authAxios2.get(
      `/portal/clientCompany/consultaCuit?cuit=${cuit}`,
    );
    return response.data;
  }

  //SELECT EMPRESA EN CONSOLIDADO -  TURNO
  static async findClient() {
    const response = await authAxios2.get(`/portal/clientCompany/`);
    return response.data;
  }

  static async edit(data) {
    const body = {
      ...data,
    };

    const response = await authAxios.put(
      `/user/`,
      body,
    );

    return response.data;
  }

  static async destroy(ids) {
    const params = {
      ids,
    };

    const response = await authAxios.delete(
      `/user/`,
      {
        params,
      },
    );

    return response.data;
  }

  static async create(data) {
    const body = {
      data,
    };

    const response = await authAxios.post(
      `/user`,
      body,
    );

    return response.data;
  }

 

  static async fetchUsers(filter, orderBy, limit, offset) {
    const params = {
      filter,
      sort: orderBy,
      size: limit,
      page: offset,
    };

    const response = await authAxios.get(
      `/user/`,
      {
        params,
      },
    );

    return response.data;
  }

  static async fetchUserAutocomplete(query, limit) {
    const params = {
      query,
      limit,
    };

    const response = await authAxios.get(
      `/user/autocomplete`,
      {
        params,
      },
    );
    return response.data;
  }
}
