import axios from 'axios';
import authAxios from 'src/modules/shared/axios/authAxios';
import authAxios2 from '../shared/axios/authAxios2';
import Message from 'src/view/shared/message';
import { TokenAzureAd } from '../auth/TokenAzureAd';

const api = '/portal/user/adminCliente'
export default class SingleLoadService {
  static async create(data) {
    const token = await TokenAzureAd.getAccessToken();
    const response = await authAxios2.post(`${api}`, { ...data, token });
    if (response.data.code === "MS-CO-OP001-ERR-002") Message.error(`${response.data.message}`)
    return response.data;
  }

  static async update(data, id) {
    const response = await authAxios2.put(`/portal/user/${id}`, data);
    Message.success(`${response.data.mensaje}`)

    return response.data;
  }

  static async find(email) {
    const response = await axios.get(
      `https://630cbbce53a833c534336f88.mockapi.io/portal/stock/active-directory`, email
    );
    return response.data;
  }


  static async edit(data) {
    const body = {
      ...data,
    };

    const response = await authAxios.put(
      `/user/`,
      body,
    );

    return response.data;
  }


  static async destroyLogic(id: String,) {
    const token = await TokenAzureAd.getAccessToken();
    const params = { token }
    const response = await authAxios.delete(`/portal/user/${id}`, { params });
    return response.data;
  }


  static async activeLogic(id: String) {
    const token = await TokenAzureAd.getAccessToken();
    const params = { token }
    const response = await authAxios.patch(`/portal/user/${id}/enabled`, null, { params });
    return response.data;
  }

  static async fetchUsers(filter, orderBy, limit, offset) {
    const params = {
      filter,
      sort: orderBy,
      size: limit,
      page: offset,
    };

    const response = await authAxios.get(
      `/user/`,
      {
        params,
      },
    );

    return response.data;
  }

  static async fetchUserAutocomplete(query, limit) {
    const params = {
      query,
      limit,
    };

    const response = await authAxios.get(
      `/user/autocomplete`,
      {
        params,
      },
    );
    return response.data;
  }
}
