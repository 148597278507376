import { i18n } from "src/i18n";

const routesComponentUser = {
  "users-menu": {
    path: "/usersMenu",
    component: () => import("src/view/users/UsersMenu"),
  },
  "user-management": {
    path: "/myusers",
    component: () => import("src/view/users/user-management/UserManagement"),
  },
  "user-management-client": {
    path: "/userAdmin",
    component: () => import("src/view/users/user-management-client/UsersAdmin"),
  },
  "dispatcher-management": {
    path: "/stakeholdersMenu",
    component: () =>
      import("src/view/users/dispatcher-management/DispatcherManagement"),
  },
};
const routesComponentManegeEmptyMenu = {
  "manage-empty-menu": {
    path: "/managementEmptyMenu",
    component: () => import("src/view/export/manage-empty/ManegeEmptyMenu"),
  },
};
const routesComponentExport = {
  "manage-export": {
    path: "/menuexport",
    component: () => import("src/view/export/ExportMenu"),
  },
  /*--  --*/
  "entered-terminal": {
    path: "/export",
    component: () => import("src/view/export/entered-terminal/Export"),
    title: "Consulta de Booking",
  },
  "export-booking": {
    path: "/export/booking/:booking",
    component: () => import("src/view/export/export-booking/ConsultBooking"),
    title: "Detalle por BOOKING",
  },
  /*-----*/
  "export-in/out": {
    path: "/export/in-out",
    component: () => import("src/view/export/export-in-out/ExportInOut"),
  },

  /*-- --*/
  "manage-empty": {
    path: "/manageEmpty",
    component: () => import("src/view/export/manage-empty/Empty"),
    title: i18n("section_empty.titles.title_empty"),
  },

  "empty-request": {
    path: "/manageEmpty/request/:id",
    component: () =>
      import("src/view/export/manage-empty/header-empty/EmptyRequest"),
    title: i18n("section_empty.titles.full_title_request"),
  },
  /*-----*/

  /*--   --*/
  "export-full-booking": {
    path: "/exportfull/booking",
    component: () =>
      import("src/view/export/export-full-booking/ExportFullForBooking"),
    title: i18n("section_empty.titles.manage_full_bookin"),
  },
  "export-full-request": {
    path: "/turns/exportfull/request/:id",
    component: () =>
      import(
        "src/view/export/export-full-booking/header-full/ExportFullRequest"
      ),
  },
  /*-----*/

  /*-- CONSOLIDADOS  --*/
  "export-consolidation": {
    path: "/expo/consolidation",
    component: () =>
      import("src/view/export/export-consolidation/ConsolidationPage"),
  },
  "export-consolidation-operation-edit": {
    path: "/expo/consolidation/start-operation/:id",
    component: () =>
      import(
        "src/view/export/export-consolidation-operation-edit/StartOperationPage"
      ),
    title: "Iniciar Operación",
  },
  /*-----*/

  "assignment-bk-bl": {
    path: "/assignment/bk/bl",
    component: () => import("src/view/export/assignment-bk-bl/AssignmentBkBl"),
  },
  "booking-socket": {
    path: "/assignment/bk/bl/delegation",
    component: () => import("src/view/export/booking-socket/BookingDelegation"),
  },
  "registration-booking": {
    path: "/altaBooking",
    component: () =>
      import("src/view/export/registration-booking/AsignacionBooking"),
  },
};

const routesComponentTurns = {
  turns: {
    path: "/turns",
    component: () => import("src/view/turns/TurnsHome"),
  },
  "scheduled-operations": {
    path: "/scheduledOperations",
    component: () =>
      import("src/view/turns/scheduled-operations/ScheduledOperations"),
    title: i18n("turns.cards.manageTurns.title"),
  },
  /*-- LOGISTICA --*/
  "export-consolidation-logistic": {
    path: "/expo/consolidation/turns",
    component: () =>
      import("src/view/export/export-consolidation-logistic/LogisticPage"),
    title: i18n("turns.titleConsolidated"),
  },
  /*-- LOGISTICA  OPERACIONES--*/
  "export-consolidation-logistic-operations": {
    path: "/expo/consolidation/operations",
    component: () =>
      import(
        "src/view/export/export-consolidation-logistic/operations/Logistics"
      ),
    title: i18n("turns.titleConsolidated"),
  },
  /*-- LOGISTICA --*/
  "export-consolidation-logistic-permisoEmbarque": {
    path: "/expo/consolidation/permisoEmbarque",
    component: () => import("src/view/turns/permisoEmbarque/PermisoEmbarque"),
    title: i18n("turns.titleConsolidated"),
  },
  "export-consolidation-turn-logistic": {
    path: "/consolidated/turnlogistics/turn/:id",
    component: () =>
      import(
        "src/view/export/export-consolidation-logistic/bookings/header-logistics/LogisticsTurn"
      ),
  },
  "operations-logistic": {
    path: "/consolidated/turnlogistics/operations/:id",
    component: () =>
      import("src/view/export/operations-logistic/approbation/ApprobationPage"),
    title: "Aprobación de Operación",
  },
  "consolidation-book-turns-id": {
    path: "/turns/bookTurns:id",
    component: () => import("src/view/turns/Turns"),
  },
  /*-----*/
};

const routesBilling = {
  billings: {
    path: "/billings",
    component: () => import("src/view/billing/BillsCard"),
  },
  receipts: {
    path: "/receipts",
    component: () => import("src/view/billing/Receipts"),
  },
  customerbilling: {
    path: "/customer-billing",
    component: () => import("src/view/users/user-billing/BillingUser"),
  },
};

const routesComponentConfig = {
  configurations: {
    path: "/config",
    component: () => import("src/view/config/ConfigMenu"),
  },
  /*--  --*/
  "parametry-turns": {
    path: "/config/manageOps",
    component: () => import("src/view/config/schedule/Schedules"),
  },
  "config-turn": {
    path: "/config/manageSchedule/:id",
    component: () => import("src/view/config/turns/ConsolidationForId"),
  },
  /*-----*/
  "exception-days": {
    path: "/config/exceptionDays",
    component: () => import("src/view/config/exception/TurnException"),
    title: i18n("exception.title"),
  },
};

const routesComponent = {
  home: {
    path: "/home",
    component: () => import("src/view/home/Home"),
    title: null,
  },
  "user-profile": {
    path: "/profile",
    component: () => import("src/view/profile/Profile"),
  },
};

const router = {
  ...routesBilling,
  ...routesComponent,
  ...routesComponentUser,
  ...routesComponentTurns,
  ...routesComponentConfig,
  ...routesComponentExport,
  ...routesComponentManegeEmptyMenu,
};

export const validateUri = (uris: Array<string>) => {
  const uriValidation: Array<string> = [];
  const keysUri = Object.keys(router);
  uris?.forEach((uri: string) => {
    if (keysUri.includes(uri)) {
      uriValidation.push(uri);
    }
  });
  return keysUri;
};

export default router;
