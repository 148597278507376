import actions from "./billingListActions";

const INITIAL_PAGE_SIZE = 10;

const initialState = {
  rows: [],
  count: 0,
  loading: false,
  filter: {},
  pagination: {
    current: 1,
    pageSize: INITIAL_PAGE_SIZE,
  },
  sorter: {},
  typeOfBills: [],
  download:[]
};

const billingListReducer = (state = initialState, { type, payload }) => {
  if (type === actions.FETCH_STARTED) {
    return {
      ...state,
      loading: true,
      pagination: state.pagination,
    };
  }

  if (type === actions.FETCH_SUCCESS) {
    return {
      ...state,
      loading: false,
      rows: payload.result,
      count: payload?.total,
    };
  }

  if (type === actions.FETCH_ERROR) {
    return {
      ...state,
      loading: false,
      rows: [],
      count: 0,
    };
  }

  if (type === actions.PAGINATION_CHANGED) {
    return {
      ...state,
      pagination: payload || {
        current: 1,
        pageSize: INITIAL_PAGE_SIZE,
      },
    };
  }

  if (type === actions.SORTER_CHANGED) {
    return {
      ...state,
      sorter: payload || {},
    };
  }

  if (type === actions.FILTER_STARTED) {
    return {
      ...state,
      filter: state.filter,
    };
  }
  if (type === actions.FILTER_SUCCESS) {
    return {
      ...state,
      filter: payload,
    };
  }
  if (type === actions.FILTER_ERROR) {
    return {
      ...state,
      filter: state.filter,
    };
  }

  if (type === actions.CLEAN_FILTER_SUCCESS) {
    return {
      ...state,
      filter: {},
    };
  }

  if (type === actions.RESET_PAGINATION) {
    return {
      ...state,
      pagination: {
        current: 1,
        pageSize: INITIAL_PAGE_SIZE,
      },
    };
  }
  if (type === actions.TYPE_OF_BILLS_STARTED) {
    return {
      ...state,
      typeOfBills: [],
    };
  }

  if (type === actions.TYPE_OF_BILLS_SUCCESS) {
    return {
      ...state,
      typeOfBills: payload,
    };
  }

  if (type === actions.TYPE_OF_BILLS_ERROR) {
    return {
      ...state,
      typeOfBills: [],
    };
  }
  if (type === actions?.DOWNLOAD_SUCCESS) {
    return {
      ...state,
      download: payload,
    };
  }

  return state;
};

export default billingListReducer;
