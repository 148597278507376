import Errors from 'src/modules/shared/error/errors';
import Service from '../providerService';

const prefix = 'PROVIDERS_LIST';

const providersListActions = {
	CUIT_STARTED: `${prefix}_CUIT_STARTED`,
	CUIT_SUCCESS: `${prefix}_CUIT_SUCCESS`,
	CUIT_ERROR: `${prefix}_CUIT_ERROR`,

	FETCH_STARTED: `${prefix}_FETCH_STARTED`,
	FETCH_SUCCESS: `${prefix}_FETCH_SUCCESS`,
	FETCH_ERROR: `${prefix}_FETCH_ERROR`,

	PAGINATION_CHANGED: `${prefix}_PAGINATION_CHANGED`,

	SORTER_CHANGED: `${prefix}_SORTER_CHANGED`,

	CHANGE_FILTER: `${prefix}_CHANGE_FILTER`,

	FETCH_ROLES: `${prefix}_FETCH_ROLES`,

	FETCH_USER_TYPES: `${prefix}_FETCH_USER_TYPES`,

	doFetchCompany: (cuit?) => async (
		dispatch,
		getState,
	) => {
		try {
			dispatch({
				type: providersListActions.CUIT_STARTED,
				payload: { cuit },
			});

			const response = await Service.find(cuit);

			dispatch({
				type: providersListActions.CUIT_SUCCESS,
				payload: response
			});
		} catch (error) {
			Errors.handle(error);

			dispatch({
				type: providersListActions.CUIT_ERROR,
			});
		}
	},

	doFetch: () => async (dispatch, getState) => {
		try {
			dispatch({
				type: providersListActions.FETCH_STARTED,
			});

			const response = await Service.getAllRelations(
			);

			dispatch({
				type: providersListActions.FETCH_SUCCESS,
				payload: response,
			});
		} catch (error) {
			Errors.handle(error);

			dispatch({
				type: providersListActions.FETCH_ERROR,
			});
		}
	},
	doDeactivate: (id) => async (dispatch) => {
		try {
			await Service.deactivateRelation(id);
			dispatch(providersListActions.doFetch());
		} catch (error) {
			Errors.handle(error);
		}
	},
};

export default providersListActions;
