import routesComponent from 'src/config/routesComponent';
import Permissions from 'src/security/permissions';
const permissions = Permissions.values;

const privateRoutes = (resources) => (resources.map(route =>
({
	path: routesComponent[route].path,
	loader: routesComponent[route].component,
	title: routesComponent[route].title,
	permissionRequired: permissions[route],
	exact: true,
})

));
const publicRoutes = [
	{
		path: '/',
		loader: () => import('src/view/auth/SignupPage'),
	},
].filter(Boolean);

const emptyPermissionsRoutes = [
	{
		path: '/auth/empty-permissions',
		loader: () => import('src/view/auth/EmptyPermissionsPage'),
	},
].filter(Boolean);

const simpleRoutes = [

	{
		path: '/403',
		loader: () => import('src/view/shared/errors/Error403Page'),
	},
	{
		path: '/500',
		loader: () => import('src/view/shared/errors/Error500Page'),
	},
	{
		path: '/**',
		loader: () => import('src/view/shared/errors/Error404Page'),
	},
].filter(Boolean);

export default {
	privateRoutes,
	publicRoutes,
	emptyPermissionsRoutes,
	simpleRoutes,
};
