import authAxios from '../shared/axios/authAxios';
import authAxios2 from '../shared/axios/authAxios2';

export default class ExportService {
	static async getAllTrucks(orderBy?, limit?, offset?, filter?, cuitSelected?) {
		const params = {
			size: limit,
			page: offset,
			ingresoBufer: filter?.ingresoBufer,
			ingresoTerminal: filter?.ingresoTerminal,
			contenedor: filter?.contenedor,
			patente: filter?.patente,
			cuit: filter.company || cuitSelected
		};
		const response = await authAxios.get(`/portal/in-out/camionesByCUIT`, {
			params,
		});
		return response.data;
	}

	static async getAllBookings(data,orderBy?, limit?, offset?,filter?) {
		const params = {
			...filter,
			sort: orderBy,
			size: limit,
			page: offset,
			vesselPhaseDesc: filter?.vesselPhaseDesc ? encodeURI(filter?.vesselPhaseDesc) : undefined,
			booking_number: filter?.booking_number ? encodeURI(filter?.booking_number) : undefined,
			booking_line: filter?.booking_line ? encodeURI(filter?.booking_line) : undefined,
			vessel_id: filter?.vessel_id ? encodeURI(filter?.vessel_id) : undefined
		};
		
		const response = await authAxios2.get(`/portal/navis/byCUIT`, {
			params,
		});

		return response.data;
	}
	static async getBookingsDetails(booking) {
		const params = {
			booking,
		};

		const response = await authAxios2.get(`/portal/navis/bookingDetails`, {
			params,
		});

		return response.data;
	}
	
	static async getUnit(booking, unit) {
		const params = {
			booking,
			unit
		};

		const response = await authAxios2.get(`/portal/navis/booking/unit`, {
			params,
		});

		return response.data;
	}
}
