import Roles from 'src/security/roles';

const roles = Roles.values;

class Permissions {
  static get values() {
    return {
      "export": {
        id: 'export',
        allowedRoles: [roles.admin, roles.consulta,roles.transaccional],
      },
      "bills": {
        id: 'my-users',
        allowedRoles: [roles.admin, roles.consulta,roles.transaccional],
      },
      "userRelations": {
        id: 'user-relations',
        allowedRoles: [roles.admin],
      },
      billings: {
        id: 'billings',
        allowedRoles: [roles.admin, roles.transaccional, roles.consulta], 
        disallowedProfiles: ['ClientCompanyUser'],
      },
      // "my-users": {
      //   id: 'my-users',
      //   allowedRoles: [roles.admin],
      // },
      "import": {
        id: 'import',
        allowedRoles: [roles.admin, roles.transaccional],
      },
      "empty": {
        id: 'empty',
        allowedRoles: [roles.admin],
      },
      "turns": {
        id: 'turns',
        allowedRoles: [roles.admin, roles.transaccional, roles.consulta],
      },
      "configurations": {
        id: 'configurations',
        allowedRoles: [roles.admin, roles.transaccional, roles.consulta],
      },
      "history": {
        id: 'history',
        allowedRoles: [roles.admin],
      },
      userEdit: {
        id: 'userEdit',
        allowedRoles: [roles.admin],
      },
      userRead: {
        id: 'userRead',
        allowedRoles: [roles.admin],
      },
      userDestroy: {
        id: 'userDestroy',
        allowedRoles: [roles.admin],
      },
      userCreate: {
        id: 'userCreate',
        allowedRoles: [roles.admin],
      },
      userAutocomplete: {
        id: 'userAutocomplete',
        allowedRoles: [roles.admin],
      },
    };
  }

  static get asArray() {
    return Object.keys(this.values).map((value) => {
      return this.values[value];
    });
  }
}

export default Permissions;
