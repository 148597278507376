import service from "src/modules/export/export-consolidation/consolidatedService";
import Errors from "src/modules/shared/error/errors";
import Message from "src/view/shared/message";
import emptyListSelectors from "src/modules/empty/list/emptyListSelectors";
import { i18n } from 'src/i18n';

import actionsConsolidationList from "src/modules/export/export-consolidation/list/consolidatedListActions";
import selectorsConsolidationList from "src/modules/export/export-consolidation/list/consolidatedListSelectors";
import { display } from "html2canvas/dist/types/css/property-descriptors/display";

const prefix = "CONSOLIDATED_FORM";

const consolidatedFormActions = {
  FETCH_STARTED: `${prefix}_FETCH_STARTED`,
  FETCH_SUCCESS: `${prefix}_FETCH_SUCCESS`,
  FETCH_ERROR: `${prefix}_FETCH_ERROR`,

  APPROBATION_STARTED: `${prefix}_APPROBATION_STARTED`,
  APPROBATION_SUCCESS: `${prefix}_APPROBATION_SUCCESS`,
  APPROBATION_ERROR: `${prefix}_APPROBATION_ERROR`,
  APPROBATION_RESET: `${prefix}_APPROBATION_RESET`,

  CONSOLIDATED_HEADER_STARTED: `${prefix}_CONSOLIDATED_HEADER_STARTED`,
  CONSOLIDATED_HEADER_SUCCESS: `${prefix}_CONSOLIDATED_HEADER_SUCCESS`,
  CONSOLIDATED_HEADER_ERROR: `${prefix}_CONSOLIDATED_HEADER_ERROR`,

  GET_COMMODITIES_STARTED: `${prefix}_GET_COMMODITIES_STARTED`,
  GET_COMMODITIES_SUCCESS: `${prefix}_GET_COMMODITIES_SUCCESS`,
  GET_COMMODITIES_ERROR: `${prefix}_GET_COMMODITIES_ERROR`,

  GET_TYPEOFLOAD_STARTED: `${prefix}_GET_TYPEOFLOAD_STARTED`,
  GET_TYPEOFLOAD_SUCCESS: `${prefix}_GET_TYPEOFLOAD_SUCCESS`,
  GET_TYPEOFLOAD_ERROR: `${prefix}_GET_TYPEOFLOAD_ERROR`,

  GET_OPERATIONS_STARTED: `${prefix}_GET_OPERATIONS_STARTED`,
  GET_OPERATIONS_SUCCESS: `${prefix}_GET_OPERATIONS_SUCCESS`,
  GET_OPERATIONS_ERROR: `${prefix}_GET_OPERATIONS_ERROR`,

  GET_PACKAGING_STARTED: `${prefix}_GET_PACKAGING_STARTED`,
  GET_PACKAGING_SUCCESS: `${prefix}_GET_PACKAGING_SUCCESS`,
  GET_PACKAGING_ERROR: `${prefix}_GET_PACKAGING_ERROR`,

  doFetch: () => async (dispatch, getState) => {
    try {
      dispatch({
        type: consolidatedFormActions.FETCH_STARTED,
      });
      dispatch({
        type: consolidatedFormActions.FETCH_SUCCESS,
      });
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: consolidatedFormActions.FETCH_ERROR,
      });
    }
  },

  resetApprobation: () => async (dispatch, getState) => {
    dispatch({
      type: consolidatedFormActions.APPROBATION_RESET,
    })
  },

  doApprobation: (id, users, pdf) => async (dispatch, getState) => {
    try {
      dispatch({
        type: consolidatedFormActions.APPROBATION_STARTED,
      });   
      if(!users.length) {
        Message.error(i18n('Antes de aprobar la Operación, debe seleccionar un usuario.'));
        return;
      }

      await service.ApprobationOperation(id, users, pdf);

      dispatch({
        type: consolidatedFormActions.APPROBATION_SUCCESS,
      });

      Message.success(i18n('Aprobación exitosa'));
    } catch (error) {

      Message.error(i18n(Errors.selectMessage(error)));

      dispatch({
        type: consolidatedFormActions.APPROBATION_ERROR,
      });
    }
  },

  doConsolidatedHeader: (id) => async (dispatch, getState) => {
    try {
      dispatch({
        type: consolidatedFormActions.CONSOLIDATED_HEADER_STARTED,
      });

      const response = await service.getFullHeader(id);

      dispatch({
        type: consolidatedFormActions.CONSOLIDATED_HEADER_SUCCESS,
        payload: response,
      });
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: consolidatedFormActions.CONSOLIDATED_HEADER_ERROR,
      });
    }
  },

  createClientValidation: (data, setCreate) => async (dispatch) => {
    try {
      const response = await service.createClientValidation(data);

      Message.success('Se creo correctamente')
      setCreate({ state: true, create: response })
      dispatch(consolidatedFormActions.doConsolidatedHeader(response.id));
    } catch (error) {
      setCreate({ state: false, create: {} })
      Errors.handle(error);
    }
  },

  editOperation: (data) => async (dispatch) => {
    try {
      const response = await service.editOperation(data, null);
      dispatch(consolidatedFormActions.doConsolidatedHeader(response.id));
      Message.success('Se editó correctamente')
    } catch (error) {
      Errors.handle(error);
    }
  },

  editService: (id, data, array) => async (dispatch) => {
    try {
      dispatch(consolidatedFormActions.doConsolidatedHeader(id));
      Message.success('Se editó correctamente')
    } catch (error) {
      Errors.handle(error);
    }
  },

  getCommodity: () => async (dispatch, getState) => {
    try {
      dispatch({
        type: consolidatedFormActions.GET_COMMODITIES_STARTED,
      });
      const response = await service.getAllCommodity();

      dispatch({
        type: consolidatedFormActions.GET_COMMODITIES_SUCCESS,
        payload: response.data,
      });

    } catch (error) {
      Errors.handle(error);
      dispatch({
        type: consolidatedFormActions.GET_COMMODITIES_ERROR,
      });
    }
  },
  getPackaging: () => async (dispatch, getState) => {
    try {
      dispatch({
        type: consolidatedFormActions.GET_PACKAGING_STARTED,
      });
      // const { id } = consolidatedFormSelectors.selectConsolidatedHeader(getState())
      const response = await service.getAllPackaging();
      dispatch({
        type: consolidatedFormActions.GET_PACKAGING_SUCCESS,
        payload: response.data,
      });
      // dispatch(consolidatedFormActions.doConsolidatedHeader(response));
    } catch (error) {
      Errors.handle(error);
      dispatch({
        type: consolidatedFormActions.GET_PACKAGING_ERROR,
      });
    }
  },
  getTypeOfLoad: () => async (dispatch, getState) => {
    try {
      dispatch({
        type: consolidatedFormActions.GET_TYPEOFLOAD_STARTED,
      });
      // const { id } = consolidatedFormSelectors.selectConsolidatedHeader(getState())
      const response = await service.getAllTypeOfLoad();
      dispatch({
        type: consolidatedFormActions.GET_TYPEOFLOAD_SUCCESS,
        payload: response.data,
      });
      // dispatch(consolidatedFormActions.doConsolidatedHeader(response));
    } catch (error) {
      Errors.handle(error);
      dispatch({
        type: consolidatedFormActions.GET_TYPEOFLOAD_ERROR,
      });
    }
  },

  getOperations: () => async (dispatch, getState) => {
    try {
      dispatch({
        type: consolidatedFormActions.GET_OPERATIONS_STARTED,
      });
      const { operationId } = emptyListSelectors.selectorHeaderById(getState())

      const response = await service.getAllOperations(operationId);
      dispatch({
        type: consolidatedFormActions.GET_OPERATIONS_SUCCESS,
        payload: response,
      });
      // dispatch(consolidatedFormActions.doConsolidatedHeader(response));
    } catch (error) {
      Errors.handle(error);
      dispatch({
        type: consolidatedFormActions.GET_OPERATIONS_ERROR,
      });
    }
  },


};

export default consolidatedFormActions;
