import authAxios from "../shared/axios/authAxios";

export default class BookingsService {
  static async getAllBookings(orderBy?, limit?, offset?, filter?) {
    const params = {
      sort: orderBy,
      size: limit,
      page: offset,
      filter: filter,
    };
    const response = await authAxios.get(
      "https://629fae2a461f8173e4eef371.mockapi.io/portal/bookings/bookings",
      { params }
    );
    return response.data;
  }

  static async getBookingDetails(orderBy?, limit?, offset?, filter?) {
    const params = {
      sort: orderBy,
      size: limit,
      page: offset,
      filter: filter,
    };
    const response = await authAxios.get("/portal/navis/bookingDetails", {
      params,
    });
    return response.data;
  }
  static async getBookingDetail(booking) {
    const params = {
      booking,
    };
    const response = await authAxios.get("/portal/navis/bookingItemDetails", {
      params,
    });
    return response.data;
  }
  static async getFilterBookings(orderBy?, limit?, offset?, filter?, values?) {
    const params = {
      sort: orderBy,
      size: limit,
      page: offset,
      filter: filter,
    };
    const response = await authAxios.get(
      `https://629fae2a461f8173e4eef371.mockapi.io/portal/bookings/bookings/${values}/container`,
      { params }
    );
    return response.data;
  }

  static async getAllContainers(orderBy?, limit?, offset?, filter?) {
    const params = {
      sort: orderBy,
      size: limit,
      page: offset,
      filter: filter,
    };
    const response = await authAxios.get(
      "https://629fae2a461f8173e4eef371.mockapi.io/portal/bookings/containerImport",
      { params }
    );
    return response.data;
  }
}
