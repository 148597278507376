import actions from 'src/modules/company/list/companyListActions';

// const INITIAL_PAGE_SIZE = 10;

const initialData = {
  loading: false,
  message: {},
  code: '',
  clientCompany: {},
  preStatus: []
};

export default (state = initialData, { type, payload }) => {
  if (type === actions.FETCH_STARTED) {
    return {
      ...state,
      loading: true,
      message:{},
      code: payload.code
    }
  }

  if (type === actions.FETCH_SUCCESS) {
    return {
      ...state,
      loading: false,
      message: payload.message, // ????
      code: payload.code,
      data:payload.data
    };
  }

  if (type === actions.CODE_RESET) {
    return {
      ...state,
      message: {}, // ????
      code: ''
    };
  }


  if (type === actions.FETCH_ERROR) {
    return {
      ...state,
      companyName:{},
      code: payload
    };
  }
  if (type === actions.FETCH_CLIENT_STARTED) {
    return {
      ...state,
      loading: true,
      clientCompany: {}
    }
  }

  if (type === actions.FETCH_CLIENT_SUCCESS) {
    return {
      ...state,
      loading: false,
      clientCompany: payload
    };
  }

  if (type === actions.FETCH_CLIENT_ERROR) {
    return {
      ...state,
      clientCompany: {}
    };
  }


  return state;
};
