import billingListActions from "src/modules/billing/list/billingListActions";
import receiptListActions from "src/modules/receipt/list/receiptListActions";
import service from "../CheckingAccountService";
import checkingAccountListSelectors from "./checkingAccountListSelectors";

const prefix = "CHECKING_ACCOUNT_LIST";

const checkingAccountListActions = {
  FETCH_STARTED: `${prefix}_FETCH_STARTED`,
  FETCH_SUCCESS: `${prefix}_FETCH_SUCCESS`,
  FETCH_ERROR: `${prefix}_FETCH_ERROR`,

  PAGINATION_CHANGED: `${prefix}_PAGINATION_CHANGED`,

  SORTER_CHANGED: `${prefix}_SORTER_CHANGED`,

  FILTER_STARTED: `${prefix}_FILTER_STARTED`,
  FILTER_SUCCESS: `${prefix}_FILTER_SUCCESS`,
  FILTER_ERROR: `${prefix}_FILTER_ERROR`,
  RESET_PAGINATION: `${prefix}_RESET_PAGINATION`,

  doFetch: (cuit?) => async (dispatch, getState) => {
    try {
      dispatch({
        type: checkingAccountListActions.FETCH_STARTED,
      });

      const { cuit: filterCuit } =
        checkingAccountListSelectors.selectFilter(getState());

      const response = await service.getCheckingAccount(
        cuit || filterCuit,
        checkingAccountListSelectors.selectOrderBy(getState()),
        checkingAccountListSelectors.selectLimit(getState()),
        checkingAccountListSelectors.selectOffset(getState()),
        checkingAccountListSelectors.selectFilter(getState())
      );
      dispatch({
        type: checkingAccountListActions.FETCH_SUCCESS,
        payload: response,
      });
    } catch (error) {
      dispatch({
        type: checkingAccountListActions.FETCH_ERROR,
      });
    }
  },

  doChangePagination: (pagination) => async (dispatch) => {
    dispatch({
      type: checkingAccountListActions.PAGINATION_CHANGED,
      payload: pagination,
    });

    dispatch(checkingAccountListActions.doFetch());
  },

  doChangeSort: (sorter) => async (dispatch) => {
    dispatch({
      type: checkingAccountListActions.SORTER_CHANGED,
      payload: sorter,
    });

    dispatch(checkingAccountListActions.doFetch());
  },

  doChangeFilter: (filter, companySelected?, centerFilter = true) => async (dispatch) => {
    dispatch({
      type: checkingAccountListActions.FILTER_SUCCESS,
      payload: filter,
    });

    dispatch(checkingAccountListActions.doResetPagination());

    dispatch(checkingAccountListActions.doFetch(companySelected));
  },

  doChangeFilterCenter:
    (filter, companySelected?) => async (dispatch, getState) => {
      let currentFilter = checkingAccountListSelectors.selectFilter(getState());
      let newFilter = {
        cuit: filter?.cuit || currentFilter.cuit,
        fchMovDesde: filter?.fchemiDesde || currentFilter.fchMovDesde,
        fchMovHasta: filter?.fchemiHasta || currentFilter.fchMovHasta,
        fchVncDesde: filter?.fchvtoDesde || currentFilter.fchVncDesde,
        fchVncHasta: filter?.fchvtoHasta || currentFilter.fchVncHasta,
        formud: currentFilter?.formud || undefined,
        nrocomp: filter?.nrocomp || filter?.nrofor,
      };
      dispatch(
        checkingAccountListActions.doChangeFilter(newFilter, companySelected, false)
      );
    },

  doResetPagination: () => async (dispatch) => {
    dispatch({
      type: checkingAccountListActions.RESET_PAGINATION,
    });
  },
};

export default checkingAccountListActions;
