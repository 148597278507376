const prefix = 'RELATIONS_DIALOGS';

const relationsDialogActions = {
	TOGGLE_ADD: `${prefix}_TOGGLE_ADD`,
	TOGGLE_EDIT: `${prefix}_TOGGLE_EDIT`,

	doToggleAdd: () => async (dispatch) => {
		dispatch({
			type: relationsDialogActions.TOGGLE_ADD,
		});
	},

	doToggleEdit: (data) => async (dispatch) => {
		dispatch({
			type: relationsDialogActions.TOGGLE_EDIT,
			payload: data,
		});
	},
};

export default relationsDialogActions;
