import authAxios2 from "../shared/axios/authAxios2";

export default class receiptService {
  static async getReceipts(cuit, orderBy?, limit?, offset?, filter?) {
    const params = {
      orderBy: orderBy,
      size: limit,
      page: offset,
      idUnico: filter.idUnico || undefined,
      nrofor: filter.nrofor || undefined,
      formul: filter.formul || undefined,
      fchemiDesde: filter.fchemiDesde || undefined,
      fchemiHasta: filter.fchemiHasta || undefined,
      impHasta: filter.impHasta || undefined,
      impDesde: filter.impDesde || undefined,
    };
    const response = await authAxios2.get(
      `/portal/softland/recibos/${cuit}`,
      { params }
    );

    return response.data;
  }

  static async getReceiptsDetails(cuit, nro_recibo) {
    const response = await authAxios2.get(
      `/portal/softland/detalle-recibo/${cuit}/${nro_recibo}`
    );

    return response.data;
  }

  static async getTypeOfReceipts() {
    const response = await authAxios2.get(`/portal/softland/tipo-recibos`);

    return response.data;
  }

  static async getSaldoTotal(cuit) {
    const response = await authAxios2.get(`/portal/softland/saldoTotal/${cuit}`);

    return response.data;
  }
}
