import authAxios2 from "../shared/axios/authAxios2";

export default class billService {
  static async getBills(cuit?, orderBy?, limit?, offset?, filter?) {
    const params = {
      orderBy: orderBy,
      size: limit,
      page: offset,
      formul: filter.formul || undefined,
      idUnico: filter.idUnico || undefined,
      nrocomp: filter.nrocomp || undefined,
      fchemiDesde: filter.fchemiDesde || undefined,
      fchemiHasta: filter.fchemiHasta || undefined,
      fchvtoDesde: filter.fchvtoDesde || undefined,
      fchvtoHasta: filter.fchvtoHasta || undefined,
      saldoDesde: filter.saldoDesde || undefined,
      saldoHasta: filter.saldoHasta || undefined,
      totalHasta: filter.totalHasta || undefined,
      totalDesde: filter.totalDesde || undefined,
    };
    
    const response = await authAxios2.get(
      `/portal/softland/facturas/${cuit}`,
      { params }
    );

    return response.data;
  }
  static async getTypeOfBills() {
  
    
    const response = await authAxios2.get(`/portal/softland/tipo-facturas`);

    return response.data;
  }

  static async download(data,url?){
   const response = await authAxios2.post(`${url}`,data)
   return response.data
  }

}
