import Permissions from "src/security/permissions";
import { i18n } from "src/i18n";
import {
  BuildRounded,
  CalendarToday,
  CallMade,
  MonetizationOnOutlined,
  Group,
} from "@material-ui/icons";

const permissions = Permissions.values;

export default [
  {
    path: "/usersMenu",
    exact: true,
    icon: <Group style={{ width: "100%", height: "100%" }} />,
    label: i18n("myusers.title"),
    description: i18n("myusers.description"),
    permissionRequired: permissions["bills"],
    resource: [
      "users.read",
      "clients.read",
      "billing.read",
      "stakeholders.read",
    ],
  },
  {
    path: "/menuexport",
    exact: true,
    icon: <CallMade style={{ width: "100%", height: "100%" }} />,
    label: i18n("export.title"),
    description: i18n("export.description"),
    permissionRequired: permissions["export"],
    resource: [
      "bookings.read",
      "inout.read",
      "bk.assign.read",
      "bk.forward",
      "bk.create",
      "epxolcl.client.read",
    ],
  },
  {
    path: "/managementEmptyMenu",
    exact: true,
    icon: <CallMade style={{ width: "100%", height: "100%" }} />,
    label: i18n("export.empty.card_title"),
    description: i18n("export.empty.description"),
    permissionRequired: permissions["export"],
    resource: ["expomty.read"],
  },
  {
    path: "/billings",
    exact: true,
    icon: <MonetizationOnOutlined style={{ width: "100%", height: "100%" }} />,
    label: i18n("bills.title"),
    description: i18n("bills.description"),
    permissionRequired: permissions["billings"],
    resource: [
      "billing.bill.view",
      "billing.receipts.view",
      "billing.currentAccount.view",
    ],
  },
  {
    path: "/turns",
    exact: true,
    icon: <CalendarToday style={{ width: "100%", height: "100%" }} />,
    label: i18n("turns.title"),
    description: i18n("turns.description"),
    permissionRequired: permissions["turns"],
    resource: [
      "turns.full.view",
      "turns.empty.view",
      "turns.consolidation.view",
      "turns.operation.view",
      "turns.boardingPermission.view",
    ],
  },
  {
    path: "/config",
    exact: true,
    icon: <BuildRounded style={{ width: "100%", height: "100%" }} />,
    label: i18n("config.title"),
    description: i18n("config.description"),
    permissionRequired: permissions["configurations"],
    resource: ["config.turns.view","config.exceptionalDays.view"],
  },
].filter(Boolean);
