import Errors from 'src/modules/shared/error/errors';
import Message from 'src/view/shared/message';
import ConfigService from '../configService';

const prefix = 'TURN';

const turnActions = {
	FETCH_STARTED: `${prefix}_FETCH_STARTED`,
	FETCH_SUCCESS: `${prefix}_FETCH_SUCCESS`,
	FETCH_ERROR: `${prefix}_FETCH_ERROR`,
	ADD_ERROR:`${prefix}_ADD_ERROR`,
	RESET_ERROR:`${prefix}_RESET_ERROR`,


	doFetch: (id) => async (dispatch) => {
		try {
			dispatch({
				type: turnActions.FETCH_STARTED,
			});

			const response = await ConfigService.getTurns(id);
			dispatch({
				type: turnActions.FETCH_SUCCESS,
				payload: response,
			});
		} catch (error) {
			Errors.handle(error);

			dispatch({
				type: turnActions.FETCH_ERROR,
			});
		}
	},

	doAdd: (data, id,handleClose?) => async (dispatch) => {
		try {
			const response = await ConfigService.addTurn(data, id);
			dispatch(turnActions.doFetch(id));
			dispatch({
				type:turnActions.RESET_ERROR
			})
			handleClose && handleClose()
			Message.success(`${response.message}`)
		} catch (error) {
			dispatch({
				type: turnActions.ADD_ERROR,
			});
			Errors.showMessage(error)
		}
	},

	doEdit: (data, id,handleClose?) => async (dispatch) => {
		try {
			const response = await ConfigService.editTurn(data);
			dispatch(turnActions.doFetch(id));
			dispatch({
				type:turnActions.RESET_ERROR
			})
			handleClose && handleClose()
			Message.success(`${response?.message}`)
			
		} catch (error) {
			dispatch({
				type: turnActions.ADD_ERROR,
			});

			Errors.showMessage(error)
		}
	},

	resetMessageErrorTurn: ()=>async (dispatch)=>{
	  dispatch({type:turnActions.RESET_ERROR})
	},

	doDestroy: (id, operation) => async (dispatch) => {
		try {
			const response = await ConfigService.deleteTurn(id);
			dispatch(turnActions.doFetch(operation));
			Message.success(`${response.message}`)
		} catch (error) {
			Errors.handle(error);
		}
	},
	
};

export default turnActions;
