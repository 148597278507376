import actions from './providersListActions';

// const INITIAL_PAGE_SIZE = 9;

const initialState = {
	companyName: null
};

const providersListReducer = (state = initialState, { type, payload }) => {
	if (type === actions.CUIT_STARTED) {
		return {
			...state,
			companyName: null
		};
	}

	if (type === actions.CUIT_SUCCESS) {
		return {
			...state,
			companyName: payload
			
		};
	}

	if (type === actions.CUIT_ERROR) {
		return {
			...state,
			companyName: null
			
		};
	}

	
	return state;
};

export default providersListReducer;
