import Errors from "src/modules/shared/error/errors";
import selectors from "src/modules/scheduledOperations/list/scheduledOperationsListSelectors";
import RervationService from "../reservationService";

const prefix = "SCHEDULED_OPERATIONS_LIST";

const scheduledOperationsListActions = {
  FETCH_STARTED: `${prefix}_FETCH_STARTED`,
  FETCH_SUCCESS: `${prefix}_FETCH_SUCCESS`,
  FETCH_ERROR: `${prefix}_FETCH_ERROR`,

  RESET_OFFSET: `${prefix}_RESET_OFFSET`,

  PAGINATION_CHANGED: `${prefix}_PAGINATION_CHANGED`,

  SORTER_CHANGED: `${prefix}_SORTER_CHANGED`,

  UPDATE_FILTER: `${prefix}_UPDATE_FILTER`,

  UPDATE_CURRENT_TAB: `${prefix}_UPDATE_CURRENT_TAB`,

  FETCH_STARTED_CONTAINERS: `${prefix}_FETCH_STARTED_CONTAINERS`,

  CHANGE_FILTER_LABELS: `${prefix}_CHANGE_FILTER_LABELS`,


  doFetch: () => async (dispatch, getState) => {
    try {
      dispatch({
        type: scheduledOperationsListActions.FETCH_STARTED,
      });

      const params = selectors.selectFetchParam(getState());
      const currentTab: string = selectors.selectCurrentTab(getState());
      const response = await RervationService.getAllReservation(
        params,
        currentTab.toLowerCase()
      );

      dispatch({
        type: scheduledOperationsListActions.FETCH_SUCCESS,
        payload: response,
      });
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: scheduledOperationsListActions.FETCH_ERROR,
      });
    }
  },

  doChangePagination: (pagination?) => async (dispatch, getState) => {
    dispatch({
      type:scheduledOperationsListActions.RESET_OFFSET
    })
    dispatch({
      type: scheduledOperationsListActions.PAGINATION_CHANGED,
      payload: pagination,
    });

    dispatch(scheduledOperationsListActions.doFetch());
  },
  
  updateFilter: (filter) => async (dispatch, getState) => {
    dispatch({
      type: scheduledOperationsListActions.UPDATE_FILTER,
      payload: filter,
    });

    dispatch(scheduledOperationsListActions.doChangePagination());
  },

  doChangeSort: (sorter) => async (dispatch, getState) => {

    dispatch({
      type: scheduledOperationsListActions.SORTER_CHANGED,
      payload: sorter,
    });

    dispatch(scheduledOperationsListActions.doFetch());
  },

  doUpdateCurrentTab: (tab) => (dispatch, getState) => {
    dispatch({
      type: scheduledOperationsListActions.UPDATE_CURRENT_TAB,
      payload: tab,
    });

    dispatch(scheduledOperationsListActions.doChangePagination());
  },

  doChangeFilterLabel: (values) => async (dispatch) => {
   
    dispatch({
      type: scheduledOperationsListActions.CHANGE_FILTER_LABELS,
      payload: values,
    });

  },


};


export default scheduledOperationsListActions;
