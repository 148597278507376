import authAxios from "../shared/axios/authAxios";

export default class RervationService {
  static async getAllReservation(values, type) {
  let params ={
    filter:values?.filter,
    page:values?.page,
    size:values?.size,
    sort:values?.sort,
  }

      const response = await authAxios.get(`/portal/reservation/${type}-body`,{params});

      return response.data;
      
  }
}
