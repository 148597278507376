import swal  from 'sweetalert'
import authAxios2 from '../shared/axios/authAxios2';

export default class ConfigService {
	static async getAllSchedules(orderBy?, limit?, offset?) {
		const params = {
			sort: orderBy,
			size: limit,
			page: offset,
		};
		const response = await authAxios2.get('/portal/operation', {
			params,
		});
		return response.data;
	}

	static async getTurns(id) {
		const response = await authAxios2.get(`/portal/turn/${id}`);
		
		return response.data;
	}

	static async addTurn(data, id) {
		const response = await authAxios2.post(`/portal/turn/${id}`, data);
		return response.data;
	}

	static async editTurn(data) {
		const response = await authAxios2.put(`/portal/turn`, data);
		return response.data;
	}

	static async deleteTurn(data) {
		const response = await authAxios2.delete(`/portal/turn/${data}`);
		return response.data;
	}
//OK
static async addExceptionDay(data) {
	  const response = await authAxios2.post('/portal/exceptionDay', data);
	  
	  return response.data;
	
  }

	static async getAllExceptionDays(orderBy?, limit?, offset?) {
		const params = {
			sort: orderBy,
			size: limit,
			page: offset,
		};
		const response = await authAxios2.get('/portal/exceptionDay', {params});
		
		return response.data;

	
	}
	///portal/exceptionDay
	static async editExceptionDay(data) {
		const response = await authAxios2.put(`/portal/exceptionDay`, data);

		return response.data;

	}
//delete 
	static async deleteException(exceptionDayId) {
		const response = await authAxios2.delete(`/portal/exceptionDay/${exceptionDayId}`);

		return response.data;

	}

	static async editOperation(data) {
		const response = await authAxios2.put('/portal/operations/edit', data);

	
		if(response.status === 201){
			swal("Exitoso!","Edición completa", "success");

         }

		return response.data;
	}
}
