import Message from "src/view/shared/message";
import service from "../BillingService";
import billingListSelectors from "./billingListSelectors";
import Errors from "src/modules/shared/error/errors";
import receiptListActions from "src/modules/receipt/list/receiptListActions";
import checkingAccountListActions from "src/modules/checkingAccount/list/checkingAccountListActions";

const prefix = "BILLING_LIST";

const billingListActions = {
  FETCH_STARTED: `${prefix}_FETCH_STARTED`,
  FETCH_SUCCESS: `${prefix}_FETCH_SUCCESS`,
  FETCH_ERROR: `${prefix}_FETCH_ERROR`,

  PAGINATION_CHANGED: `${prefix}_PAGINATION_CHANGED`,

  SORTER_CHANGED: `${prefix}_SORTER_CHANGED`,

  FILTER_STARTED: `${prefix}_FILTER_STARTED`,
  FILTER_SUCCESS: `${prefix}_FILTER_SUCCESS`,
  FILTER_ERROR: `${prefix}_FILTER_ERROR`,

  CLEAN_FILTER_SUCCESS: `${prefix}_CLEAN_FILTER_SUCCESS`,

  RESET_PAGINATION: `${prefix}_RESET_PAGINATION`,

  TYPE_OF_BILLS_STARTED: `${prefix}_TYPE_OF_BILLS_STARTED`,
  TYPE_OF_BILLS_SUCCESS: `${prefix}_TYPE_OF_BILLS_SUCCESS`,
  TYPE_OF_BILLS_ERROR: `${prefix}_ERROR_TYPE_OF_BILLS_ERROR`,
  DOWNLOAD_SUCCESS: `${prefix}_DOWNLOAD_SUCCESS`,

  doFetch: (cuit?) => async (dispatch, getState) => {
    try {
      if (!Boolean(cuit)) return;
      dispatch({
        type: billingListActions.FETCH_STARTED,
      });

      const response = await service.getBills(
        cuit,
        billingListSelectors.selectOrderBy(getState()),
        billingListSelectors.selectLimit(getState()),
        billingListSelectors.selectOffset(getState()),
        billingListSelectors.selectFilter(getState())
      );
      dispatch({
        type: billingListActions.FETCH_SUCCESS,
        payload: response,
      });
    } catch (error) {
      dispatch({
        type: billingListActions.FETCH_ERROR,
      });
    }
  },

  doChangePagination: (pagination, rut) => async (dispatch) => {
    dispatch({
      type: billingListActions.PAGINATION_CHANGED,
      payload: pagination,
    });

    dispatch(billingListActions.doFetch(rut));
  },

  doChangeSort: (sorter, rutSelected) => async (dispatch) => {
    dispatch({
      type: billingListActions.SORTER_CHANGED,
      payload: sorter,
    });

    dispatch(billingListActions.doFetch(rutSelected));
  },

  doChangeFilter:
    (filter, companySelected?, centerFilter = true) =>
    async (dispatch) => {
      dispatch(billingListActions.doResetPagination()); //resetea paginacion cad vez que cambia el cuit seleccionado
      dispatch({
        type: billingListActions.FILTER_SUCCESS,
        payload: filter,
      });

      dispatch(billingListActions.doFetch(companySelected));
    },

  doChangeFilterCenter:
    (filter, companySelected) => async (dispatch, getState) => {
      let currentFilter = billingListSelectors.selectFilter(getState());
      let newFilter = {
        fchemiDesde: filter?.fchemiDesde || currentFilter?.fchMovDesde,
        fchemiHasta: filter?.fchemiHasta || currentFilter?.fchMovHasta,
        fchvtoDesde: filter?.fchvtoDesde || currentFilter?.fchvtoDesde,
        fchvtoHasta: filter?.fchvtoHasta || currentFilter?.fchvtoHasta,
        formul: currentFilter?.formul || undefined,
        nrocomp: filter?.nrocomp || filter?.nrofor,
        saldoDesde: filter?.impDesde || currentFilter?.saldoDesde,
        saldoHasta: filter?.impHasta || currentFilter?.saldoHasta,
        totalDesde: currentFilter?.totalDesde,
        totalHasta: currentFilter?.totalHasta,
      };
      dispatch(
        billingListActions.doChangeFilter(newFilter, companySelected, false)
      );
    },

  cleanFilter: (companySelected) => async (dispatch) => {
    dispatch({
      type: billingListActions.CLEAN_FILTER_SUCCESS,
    });

    dispatch(billingListActions.doFetch(companySelected));
  },
  doResetPagination: () => async (dispatch) => {
    dispatch({
      type: billingListActions.RESET_PAGINATION,
    });
  },
  getTypeOfBills: () => async (dispatch, getState) => {
    try {
      dispatch({
        type: billingListActions.TYPE_OF_BILLS_STARTED,
      });

      const response = await service.getTypeOfBills();
      dispatch({
        type: billingListActions.TYPE_OF_BILLS_SUCCESS,
        payload: response,
      });
    } catch (error) {
      dispatch({
        type: billingListActions.TYPE_OF_BILLS_ERROR,
      });
    }
  },
  download: (data, url?, createZip?) => async (dispatch, getState) => {
    try {
      const { pdfs, message, code } = await service.download(data, url);

      dispatch({
        type: billingListActions.DOWNLOAD_SUCCESS,
        payload: pdfs,
      });

      await createZip(pdfs);

      Message.messageType(message, code);
    } catch (error) {
      Errors.showMessage(error);
    }
  },
};

export default billingListActions;
