import authAxios from '../shared/axios/authAxios';

export default class relationsService {
	static async getAllRelations(orderBy?, limit?, offset?, filter?) {
		const params = {
			sort: orderBy,
			size: limit,
			page: offset,
			filter: filter,
		};
		const response = await authAxios.get('/user/all', { params });
		return response.data;
	}

	static async addRelation(data) {
		const response = await authAxios.post('/user/registration', data);
		return response.data;
	}

	static async editRelation(data) {
		const response = await authAxios.put('/user/edit', data);
		return response.data;
	}

	static async getRelation(id) {
		const response = await authAxios.get(`/user/${id}`);
		return response.data;
	}

	static async deactivateRelation(id) {
		const response = await authAxios.put('/user/deactivate', id);
		return response.data;
	}

	static async getRoles() {
		const response = await authAxios.get('/user/roles');
		return response.data;
	}
}
