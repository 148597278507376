import { combineReducers } from 'redux';
import schedules from './schedules/schedulesReducers';
import turn from './turn/turnReducer';
import exceptions from './exception/ExceptionReducer'

export default combineReducers({
	schedules,
	turn,
	exceptions
});
