import { i18n, init as i18nInit } from "src/i18n";
import { AuthToken } from "./modules/auth/authToken";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import ReactDOM from "react-dom";
import { msalConfig } from "./authConfig";
export const msalInstance = new PublicClientApplication(msalConfig);

(async function () {
  AuthToken.applyFromLocationUrlIfExists();
  const publicClientApplication = new PublicClientApplication(msalConfig);
  await i18nInit();

  const App = require('./App').default;
  document.title = i18n('app.title');
  
  ReactDOM.render(
    <MsalProvider instance={publicClientApplication} >
        <App />
      </MsalProvider>,
    document.getElementById("root")
  );
})();
