import actions from './expoFullListAction';

const INITIAL_PAGE_SIZE = 10;

const initialState = {
	// FULL HEADER ID
	headerId: [] as Array<any>,
	loadingHeaderId: false,

	// FULL BODY
	rowsFullBody: [] as Array<any>,
	countFullBody: 0,
	loadingFullBody: false,
	paginationFullBody: {
		current: 1,
		pageSize: INITIAL_PAGE_SIZE,
	},
	sorterFullBody: {},

	// PREAVISO
	loadingPreaviso: false,

	rows: [],
	preaviso: [],
	count: 0,
	loadingHeader: false,
	loading: false,
	countPreaviso: 0,
	filter: {},
	filterLabel: {},
	pagination: {
		current: 1,
		pageSize: INITIAL_PAGE_SIZE,
	},
	sorter: {},
	newFullHeader: {},
	fullHeaderById: {},
	client: {},
	orders: [],
	booking: {},
	cuitClient: {},
	cuitClientMessage: null,
	emptyBody: {},
	editEmptyBody: {},
	getContainer: null,
	services: [],
	status: [],
	commodity: [],
	editFullBody: {},
};


const expoFullListReducers = (state = initialState, { type, payload }) => {
	if (type === actions.FETCH_STARTED) {
		return {
			...state,
			loading: true,
			pagination: state.pagination,
		};
	}
	
	if (type === actions.FETCH_SUCCESS) {

		return {
			...state,
			loading: false,
			rows: payload.list,
			count: payload.total,
		};
	}

	if (type === actions.FETCH_ERROR) {
		return {
			...state,
			loading: false,
			rows: [],
			count: 0,
		};
	}

	// FETCH FULL BODY

	if (type === actions.FETCH_FULL_BODY_STARTED) {
		return {
			...state,
			loadingFullBody: true,
		};
	}

	if (type === actions.FETCH_FULL_BODY_SUCCESS) {
		return {
			...state,
			loadingFullBody: false,
			rowsFullBody: payload.list,
			countFullBody: payload.total,
		};
	}

	if (type === actions.FETCH_FULL_BODY_ERROR) {
		return {
			...state,
			loadingFullBody: false,
			rowsFullBody: [],
			countFullBody: 0,
		};
	}
	// ORDER & PAGINATION FULL BODY 
	if (type === actions.RESET_OFFSET_FULL_BODY) {
		return {
			...state,
			paginationFullBody: {
				current: 1,
				pageSize: INITIAL_PAGE_SIZE,
			},
		};
	}

	if (type === actions.PAGINATION_CHANGED_FULL_BODY) {
		return {
			...state,
			paginationFullBody: payload || {
				current: 1,
				pageSize: INITIAL_PAGE_SIZE,
			},
		};
	}
	
	if (type === actions.SORTER_CHANGED_FULL_BODY) {
		return {
			...state,
			sorterFullBody: payload || {},
		};
	}


	if (type === actions.CHANGE_FILTER_FULL_BODY) {
		return {
			...state,
			filterFullBody: payload,
		};
	}

	// FETCH FULL HEADER ID
	if (type === actions.FETCH_FULL_HEADER_ID_STARTED) {
		return {
			...state,
			loadingHeaderId: true,
		};
	}

	if (type === actions.FETCH_FULL_HEADER_ID_SUCCESS) {
		return {
			...state,
			loadingHeaderId: false,
			headerId: payload,
		};
	}

	if (type === actions.FETCH_FULL_HEADER_ID_ERROR) {
		return {
			...state,
			loadingHeaderId: false,
			headerId: [],
		};
	}

	if (type === actions.CHANGE_FILTER_LABELS) {
		return {
			...state,
			filterLabel: payload,
		};
	}


	if (type === actions.GET_STATUS) {
		return {
			...state,
			status: payload
		}
	}

	if (type === actions.GET_STATUS_OPTIONS) {
		return {
			...state,
			status: payload
		}
	}

	if (type === actions.RESET_OFFSET) {
		return {
			...state,
			pagination: {
				current: 1,
				pageSize: INITIAL_PAGE_SIZE,
			},
		};
	}


	if (type === actions.FETCH_PREAVISO_STARTED) {
		return {
			...state,
			loadingPreaviso: true,
		};
	}


	if (type === actions.FETCH_PREAVISO_ERROR) {
		return {
			...state,
			loadingPreaviso: false,
			preaviso: [],
			countPreaviso: 0,
		};
	}

	if (type === actions.FETCH_PREAVISO_SUCCESS) {

		return {
			...state,
			loadingPreaviso: false,
			preaviso: payload.list,
			countPreaviso: payload.total,
		};
	}



	if (type === actions.PAGINATION_CHANGED) {
		return {
			...state,
			pagination: payload || {
				current: 1,
				pageSize: INITIAL_PAGE_SIZE,
			},
		};
	}

	if (type === actions.SORTER_CHANGED) {
		return {
			...state,
			sorter: payload || {},
		};
	}

	if (type === actions.CLEAN_SORTER) {
		return {
			...state,
			sorter: {},
		};
	}

	if (type === actions.CHANGE_FILTER) {
		return {
			...state,
			filter: payload,
		};
	}
	

	if (type === actions.GET_SERVICES) {
		return {
			...state,
			services: payload,
		};
	}
	if (type === actions.GET_CONTAINER_SUCCESS) {

		return {
			...state,
			getContainer: payload.data,
		};
	}
	if (type === actions.GET_CONTAINER_ERROR) {

		return {
			...state,
			getContainer: payload,
		};
	}


	return state;
};

export default expoFullListReducers;