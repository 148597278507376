import { connectRouter } from "connected-react-router";
import layout from "src/modules/layout/layoutReducers";
import auth from "src/modules/auth/authReducers";
import singleLoad from "src/modules/singleLoad/singleLoadReducers";
import company from "src/modules/company/companyReducers";
import providers from "src/modules/providers/providersReducer";
import relations from "src/modules/relations/relationsReducer";
import bookings from "src/modules/bookings/bookingsReducer";
import exportR from "src/modules/export/exportReducer";
import users from "src/modules/users/usersReducer";
import config from "src/modules/config/configReducer";
import { combineReducers } from "redux";
import adminTZ from "src/modules/config/configReducer";
import turns from "src/modules/turn/turnsReducer";
import empty from "src/modules/empty/emptyReducer";
import expoFull from "src/modules/expofull/expoFullReducer";
import providerCompany from "src/modules/providerCompany/providerCompanyReducers";
import myusers from "src/modules/users/user-management/usersReducer";
import consolidated from "src/modules/export/export-consolidation/consolidatedReducers";
import stakeholderBooking from "src/modules/stakeholderBooking/stakeholderBookingReducer";
import scheduleOperations from "src/modules/scheduledOperations/scheduleOperationsReducer";
import general2 from "src/modules/shared/generals/GeneralReducers";
import billing from "src/modules/billing/billingReducer";
import checkingAccount from "src/modules/checkingAccount/checkingAccountReducer";
import receipt from "src/modules/receipt/receiptReducer";
import billingUser from "./billingUser/billingUserReducer";

export default (history) =>
  combineReducers({
    router: connectRouter(history),
    general2,
    layout,
    auth,
    singleLoad,
    company,
    providers,
    providerCompany,
    myusers,
    relations,
    bookings,
    exportR,
    users,
    config,
    adminTZ,
    turns,
    empty,
    expoFull,
    consolidated,
    stakeholderBooking,
    scheduleOperations,
    billing,
    receipt,
    checkingAccount,
    billingUser
  });
