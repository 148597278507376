let inMemoryToken = null;

const keyToken = (numberKeys: number, key:string) => {
  if(numberKeys < 0) return null 
  let currentKey = localStorage.key(numberKeys);
  if(currentKey && currentKey?.includes(key))
    return currentKey;
  
  return keyToken(numberKeys - 1, key);
}

export class AuthToken {


  static set(token, rememberMe) {
    if (rememberMe) {
      localStorage.setItem('jwt', token || '');
    } else {
      inMemoryToken = token;
      localStorage.setItem('jwt', '');
    }
  }
    
  static clearToken(){
    localStorage.clear();
  }

  static applyFromLocationUrlIfExists() {
    const urlParams = new URLSearchParams(
      window.location.search,
    );
    const authToken = urlParams.get('authToken');

    if (!authToken) {
      return;
    }

    this.set(authToken, true);
    window.history.replaceState(
      {},
      document.title,
      window.location.origin,
    );
  }
}
