import Errors from "src/modules/shared/error/errors";
import service from "../emptyService";
import emptySelectors from "src/modules/empty/list/emptyListSelectors";
import { getHistory } from "src/modules/store";
import Message from "src/view/shared/message";
import stakeholderBookingListActions from "src/modules/stakeholderBooking/list/stakeholderBookingListActions";

const prefix = "EMPTY";

const emptyActions = {
  FETCH_STARTED: `${prefix}_FETCH_STARTED`,

  FETCH_SUCCESS: `${prefix}_FETCH_SUCCESS`,

  FETCH_ERROR: `${prefix}_FETCH_ERROR`,

  PAGINATION_CHANGED: `${prefix}_PAGINATION_CHANGED`,

  SORTER_CHANGED: `${prefix}_SORTER_CHANGED`,

  CHANGE_FILTER: `${prefix}_CHANGE_FILTER`,

  CHANGE_FILTER_LABELS: `${prefix}_CHANGE_FILTER_LABELS`,

  CREATE_SUCCESS: `${prefix}_CREATE_SUCCESS`,

  EMPTY_HEADER_SUCCESS: `${prefix}_HEADER_SUCCESS`,

  EMPTY_HEADER_CREATE: `${prefix}_HEADER_CREATE`,

  EMPTY_HEADER_BYID: `${prefix}_HEADER_BYID`,

  EMPTY_BODY_BY_HEADER_ID: `${prefix}_BODY_BY_HEADER_ID`,

  EMPTY_BODY_CREATE: `${prefix}_BODY_CREATE`,

  RESERVATION_SUCCESS: `${prefix}_RESERVATION_SUCCESS`,

  FETCH_STAKEHOLDER: `${prefix}_FETCH_STAKEHOLDER`,
  FETCH_STAKEHOLDER_BY_COMPANY_ID: `${prefix}_FETCH_STAKEHOLDER_BY_COMPANY_ID`,

  ORDERS: `${prefix}_ORDERS`,

  ORDERS_NOT_PAGINATION: `${prefix}_ORDERS_NOT_PAGINATION`,

  ADD_STAKEHOLDER: `${prefix}_ADD_STAKEHOLDER`,
  UPDATE_STAKEHOLDER: `${prefix}_UPDATE_STAKEHOLDER`,
  REMOVE_STAKEHOLDER: `${prefix}_REMOVE_STAKEHOLDER`,

  CHANGE_ORDER_FILTER: `${prefix}_CHANGE_ORDER_FILTER`,

  ORDER_PAGINATION_CHANGED: `${prefix}_ORDER_PAGINATION_CHANGED`,

  SORTER_ORDER_CHANGED: `${prefix}_SORTER_ORDER_CHANGED`,

  GET_BOOKING_NAVIS: `${prefix}_GET_BOOKING_NAVIS`,
  GET_CUIT_STARTED: `${prefix}_GET_CUIT_STARTED`,
  GET_CUIT_SUCCESS: `${prefix}_GET_CUIT_SUCCESS`,

  GET_CUIT_ERROR: `${prefix}_GET_CUIT_ERROR`,

  ADD_EMPTY_BODY: `${prefix}_ADD_EMPTY_BODY`,

  EDIT_EMPTY_BODY: `${prefix}_EDIT_EMPTY_BODY`,

  BOOKING_FORWARDER: `${prefix}_BOOKING_FORWARDER`,
  BOOKING_FORWARDER_ERROR: `${prefix}_BOOKING_FORWARDER_ERROR`,
  RESET_OFFSET: `${prefix}_RESET_OFFSET`,

  FETCH_STARTED_BODY: `${prefix}_FETCH_STARTED_BODY`,
  FETCH_SUCCESS_BODY: `${prefix}_FETCH_SUCCESS_BODY`,
  FETCH_ERROR_BODY: `${prefix}_FETCH_ERROR_BODY`,
  PAGINATION_CHANGED_BODY: `${prefix}_PAGINATION_CHANGED_BODY`,
  SORTER_CHANGED_BODY: `${prefix}_SORTER_CHANGED_BODY`,

  FETCH_ATTACHMENT_STARTED: `${prefix}_FETCH_ATTACHMENT_STARTED`,
  FETCH_ATTACHMENT_SUCCESS: `${prefix}_FETCH_ATTACHMENT_SUCCESS`,
  FETCH_ATTACHMENT_ERROR: `${prefix}_FETCH_ATTACHMENT_ERROR`,
  FETCH_ERROR_BOOKING: `${prefix}_FETCH_ERROR_BOOKING`,
  FETCH_BOOKING_STARTED: `${prefix}_FETCH_BOOKING_STARTED`,

  GET_STATUS_OPTIONS: `${prefix}_GET_STATUS_OPTIONS`,
  GET_STATUS: `${prefix}_GET_STATUS`,
  ERROR_GET_BOOKING_NAVIS: `${prefix}_ERROR_GET_BOOKING_NAVIS}`,
  START_BOOKING_FORWARDER: `${prefix}_START_BOOKING_FORWARDER`,
  GET_BOOKING_FORWARDER: `${prefix}_GET_BOOKING_FORWARDER`,
  ERROR_BOOKING_FORWARDER: `${prefix}_ERROR_BOOKING_FORWARDER`,
  RESET_BOOKING_FORWARDER: `${prefix}_RESET_BOOKING_FORWARDER`,

  STAKEHOLDERS_NOT_PAGINATION: `${prefix}_STAKEHOLDERS_NOT_PAGINATION`,
  GET_CONTAINER: `${prefix}_GET_CONTAINER`,
  EMPTY_HEADER_BYID_STARTED: `${prefix}_EMPTY_HEADER_BYID_STARTED`,
  STARTED_BOOKING_FORWARDER: `${prefix}_STARTED_BOOKING_FORWARDER`,

  doFetchAttachments: (emptyId) => async (dispatch, getState) => {
    try {
      dispatch({
        type: emptyActions.FETCH_ATTACHMENT_STARTED,
      });

      const rows = await service.getAttachment(emptyId);

      dispatch({
        type: emptyActions.FETCH_ATTACHMENT_SUCCESS,
        payload: rows,
      });
    } catch (error) {
      // Errors.handle(error);
      dispatch({
        type: emptyActions.FETCH_ERROR,
      });
    }
  },

  emptyHeaderStatus: () => async (dispatch) => {
    try {
      const response = await service.emptyHeaderStatus();
      dispatch({
        type: emptyActions.GET_STATUS,
        payload: response,
      });
    } catch (error) {
      Errors.showMessage(error);
    }
  },

  optionsEmptyHeaderStatus: () => async (dispatch) => {
    try {
      const response = await service.emptyHeaderStatus();
      dispatch({
        type: emptyActions.GET_STATUS_OPTIONS,
        payload: response,
      });
    } catch (error) {
      Errors.showMessage(error);
    }
  },

  updateStakeholder: (orderId, stakeholderId) => async (dispatch, getState) => {
    try {
      /*dispatch({
        type: emptyActions.FETCH_STARTED,
      });*/

      await service.updateStakeholder(orderId, stakeholderId);

      dispatch(stakeholderBookingListActions.doFetch());
    } catch (error) {
      Message.error("No se puede vincular el proveedor");
      dispatch({
        type: emptyActions.FETCH_ERROR,
      });
    }
  },

  removeStakeholder: (orderId) => async (dispatch, getState) => {
    try {
      await service.removeStakeholder(orderId);
      dispatch(stakeholderBookingListActions.doFetch());
    } catch (error) {
      Errors.handle(error);
      dispatch({
        type: emptyActions.FETCH_ERROR,
      });
    }
  },

  bookingForwarder:
    (data, setData?, setBookingDelegated?, setCompanyId?, setStakeholderId?) =>
      async (dispatch, getState) => {
        try {
          dispatch({
            type: emptyActions.STARTED_BOOKING_FORWARDER,
          });

          dispatch({ type: emptyActions.RESET_BOOKING_FORWARDER });
          const response = await service.bookingForwarder(data);

          dispatch({
            type: emptyActions.BOOKING_FORWARDER,
            payload: response,
          });

          setCompanyId && setCompanyId(null);
          if (!response.data) {
            Message.error(`${response.message}`);
            setData(false);
          }

          if (response.data && response.data?.clientCompany?.id) {
            Message.success("El booking ya fue asignado");
          }

          if (response.data && !response.data?.clientCompany?.id) {
            setData(true);
          }

        } catch (error) {
          setData(false);
          Errors.showMessage(error);
          dispatch({ type: emptyActions.BOOKING_FORWARDER_ERROR });
        }
      },

  doChangePagination: (pagination) => async (dispatch) => {
    dispatch({
      type: emptyActions.PAGINATION_CHANGED,
      payload: pagination,
    });

    dispatch(emptyActions.allEmptyHeader());
  },

  doChangeSort: (sorter) => async (dispatch) => {
    dispatch({
      type: emptyActions.SORTER_CHANGED,
      payload: sorter,
    });

    dispatch(emptyActions.allEmptyHeader());
  },

  doChangeFilter: (values) => async (dispatch) => {
    dispatch({
      type: emptyActions.RESET_OFFSET,
    });
    dispatch({
      type: emptyActions.CHANGE_FILTER,
      payload: values,
    });

    dispatch(emptyActions.allEmptyHeader());
  },

  doChangeFilterLabel: (values) => async (dispatch) => {
    dispatch({
      type: emptyActions.CHANGE_FILTER_LABELS,
      payload: values,
    });
  },

  doChangeOrderFilter: (values) => async (dispatch) => {
    dispatch({
      type: emptyActions.CHANGE_ORDER_FILTER,
      payload: values,
    });

    dispatch(emptyActions.getOrders());
  },

  doChangeOrderFilterNotPagination: (values?) => async (dispatch) => {
    dispatch({
      type: emptyActions.CHANGE_ORDER_FILTER,
      payload: values,
    });

    dispatch(emptyActions.getOrdersNotPagination());
  },

  doChangeOrderPagination: (pagination) => async (dispatch) => {
    dispatch({
      type: emptyActions.ORDER_PAGINATION_CHANGED,
      payload: pagination,
    });

    dispatch(emptyActions.getOrders());
  },

  doChangeOrderSort: (sorter) => async (dispatch) => {
    dispatch({
      type: emptyActions.SORTER_ORDER_CHANGED,
      payload: sorter,
    });

    dispatch(emptyActions.getOrders());
  },

  cancelFormContainerEmpty: (data) => async (dispatch) => {
    try {
      await service.cancelFormContainerEmpty(data);
      dispatch(emptyActions.allEmptyHeader());
    } catch (error) {
      Errors.handle(error);
    }
  },
  allEmptyHeader: () => async (dispatch, getState) => {
    try {
      dispatch({
        type: emptyActions.FETCH_STARTED,
      });

      const response = await service.allEmptyHeader(
        emptySelectors.selectOrderBy(getState()),
        emptySelectors.selectLimit(getState()),
        emptySelectors.selectOffset(getState()),
        emptySelectors.selectFilter(getState())
      );

      dispatch({
        type: emptyActions.EMPTY_HEADER_SUCCESS,
        payload: response,
      });
    } catch (error) {
      Errors.showMessage(error);
      dispatch({
        type: emptyActions.FETCH_ERROR,
      });
    }
  },

  createEmptyHeader: (values, files?, resetLoading?) => async (dispatch, getState) => {
    try {
      const response = await service.createEmptyHeader(values, files);
      dispatch({
        type: emptyActions.EMPTY_HEADER_CREATE,
        payload: response,
      });

      getHistory().push(`/manageEmpty/request/${response.data.id}`, {
        bookingNumber: response.data.bookingNumber,
        clientCompanyCUIT: response.data.clientCompanyCUIT,
        operationId: response.data?.operationId,
      });
      Message.success(`${response?.message}`);
    } catch (error) {
      resetLoading && resetLoading();
      Errors.showMessage(error);
    }
  },

  createEmptyBody:
    (values, setStatusMessage?, handleClose?) => async (dispatch, getState) => {
      try {
        const response = await service.createEmptyBody(values);
        dispatch(emptyActions.emptyBodyByHeaderID(response.data.emptyHeaderId));
        dispatch({
          type: emptyActions.ADD_EMPTY_BODY,
          payload: response,
        });
        setStatusMessage(true);
        handleClose && handleClose();
        Message.success(`${response.message}`);
      } catch (error) {
        Errors.showMessage(error);
        setStatusMessage(false);
      }
    },

  EditEmptyBody:
    (values, setStatusMessage?, handleClose?) => async (dispatch, getState) => {
      try {
        const response = await service.EditEmptyBody(values);

        dispatch(emptyActions.emptyBodyByHeaderID(response.emptyHeaderId));
        dispatch(emptyActions.findEmptyHeaderById(response.emptyHeaderId));
        dispatch({
          type: emptyActions.EDIT_EMPTY_BODY,
          payload: response,
        });
        setStatusMessage(true);
        handleClose && handleClose();
        Message.success("Edición  Éxitosa");
      } catch (error) {
        Errors.showMessage(error);
        setStatusMessage(false);
      }
    },

  //paginación de body empty
  findEmptyHeaderById: (data) => async (dispatch, getState) => {
    try {
      dispatch({
        type: emptyActions.EMPTY_HEADER_BYID_STARTED,
      });
      const response = await service.findEmptyHeaderById(data);

      dispatch({
        type: emptyActions.EMPTY_HEADER_BYID,
        payload: response,
      });
    } catch (error) {
      Errors.handle(error);
    }
  },

  emptyBodyByHeaderID: (data) => async (dispatch, getState) => {
    try {
      dispatch({ type: emptyActions.FETCH_STARTED_BODY });

      const response = await service.emptyBodyByHeaderID(
        data,
        emptySelectors.selectOrderByBody(getState()),
        emptySelectors.selectLimitBody(getState()),
        emptySelectors.selectOffsetBody(getState())
      );

      dispatch({
        type: emptyActions.FETCH_SUCCESS_BODY,
        payload: response,
      });
    } catch (error) {
      Errors.showMessage(error);
      dispatch({
        type: emptyActions.FETCH_ERROR_BODY,
      });
    }
  },

  doChangePaginationBodyEmpty: (data, pagination) => async (dispatch) => {
    dispatch({
      type: emptyActions.PAGINATION_CHANGED_BODY,
      payload: pagination,
    });

    dispatch(emptyActions.emptyBodyByHeaderID(data));
  },

  doChangeSortBodyEmpty: (data, sorter) => async (dispatch) => {
    dispatch({
      type: emptyActions.SORTER_CHANGED_BODY,
      payload: sorter,
    });

    dispatch(emptyActions.emptyBodyByHeaderID(data));
  },

  findEmptyBodyByEmptyHeaderId: (data) => async (dispatch, getState) => {
    try {
      const response = await service.findEmptyBodyByEmptyHeaderId(data);

      dispatch({
        type: emptyActions.EMPTY_BODY_BY_HEADER_ID,
        payload: response,
      });
    } catch (error) {
      Errors.handle(error);
    }
  },

  deletemptyBody: (values, setOpen?) => async (dispatch, getState) => {
    const { id, emptyHeaderId } = values;
    try {
      const response = await service.deleteEmptyBody(id);

      dispatch(emptyActions.emptyBodyByHeaderID(emptyHeaderId));
      dispatch(emptyActions.findEmptyHeaderById(emptyHeaderId));
      Message.success(`${response.message}`);
      setOpen(false);
    } catch (error) {
      Errors.showMessage(error);
    }
  },

  deletemptyHeader: (values) => async (dispatch, getState) => {
    try {
      const response = await service.deleteEmptyHeader(values);

      dispatch(emptyActions.allEmptyHeader());

      Message.success(`${response.message}`);
    } catch (error) {
      Message.error(`Eliminar contenedores antes de eliminar la operación`);
    }
  },

  createReservationEmptyBody: (data, setButtonAddTurn?) => async (dispatch) => {
    try {
      const response = await service.createReservationEmptyBody(data);

      dispatch({
        type: emptyActions.RESERVATION_SUCCESS,
        payload: response,
      });
      Message.success(`Turno editado con éxito`);

      getHistory().replace(`/manageEmpty/request/${data.idHeader}`, {
        bookingNumber: data?.bookingNumber,
        clientCompanyCUIT: data?.clientCompanyCUIT,
      });
    } catch (error) {
      Message.success(`Turno editado con éxito`);
      getHistory().replace(`/manageEmpty/request/${data.idHeader}`, {
        bookingNumber: data?.bookingNumber,
        clientCompanyCUIT: data?.clientCompanyCUIT,
      });
      setButtonAddTurn(false);
      // Errors.handle(error)
      // Message.error(`Que esta pasando?`) //edita pero entra en error
    }
  },

  pageBackEmpty: (data) => async (dispatch) => {
    getHistory().replace(`/manageEmpty/request/${data.idHeader}`, {
      bookingNumber: data?.bookingNumber,
      clientCompanyCUIT: data?.clientCompanyCUIT,
    });
  },

  getStakeholder:
    (clientCompanyId?, isSelect?) => async (dispatch, getState) => {
      try {
        const data = await service.getStakeholder(clientCompanyId, isSelect);

        dispatch({
          type: emptyActions.FETCH_STAKEHOLDER,
          payload: data,
        });
      } catch (error) {
        // Errors.handle(error);
      }
    },

  getStakeholdersNotPagination: () => async (dispatch, getState) => {
    try {
      const response = await service.getStakeholdersNotPagination(
        emptySelectors.selectOrderBy(getState()),
        9999999,
        emptySelectors.selectOffset(getState()),
        emptySelectors.selectFilterOrder(getState())
      );

      dispatch({
        type: emptyActions.STAKEHOLDERS_NOT_PAGINATION,
        payload: response,
      });
    } catch (error) {
      // Errors.handle(error);
    }
  },

  getStakeholderByCompanyId:
    (clientCompanyId?) => async (dispatch, getState) => {
      try {
        const data = await service.getStakeholderByCompanyId(clientCompanyId);

        dispatch({
          type: emptyActions.FETCH_STAKEHOLDER_BY_COMPANY_ID,
          payload: data,
        });
      } catch (error) {
        // Errors.handle(error);
      }
    },

  stakeholderForwarder: (data) => async (dispatch, getState) => {
    try {
      const response = await service.stakeholderForwarder(data);

      dispatch({
        type: emptyActions.FETCH_STAKEHOLDER,
        payload: data,
      });

      getHistory().push("/assignment/bk/bl");

      Message.success(`${response.message}`);
    } catch (error) {
      Errors.handle(error);
    }
  },

  autoStakeHolderForwarder:
    (data, setData?, stakeholderId?) => async (dispatch) => {
      try {
        dispatch({
          type: emptyActions.START_BOOKING_FORWARDER,
        });
        const response = await service.autoStakeholderForwarder(
          data,
          stakeholderId
        );
        dispatch({
          type: emptyActions.GET_BOOKING_FORWARDER,
          payload: response.data,
        });
        Message.success(`${response.message}`);
        setData(false);
      } catch (error) {
        dispatch({
          type: emptyActions.ERROR_BOOKING_FORWARDER,
        });
        Errors.showMessage(error);
      }
    },

  getOrders: () => async (dispatch, getState) => {
    try {
      dispatch({
        type: emptyActions.FETCH_STARTED,
      });

      const response = await service.getOrders(
        emptySelectors.selectCurrentOrderBy(getState()),
        emptySelectors.selectOrderLimit(getState()),
        emptySelectors.selectOrderOffset(getState()),
        emptySelectors.selectFilterOrder(getState())
      );

      dispatch({
        type: emptyActions.ORDERS,
        payload: response,
      });
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: emptyActions.FETCH_ERROR,
      });
    }
  },

  getOrdersNotPagination: () => async (dispatch, getState) => {
    try {
      const response = await service.getOrders(
        emptySelectors.selectOrderBy(getState()),
        9999999,
        emptySelectors.selectOffset(getState()),
        emptySelectors.selectFilterOrder(getState())
      );
      dispatch({
        type: emptyActions.ORDERS_NOT_PAGINATION,
        payload: response,
      });
    } catch (error) {
      // Errors.handle(error);
    }
  },

  searchBooking: (data, message?) => async (dispatch, getState) => {
    try {
      dispatch({
        type: emptyActions.FETCH_BOOKING_STARTED,
      });

      const response = await service.searchBooking(data);
      if (!response.data) {
        dispatch({
          type: emptyActions.GET_BOOKING_NAVIS,
          payload: {},
        });
      } else {
        dispatch({
          type: emptyActions.GET_BOOKING_NAVIS,
          payload: response.data,
        });
      }
    } catch (error) {
      dispatch({
        type: emptyActions.ERROR_GET_BOOKING_NAVIS,
      });
      Boolean(message) && Errors.showMessage(error);
    }
  },

  resetBooking: () => async (dispatch, getState) => {
    try {
      dispatch({
        type: emptyActions.FETCH_BOOKING_STARTED,
      });
    } catch (error) {
      Errors.showMessage(error);
    }
  },
  searchCuit: (data, setStatusMessage?) => async (dispatch, getState) => {
    try {
      dispatch({
        type: emptyActions.GET_CUIT_STARTED,
      });

      const response = await service.searchCuit(data);
      dispatch({
        type: emptyActions.GET_CUIT_SUCCESS,
        payload: response,
      });

      Message.success(`${response.message}`);

      setStatusMessage(false);
    } catch (error) {
      setStatusMessage(true);
      dispatch({
        type: emptyActions.GET_CUIT_ERROR,
      });
      Errors.showMessage(
        error,
        "- Por caída del servicio de softland (504), corregir en este caso por"
      );
    }
  },
  resetCuitCode: () => async (dispatch, getState) => {
    try {
      dispatch({
        type: emptyActions.GET_CUIT_STARTED,
      });
    } catch (error) {
      // Errors.showMessage(error);
    }
  },

  scheduledOther: (data) => async (dispatch) => {
    try {
      const response = await service.scheduledOther(data);
      getHistory().replace(`/manageEmpty/request/${data.idHeader}`, {
        bookingNumber: data?.bookingNumber,
        clientCompanyCUIT: data?.clientCompanyCUIT,
      });
      Message.success(`${response.message}`);
    } catch (error) {
      Errors.showMessage(error);
    }
  },
  typeContainer: (data, message?) => async (dispatch) => {
    try {
      const response = await service.typeContainer(data);
      dispatch({
        type: emptyActions.GET_CONTAINER,
        payload: response,
      });
    } catch (error) {
      Boolean(message) && Errors.showMessage(error);
    }
  },
};

export default emptyActions;
