import swal from 'sweetalert'
import authAxios2 from '../shared/axios/authAxios2';

export default class TurnsService {
	static async getAllOperations(orderBy?, limit?, offset?, filter?) {
		const params = {
			sort: orderBy,
			size: limit,
			page: offset,
			filter: filter,
		};
		const response = await authAxios2.get('/portal/operation', { params });
		return response.data;
	}
	static async getAllTurnsForOperation(data?) {

		const response = await authAxios2.get(`/portal/turn/${data}`);
		return response.data;
	}
	static async addTurn(data) {

		const response = await authAxios2.post(`/portal/turn/${data.id}`);
		if (response.status === 201) {

			swal(`${response.data.message}`, {
				buttons: {
					catch: {
						text: "Gestionar Reservas",
						value: "gestionar",
					},
					Realizar_otra_reserva: { text: "Realizar otra reserva", value: "nueva" },
					inicio: { text: "Volver al inicio" }
				},
			}).then((value) => {
				switch (value) {
					case "gestionar":
						return window.location.pathname = "/turns/manageTurns"
					case "nueva":
						return window.location.pathname = "/turns/bookTurns"
					default:
						return window.location.reload()
				}
			})
		}
		return response.data

	}
	static async getAllReservations(orderBy?, limit?, offset?, filter?) {
		const params = {
			sort: orderBy,
			size: limit,
			page: offset,
			filter: filter,
		};
		const response = await authAxios2.get('/portal/turn/reservations', { params });
		return response.data;
	}


	static async turnEdit(data) {

		const response = await authAxios2.put('/portal/turn/edit-reservation', data);
		if (response.status === 200) {
			swal("Exitoso!", "Modificaste con exito los datos de tu turno", "success");

		}
		return response.data;

	}

	static async turnDelete(data) {

		const response = await authAxios2.put('/portal/turn/cancel-reservation', data);
		if (response.status === 200) {
			swal("Exitoso!", "tu cancelación de turno fue realizado con exito!", "success");

		}
		return response.data;

	}
	static async getRag(orderBy?, limit?, offset?, filter?) {
		const params = {
			sort: orderBy,
			size: limit,
			page: offset,
			filter: filter,
		};

		const response = await authAxios2.get('/portal/portal/rag/', { params });

		return response.data.list;
	}

	static async ocupationForColor(data) {
		const { date, idRag, operationId } = data
		const titleOperacion = () => {
			if (operationId === 1 || operationId === 2) return "EMPTY";
			if (operationId === 3) return "FULL";
			if (operationId === 5) return "CONSOLIDATED";
		};
		const response = await authAxios2.post(`/portal/turn/slopts`, idRag, { params: { typeTurn: titleOperacion(), date } });
		return response.data;
	}









}