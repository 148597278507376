import Axios from 'axios';
import config from 'src/config';
import { getLanguageCode } from 'src/i18n';
import Qs from 'qs';
import moment from 'moment';
import { TokenAzureAd } from 'src/modules/auth/TokenAzureAd';

const authAxios3 = Axios.create({
  baseURL: `${config.backendUrl}`,
  paramsSerializer: function (params) {
    return Qs.stringify(params, {
      arrayFormat: 'brackets',
      filter: (prefix, value) => {
        if (
          moment.isMoment(value) ||
          value instanceof Date
        ) {
          return value.toISOString();
        }

        return value;
      },
    });
  },
});

authAxios3.interceptors.request.use(
  async function (options) {
    const token = await TokenAzureAd.getIdToken();

    if (token) {
      options.headers['Authorization'] = `Bearer ${token}`;
    }

    options.headers['Content-Type'] = 'multipart/form-data'
    options.headers['Accept-Language'] = getLanguageCode();

    return options;
  },
  function (error) {
    return Promise.reject(error);
  },
);


export default authAxios3
