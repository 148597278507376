import PermissionChecker from 'src/modules/auth/permissionChecker';
import { Redirect, Route } from 'react-router-dom';
import { useIsAuthenticated } from '@azure/msal-react';

function PublicRoute({
  component: Component,
  currentUser,
  ...rest
}) {
  const isAuthenticated = useIsAuthenticated();
  return (
    <Route
      {...rest}
      render={(props) => {
        if (isAuthenticated) {
          return <Redirect to="/home" />;
        }

        return <Component {...props} />;
      }}
    />
  );
}

export default PublicRoute;
