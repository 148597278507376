import Errors from 'src/modules/shared/error/errors';
import ConfigService from '../configService';
import schedulesSelectors from './schedulesSelectors';

const prefix = 'SCHEDULES';

const schedulesActions = {
	FETCH_STARTED: `${prefix}_FETCH_STARTED`,
	FETCH_SUCCESS: `${prefix}_FETCH_SUCCESS`,
	FETCH_ERROR: `${prefix}_FETCH_ERROR`,

	PAGINATION_CHANGED: `${prefix}_PAGINATION_CHANGED`,

	SORTER_CHANGED: `${prefix}_SORTER_CHANGED`,

	doFetch: () => async (dispatch, getState) => {
		try {
			dispatch({
				type: schedulesActions.FETCH_STARTED,
			});

			const response = await ConfigService.getAllSchedules(
				schedulesSelectors.selectOrderBy(getState()),
				schedulesSelectors.selectLimit(getState()),
				schedulesSelectors.selectOffset(getState())
			);

			dispatch({
				type: schedulesActions.FETCH_SUCCESS,
				payload: response,
			});
		} catch (error) {
			// Errors.handle(error);

			dispatch({
				type: schedulesActions.FETCH_ERROR,
			});
		}
	},

	doChangePagination: (pagination) => async (dispatch) => {
		dispatch({
			type: schedulesActions.PAGINATION_CHANGED,
			payload: pagination,
		});

		dispatch(schedulesActions.doFetch());
	},

	doChangeSort: (sorter) => async (dispatch) => {
		dispatch({
			type: schedulesActions.SORTER_CHANGED,
			payload: sorter,
		});

		dispatch(schedulesActions.doFetch());
	},

	editOperation: (data) => async (dispatch) => {
		try {
			await ConfigService.editOperation(data);
			
			dispatch(schedulesActions.doFetch());
		} catch (error) {
			Errors.handle(error);
		}
	},
};

export default schedulesActions;
