import service from 'src/modules/auth/authService';
import Errors from 'src/modules/shared/error/errors';
import Message from 'src/view/shared/message';
import { i18n } from 'src/i18n';
import { getHistory } from 'src/modules/store';
import { AuthToken } from 'src/modules/auth/authToken';
import Roles from 'src/security/roles';
import { authResource } from './authResource';
import { TokenAzureAd } from './TokenAzureAd';
import stakeholderActions from "src/modules/empty/list/emptyListActions";
import actions from "src/modules/company/list/companyListActions";

const roles = Roles.values;

const currentRole = rolesArray => {
	let isAdmin = rolesArray?.includes(roles.admin);
	let isConsulta = rolesArray?.includes(roles.consulta);
	let isTransaccional = rolesArray?.includes(roles.transaccional);
	return {
		isAdmin,
		isConsulta,
		isTransaccional,
	}
};

const prefix = 'AUTH';

const authActions = {
	ERROR_MESSAGE_CLEARED: `${prefix}_ERROR_MESSAGE_CLEARED`,

	RESET_ACTIONSEND: `${prefix}_RESET_ACTIONSEND`,

	AUTH_INIT_SUCCESS: `${prefix}_INIT_SUCCESS`,
	AUTH_INIT_ERROR: `${prefix}_INIT_ERROR`,

	AUTH_START: `${prefix}_START`,
	AUTH_SUCCESS: `${prefix}_SUCCESS`,
	AUTH_ERROR: `${prefix}_ERROR`,

	UPDATE_PROFILE_START: `${prefix}_UPDATE_PROFILE_START`,
	UPDATE_PROFILE_SUCCESS: `${prefix}_UPDATE_PROFILE_SUCCESS`,
	UPDATE_PROFILE_ERROR: `${prefix}_UPDATE_PROFILE_ERROR`,

	CURRENT_USER_REFRESH_START: `${prefix}_CURRENT_USER_REFRESH_START`,
	CURRENT_USER_REFRESH_SUCCESS: `${prefix}_CURRENT_USER_REFRESH_SUCCESS`,
	CURRENT_USER_REFRESH_ERROR: `${prefix}_CURRENT_USER_REFRESH_ERROR`,

	UPDATE_SIGNUP_MESSAGE_START: `${prefix}_UPDATE_SIGNUP_MESSAGE_START`,
	UPDATE_SIGNUP_MESSAGE_SUCCESS: `${prefix}_UPDATE_SIGNUP_MESSAGE_SUCCESS`,
	UPDATE_SIGNUP_MESSAGE_ERROR: `${prefix}_UPDATE_SIGNUP_MESSAGE_ERROR`,
	UPDATE_SIGNUP_MESSAGE: 'UPDATE_SIGNUP_MESSAGE',

	SAVE_FORM_PERSONAL_DATA: 'SAVE_FORM_PERSONAL_DATA',
	SAVE_FORM_BUSINESS_DATA: 'SAVE_FORM_BUSINESS_DATA',

	VALIDATE_USER_START: 'VALIDATE_USER_START',
	VALIDATE_USER_SUCCESS: 'VALIDATE_USER_SUCCESS',
	VALIDATE_USER_ERROR: 'VALIDATE_USER_ERROR',

	doClearErrorMessage() {
		return {
			type: authActions.ERROR_MESSAGE_CLEARED,
		};
	},

	doSigninWithEmailAndPassword: (username, password, rememberMe) => async (dispatch) => {
		try {
			dispatch({ type: authActions.AUTH_START });

			let currentUser;

			const token = await service.signinWithEmailAndPassword(username, password);

			AuthToken.set(token.access_token, rememberMe);
			currentUser = await service.fetchMe();

			var { isAdmin, isConsulta, isTransaccional } = currentRole(currentUser.roles[0].name);

			const permissions: any[] = []

			authResource.setResource(permissions);

			dispatch({
				type: authActions.AUTH_SUCCESS,
				payload: {
					currentUser,
					roles: {
						isAdmin: isAdmin,
						isConsulta: isConsulta,
						isTransaccional: isTransaccional
					},
				},
			});
		} catch (error) {
			await service.signout();

			if (Errors.errorCode(error) !== 400) {
				Errors.handle(error);
			}

			dispatch({
				type: authActions.AUTH_ERROR,
				payload: Errors.selectMessage(error),
			});
		}
	},

	doSignout: () => async (dispatch) => {
		try {
			dispatch({ type: authActions.AUTH_START });
			await service.signout();

			dispatch({
				type: authActions.AUTH_SUCCESS,
				payload: {
					currentUser: null,
				},
			});

			getHistory().push("/")
		} catch (error) {
			Errors.handle(error);

			dispatch({
				type: authActions.AUTH_ERROR,
			});
		}
	},

	doInit: () => async (dispatch) => {
		try {
			const token = await TokenAzureAd.getIdToken();
			let currentUser;

			if (token) {
				currentUser = await service.fetchMe();
				currentUser?.userType === 'ClientCompanyUser' && dispatch(actions.doFetchClientCompany());
				currentUser?.userType === 'StakeholderUser' && dispatch(stakeholderActions.getStakeholder());
			}

			var { isAdmin, isConsulta, isTransaccional } = currentRole(currentUser?.roles[0].name)
			dispatch({
				type: authActions.AUTH_INIT_SUCCESS,
				payload: {
					currentUser,
					roles: {
						isAdmin: isAdmin,
						isConsulta: isConsulta,
						isTransaccional: isTransaccional
					},
				},
			});
		} catch (error) {
			dispatch({
				type: authActions.AUTH_INIT_ERROR,
				payload: error,
			});
		}
	},

	doRefreshCurrentUser: () => async (dispatch) => {
		try {
			dispatch({
				type: authActions.CURRENT_USER_REFRESH_START,
			});

			let currentUser;
			const token = await TokenAzureAd.getIdToken();

			if (token) {
				currentUser = await service.fetchMe();
			}

			console.log(currentUser.roles)

			// var { isTZ, isCliente, isDespachante, isForwarder } = currentRelation(currentUser.userTypes[0].name)
			var { isAdmin, isConsulta, isTransaccional } = currentRole(currentUser.roles[0].name)

			dispatch({
				type: authActions.CURRENT_USER_REFRESH_SUCCESS,
				payload: {
					currentUser,
					roles: {
						isAdmin: isAdmin,
						isConsulta: isConsulta,
						isTransaccional: isTransaccional
					},
				},
			});
		} catch (error) {
			service.signout();
			Errors.handle(error);

			dispatch({
				type: authActions.CURRENT_USER_REFRESH_ERROR,
				payload: error,
			});
		}
	},

	doUpdateProfile: (data) => async (dispatch) => {
		try {
			dispatch({
				type: authActions.UPDATE_PROFILE_START,
			});

			await service.updateProfile(data);

			dispatch({
				type: authActions.UPDATE_PROFILE_SUCCESS,
			});
			await dispatch(authActions.doRefreshCurrentUser());
			Message.success(i18n('auth.profile.success'));
			getHistory().push('/home');
		} catch (error) {
			Errors.handle(error);

			dispatch({
				type: authActions.UPDATE_PROFILE_ERROR,
			});
		}
	},

	doSignUp: (data) => async (dispatch) => {
		try {
			dispatch({
				type: authActions.UPDATE_SIGNUP_MESSAGE_START,
			});
			const response = await service.signUp(data);
			dispatch({
				type: authActions.UPDATE_SIGNUP_MESSAGE_SUCCESS,
				payload: response,
			});

			Message.success(i18n('auth.message.signupSuccess'))


		} catch (error) {
			dispatch({
				type: authActions.UPDATE_SIGNUP_MESSAGE_ERROR,
				payload: 'Ha ocurrido un error con el registro. Vuelva a intentarlo más tarde.',
			});
		}
	},
	resetActionSend() {
		return {
			type: authActions.RESET_ACTIONSEND,
		};
	},

	forgotPassword: (data) => async (dispatch) => {
		dispatch({
			type: authActions.VALIDATE_USER_START,
		});

		try {
			service.forgotPassword(data)
			dispatch({
				type: authActions.VALIDATE_USER_SUCCESS,
				payload: 'Tu email fue validado de forma correcta.',
			});
		} catch (error) {
			dispatch({
				type: authActions.VALIDATE_USER_ERROR,
				payload: 'Tu email no pudo ser validado.',
			});
		}
	},
};

export default authActions;
