import actions from "./bookingsListActions";

const INITIAL_PAGE_SIZE = 10;

const initialState = {
  rows: [],
  row: {},
  bookingDetail: [],
  filter: {},
  rawFilter: {},
  sorter: {},
  count: 1,
  pagination: {
    current: 1,
    pageSize: INITIAL_PAGE_SIZE,
  },
  loading: false,
};

const bookingsListReducer = (state = initialState, { type, payload }) => {
  if (type === actions.FETCH_DETAIL_STARTED) {
    return {
      ...state,
      loading: true,
    };
  }

  if (type === actions.FETCH_DETAIL_SUCCESS) {
    return {
      ...state,
      loading: false,
      bookingDetail: payload,
    };
  }

  if (type === actions.FETCH_DETAIL_ERROR) {
    return {
      ...state,
      loading: false,
      bookingDetail: [],
    };
  }

  if (type === actions.FETCH_STARTED) {
    return {
      ...state,
      loading: true,
    };
  }

  if (type === actions.FETCH_SUCCESS) {
    return {
      ...state,
      loading: false,
      rows: payload,
      count: payload.length,
    };
  }

  if (type === actions.FETCH_ERROR) {
    return {
      ...state,
      loading: false,
      rows: [],
      count: 0,
    };
  }

  if (type === actions.PAGINATION_CHANGED) {
    return {
      ...state,
      pagination: payload || {
        current: 1,
        pageSize: INITIAL_PAGE_SIZE,
      },
    };
  }

  if (type === actions.SORTER_CHANGED) {
    return {
      ...state,
      sorter: payload || {},
    };
  }

  if (type === actions.UPDATE_FILTER) {
    return {
      ...state,
      filter: payload,
    };
  }

  return state;
};

export default bookingsListReducer;
