/* eslint-disable import/no-anonymous-default-export */
import actions from "src/modules/export/export-consolidation/list/consolidatedListActions";

const INITIAL_PAGE_SIZE = 10;
const INITIAL_PAGE_SIZE_REMITOS = 4;

const initialData = {
  rows: [],
  rowsOperacionesTipo: [],
  count: 0,
  rowsTurnConsolidated: [],
  countTurnConsolidated: 0,
  rowsUserConsolidated: [],
  countUserConsolidated: 0,
  orderCount: 0,
  countTurnById: 0,
  loading: false,
  loadingTurnConsolidated: false,
  loadingTurnId: false,
  loadingOperacionesTipo: false,
  saveLoadingEmbarque: false,
  loadingUsers: false,
  initLoading: false,
  countEmbarque: 0,
  filter: {},
  filterEmbarque: {},
  filterOperation: {},
  orderFilter: {},
  turnFilter: {},
  pagination: {
    current: 1,
    pageSize: INITIAL_PAGE_SIZE,
  },
  paginationEmbarque: {
    current: 1,
    pageSize: INITIAL_PAGE_SIZE,
  },
  orderPagination: {
    current: 1,
    pageSize: INITIAL_PAGE_SIZE,
  },
  paginationRemitos: {
    current: 1,
    pageSize: INITIAL_PAGE_SIZE_REMITOS,
  },
  paginationTrucks: {
    current: 1,
    pageSize: INITIAL_PAGE_SIZE,
  },
  paginationTurn: {
    current: 1,
    pageSize: INITIAL_PAGE_SIZE,
  },
  paginationTurnById: {
    current: 1,
    pageSize: INITIAL_PAGE_SIZE,
  },

  sorter: {},
  orderSorter: {},
  sorterTurnById: {},
  turnSorter: {},
  rowById: null,
  validationHeaderRowById: true,
  trucks: [],
  remitos: [],
  truckById: {},
  commodity: [],
  transport: [],
  preStatus: [],
  status: [],
  packaging: [],
  timeFring: [],
  turnsById: [],
  turns: [],
  files: [],
  filesEmbarques: [],
  services: [],
  turnsId: [],
  articlesOrTrucksByTurn: [],
  countTrucks: 0,
  totalTrucks: 0,
  countRemitos: 0,
  totalRemitos: 0,
  statusConsolidated: [],
  getDataByTurn: [],
  loadingRemito: false,
  selectConsolidationTurn: {},
  loadingConsolidationTurn: false,
};

export default (state = initialData, { type, payload }) => {
  if (type === actions.GET_CONSOLIDATION_TURN_ID_STARTED) {
    return {
      ...state,
      loadingConsolidationTurn: true,
    };
  }

  if (type === actions.GET_CONSOLIDATION_TURN_ID_SUCCESS) {
    return {
      ...state,
      loadingConsolidationTurn: false,
      selectConsolidationTurn: payload,
    };
  }

  if (type === actions.GET_DATA_BYTURN_SUCCESS) {
    return {
      ...state,
      selectConsolidationTurn: {},
      loadingConsolidationTurn: false,
    };
  }

  if (type === actions.FETCH_STARTED) {
    return {
      ...state,
      loading: true,
      pagination: state.pagination,
    };
  }

  if (type === actions.RESET_OFFSET) {
    return {
      ...state,
      pagination: {
        current: 1,
        pageSize: INITIAL_PAGE_SIZE,
      },
    };
  }
  if (type === actions.RESET_OFFSET_TURN) {
    return {
      ...state,
      paginationTurn: {
        current: 1,
        pageSize: INITIAL_PAGE_SIZE,
      },
    };
  }

  if (type === actions.FETCH_OPERACIONES_TIPO_STARTED) {
    return {
      ...state,
      loadingOperacionesTipo: true,
    };
  }

  if (type === actions.FETCH_OPERACIONES_TIPO_SUCCESS) {
    return {
      ...state,
      loadingOperacionesTipo: false,
      rowsOperacionesTipo: payload,
    };
  }

  if (type === actions.FETCH_OPERACIONES_TIPO_ERROR) {
    return {
      ...state,
      loadingOperacionesTipo: false,
      rowsOperacionesTipo: [],
    };
  }

  if (type === actions.FETCH_SUCCESS) {
    return {
      ...state,
      loading: false,
      rows: payload.list,
      count: payload.total,
    };
  }

  if (type === actions.FETCH_ERROR) {
    return {
      ...state,
      loading: false,
      rows: [],
      count: 0,
    };
  }

  if (type === actions.RESET_OFFSET) {
    return {
      ...state,
      pagination: {
        current: 1,
        pageSize: INITIAL_PAGE_SIZE,
      },
    };
  }

  if (type === actions.FETCH_TURN_CONSOLIDATED_STARTED) {
    return {
      ...state,
      loadingTurnConsolidated: true,
      paginationTurn: state.paginationTurn,
    };
  }
  if (type === actions.FETCH_TURN_CONSOLIDATED_SUCCESS) {
    return {
      ...state,
      loadingTurnConsolidated: false,
      rowsTurnConsolidated: payload.list,
      countTurnConsolidated: payload.total,
    };
  }

  if (type === actions.FETCH_TURN_CONSOLIDATED_ERROR) {
    return {
      ...state,
      loadingTurnConsolidated: false,
      rowsTurnConsolidated: [],
      countTurnConsolidated: 0,
    };
  }
  if (type === actions.FETCH_USER_CONSOLIDATED_STARTED) {
    return {
      ...state,
      loadingUsers: true,
      pagination: state.pagination,
    };
  }

  if (type === actions.FETCH_USER_CONSOLIDATED_SUCCESS) {
    return {
      ...state,
      loadingUsers: false,
      rowsUserConsolidated: payload,
      countUserConsolidated: payload.total,
    };
  }

  if (type === actions.FETCH_USER_CONSOLIDATED_ERROR) {
    return {
      ...state,
      loadingUser: false,
      rowsUserConsolidated: [],
      countUserConsolidated: 0,
    };
  }
  if (type === actions.PAGINATION_CHANGED) {
    return {
      ...state,
      pagination: payload || {
        current: 1,
        pageSize: INITIAL_PAGE_SIZE,
      },
    };
  }
  if (type === actions.SORTER_CHANGED) {
    return {
      ...state,
      sorter: payload || {},
    };
  }

  if (type === actions.CHANGE_FILTER) {
    return {
      ...state,
      filter: payload,
    };
  }
  if (type === actions.CHANGE_FILTER_OPERATION) {
    return {
      ...state,
      filterOperation: payload,
    };
  }

  if (type === actions.CHANGE_FILTER_TURN) {
    return {
      ...state,
      turnFilter: payload,
    };
  }

  if (type === actions.SORTER_CHANGED_TURN) {
    return {
      ...state,
      turnSorter: payload || {},
    };
  }

  if (type === actions.PAGINATION_TURN_CHANGED) {
    return {
      ...state,
      paginationTurn: payload || {
        current: 1,
        pageSize: INITIAL_PAGE_SIZE,
      },
    };
  }

  if (type === actions.APPROVE_OPERATION_STARTED) {
    return {
      ...state,
      user: null,
      loading: true,
    };
  }
  if (type === actions.APPROVE_OPERATION_SUCCESS) {
    return {
      ...state,
      loading: false,
    };
  }
  if (type === actions.APPROVE_OPERATION_ERROR) {
    return {
      ...state,
      loading: false,
    };
  }
  if (type === actions.INIT_STARTED) {
    return {
      ...state,
      user: null,
      initLoading: true,
    };
  }

  if (type === actions.INIT_SUCCESS) {
    const {
      clientCompanyId,
      mercaderiaId,
      embalajeId,
      tipoDeCargaId,
      booking,
      linea,
      buque,
      cutoff,
      contenedoresTotales,
      referenciaOperativa,
      clienteFacturable,
      permisoDeEmbarque,
    } = payload;
    const result = Object.values({
      clientCompanyId,
      mercaderiaId,
      embalajeId,
      tipoDeCargaId,
      booking,
      linea,
      buque,
      cutoff,
      contenedoresTotales,
      referenciaOperativa,
      clienteFacturable,
      permisoDeEmbarque,
    }).includes(null);
    return {
      ...state,
      rowById: payload,
      initLoading: false,
      validationHeaderRowById: result,
    };
  }

  if (type === actions.INIT_ERROR) {
    return {
      ...state,
      rowById: null,
      initLoading: false,
    };
  }
  if (type === actions.GET_ALL_TRUCK_CONSOLIDATED) {
    return {
      ...state,
      loading: false,
      trucks: payload.list,
      count: payload.pageSize,
      total: payload.total,
    };
  }
  //camiones o mercaderias por id de turno
  if (type === actions.STARTED_CONSOLIDATED_BY_TURN_ID) {
    return {
      ...state,
      loading: true,
    };
  }
  if (type === actions.GET_CONSOLIDATED_BY_TURN_ID) {
    return {
      ...state,
      loading: false,
      articlesOrTrucksByTurn: payload.list,
      countTrucks: payload.total,
      // totalTrucks:payload.total
    };
  }
  //paginacion tabla mercaderias
  if (type === actions.PAGINATION_TRUCKS_CHANGED) {
    return {
      ...state,
      paginationTrucks: payload || {
        current: 1,
        pageSize: INITIAL_PAGE_SIZE,
      },
    };
  }
  if (type === actions.GET_ALL_TURN_CONSOLIDATED) {
    return {
      ...state,
      loading: false,
      loadingTurnId: false,
      turns: payload.list,
    };
  }
  //get remitos
  if (type === actions.REFRESH_REMITO) {
    return {
      remitos: [],
    };
  }
  if (type === actions.FETCH_REMITO_STARTED) {
    return {
      ...state,
      loadingRemito: true,
      paginationRemitos: state.paginationRemitos,
    };
  }
  if (type === actions.GET_ALL_REMITO) {
    return {
      ...state,
      loadingRemito: false,
      remitos: payload.list,
      countRemitos: payload.total,
      // totalRemitos:payload.total
    };
  }
  if (type === actions.DELETE_REMITO) {
    return {
      ...state,
      loadingRemito: true,
    };
  }
  if (type === actions.DELETE_TRUCK_STARTED) {
    return {
      ...state,
      loading: true,
    };
  }
  if (type === actions.DELETE_TRUCK_SUCCESS) {
    return {
      ...state,
      loading: false,
    };
  }
  if (type === actions.DELETE_TRUCK_ERROR) {
    return {
      ...state,
      loading: false,
    };
  }
  //paginacion remitos
  if (type === actions.PAGINATION_REMITOS_CHANGED) {
    return {
      ...state,
      paginationRemitos: payload || {
        current: 1,
        pageSize: INITIAL_PAGE_SIZE,
      },
    };
  }
  if (type === actions.GET_TRUCK_BY_ID) {
    return {
      ...state,
      truckById: payload,
    };
  }

  if (type === actions.GET_ALL_COMMODITY) {
    return {
      ...state,
      commodity: payload.data,
    };
  }

  if (type === actions.GET_ALL_PACKAGING) {
    return {
      ...state,
      packaging: payload.data,
    };
  }

  if (type === actions.GET_ALL_TIME) {
    return {
      ...state,
      timeFring: payload,
    };
  }

  if (type === actions.GET_ALL_TIME_ORDER) {
    return {
      ...state,
      timeFring: payload,
    };
  }

  if (type === actions.GET_ALL_PACKAGING_ORDER) {
    return {
      ...state,
      packaging: payload.data,
    };
  }

  if (type === actions.COMMODITY_PAGINATION_CHANGED) {
    return {
      ...state,
      commodity: payload.data,
    };
  }

  if (type === actions.GET_ALL_TRANSPORT) {
    return {
      ...state,
      transport: payload,
    };
  }

  if (type === actions.TRANSPORT_OPTION) {
    return {
      ...state,
      transport: payload,
    };
  }

  if (type === actions.PRE_STATUS) {
    return {
      ...state,
      preStatus: payload,
    };
  }

  if (type === actions.GET_STATUS) {
    return {
      ...state,
      status: payload,
    };
  }

  if (type === actions.GET_ATTACHMENT) {
    return {
      ...state,
      files: payload.data || [],
    };
  }

  if (type === actions.RESET_OFFSET_EMBARQUE) {
    return {
      ...state,
      paginationEmbarque: {
        current: 1,
        pageSize: INITIAL_PAGE_SIZE,
      },
    };
  }
  if (type === actions.CHANGE_FILTER_EMBARQUE) {
    return {
      ...state,
      filterEmbarque: payload,
    };
  }

  if (type === actions.PAGINATION_EMBARQUE_CHANGED) {
    return {
      ...state,
      paginationEmbarque: payload || {
        current: 1,
        pageSize: INITIAL_PAGE_SIZE,
      },
    };
  }
  if (type === actions.GET_EMBARQUES_STARTED) {
    return {
      ...state,
      loadingFilesEmbarques: true,
      paginationEmbarque: state.paginationEmbarque,
    };
  }

  if (type === actions.GET_EMBARQUES_SUCCESS) {
    return {
      ...state,
      loadingFilesEmbarques: false,
      filesEmbarques: payload.list,
      countEmbarque: payload.total,
    };
  }

  if (type === actions.GET_EMBARQUES_ERROR) {
    return {
      ...state,
      loadingFilesEmbarques: false,
      filesEmbarques: [],
    };
  }

  if (type === actions.EDIT_EMBARQUE_STARTED) {
    return {
      ...state,
      saveLoadingEmbarque: true,
    };
  }

  if (type === actions.EDIT_EMBARQUE_SUCCESS) {
    return {
      ...state,
      saveLoadingEmbarque: false,
    };
  }

  if (type === actions.EDIT_EMBARQUE_ERROR) {
    return {
      ...state,
      saveLoadingEmbarque: false,
    };
  }

  if (type === actions.DESTROY_STARTED) {
    return {
      ...state,
      loadingFilesEmbarques: true,
    };
  }

  if (type === actions.DESTROY_ERROR) {
    return {
      ...state,
      loadingFilesEmbarques: false,
    };
  }

  if (type === actions.DESTROY_SUCCESS) {
    return {
      ...state,
      loadingFilesEmbarques: false,
    };
  }

  if (type === actions.SERVICES_OPERATIONID) {
    return {
      ...state,
      services: payload,
    };
  }

  if (type === actions.GET_SUCCESS_TURN) {
    return {
      ...state,
      loadingTurnId: true,
      pagination: state.pagination,
    };
  }

  if (type === actions.FETCH_ERROR) {
    return {
      ...state,
      loadingTurnId: false,
      turnsId: [],
      count: 0,
    };
  }

  //TURNO POR ID DE CONSOLIDADO HEADER

  if (type === actions.GET_TURN_IDCONSOLIDATED) {
    return {
      ...state,
      turnsId: payload.list,
      loading: false,
      countTurnById: payload.total,
    };
  }
  if (type === actions.RESET_OFFSET_TURN_ID) {
    return {
      ...state,
      paginationTurnById: {
        current: 1,
        pageSize: INITIAL_PAGE_SIZE,
      },
    };
  }
  if (type === actions.PAGINATION_TURN_CHANGED) {
    return {
      ...state,
      paginationTurnById: payload || {
        current: 1,
        pageSize: INITIAL_PAGE_SIZE,
      },
    };
  }

  if (type === actions.SORTER_CHANGED_TURNBYID) {
    return {
      ...state,
      sorterTurnById: payload || {},
    };
  }

  if (type === actions.GET_STATUS_CONSOLIDATED) {
    return {
      ...state,
      statusConsolidated: payload,
    };
  }

  if (type === actions.GET_DATA_BYTURN_STARTED) {
    return {
      ...state,
      getDataByTurn: [],
    };
  }

  if (type === actions.GET_DATA_BYTURN_SUCCESS) {
    return {
      ...state,
      getDataByTurn: payload,
    };
  }

  if (type === actions.GET_DATA_BYTURN_ERROR) {
    return {
      ...state,
      getDataByTurn: [],
    };
  }

  return state;
};
