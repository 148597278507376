import actions from './usersDialogsActions';

const initialData = {
	add: false,
	edit: false,
	editData: null,
	close: false
};

const usersDialogsReducer = (state = initialData, { type, payload }) => {
	if (type === actions.TOGGLE_ADD) {
		return {
			...state,
			add: !state.add,
		};
	}

	if (type === actions.TOGGLE_EDIT) {
		return {
			...state,
			edit: !state.edit,
			editData: payload,
		};
	}

	if (type === actions.TOGGLE_CLOSE) {
		return {
			...state,
			close: !state.close,
		};
	}

	return state;
};

export default usersDialogsReducer;
