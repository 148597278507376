import { createSelector } from "reselect";

const selectRaw = (state) => state.providerCompany.list;

const selectRows = createSelector([selectRaw], (raw) => raw.message);

const selectRelation = createSelector([selectRaw], (raw) => raw.relation);

const selectRow = createSelector([selectRaw], (raw) => raw.rows);

const selectLoading = createSelector([selectRaw], (raw) => raw.loading);
const selectLoadingStakeholdersUsers = createSelector(
  [selectRaw],
  (raw) => raw.loadingStakeholdersUsers
);
const selectCount = createSelector([selectRaw], (raw) => raw.count);
const selectHasRows = createSelector([selectRaw], (raw) => raw.count > 0);
const selectSorter = createSelector([selectRaw], (raw) => raw.sorter || {});
const selectOrderBy = createSelector([selectRaw], (raw) => {
  const sorter = raw.sorter;

  if (!sorter) {
    return null;
  }

  if (!sorter.field) {
    return null;
  }

  let direction = sorter.order === "desc" ? "DESC" : "ASC";

  return `${sorter.field},${direction}`;
});

const selectFilter = createSelector([selectRaw], (raw) => {
  return raw.filter;
});
const selectLimit = createSelector([selectRaw], (raw) => {
  const pagination = raw.pagination;
  return pagination.pageSize;
});

const selectOffset = createSelector([selectRaw], (raw) => {
  const pagination = raw.pagination;

  if (!pagination || !pagination.pageSize) {
    return 0;
  }

  const current = pagination.current || 1;

  return current - 1;
});

const selectPagination = createSelector(
  [selectRaw, selectCount],
  (raw, count) => {
    return {
      ...raw.pagination,
      total: count,
    };
  }
);

const selectError = createSelector([selectRaw], (raw) => raw.code);

const selectStakeholder = createSelector(
  [selectRaw],
  (raw) => raw?.stakehoder?.companyName || ""
);

const selectStakeholderHasUserAdmin = createSelector([selectRaw], (raw) =>
  raw?.stakehoder?.users?.some(({ roles }) =>
    roles.some(({ name }) => name === "ADMIN")
  )
);

const selectStakeholderAdmin = createSelector(
  [selectRaw],
  (raw) =>
    raw?.stakehoder?.users?.filter(({ roles }) =>
      roles.some(({ name }) => name === "ADMIN")
    )[0] || undefined
);

const selectStakeholderUsers = createSelector(
  [selectRaw],
  (raw) => raw.stakeholdersUsers
);
const selectFilterPreview = createSelector([selectRaw], (raw) => raw.filterPreview)


const companyListSelectors = {
  selectRows,
  selectError,
  selectRow,
  selectLoadingStakeholdersUsers,
  selectLoading,
  selectStakeholder,
  selectStakeholderHasUserAdmin,
  selectStakeholderAdmin,
  selectCount,
  selectHasRows,
  selectSorter,
  selectOrderBy,
  selectFilter,
  selectPagination,
  selectLimit,
  selectOffset,
  selectRelation,
  selectStakeholderUsers,
  selectFilterPreview
};

export default companyListSelectors;
