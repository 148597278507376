import CompanyService from 'src/modules/company/companyService';
import Errors from 'src/modules/shared/error/errors';

const prefix = 'COMPANY';

const companyFormActions = {
  FETCH_STARTED: `${prefix}_FETCH_STARTED`,
  FETCH_SUCCESS: `${prefix}_FETCH_SUCCESS`,
  FETCH_ERROR: `${prefix}_FETCH_ERROR`,
  
  FETCH_CLIENT_STARTED: `${prefix}_FETCH_CLIENT_STARTED`,
  FETCH_CLIENT_SUCCESS: `${prefix}_FETCH_CLIENT_SUCCESS`,
  FETCH_CLIENT_ERROR: `${prefix}_FETCH_CLIENT_ERROR`,
  
  CODE_RESET: `${prefix}_CODE_RESET`,

  doReset: () => (dispatch) => {
    dispatch({
      type: companyFormActions.CODE_RESET,
    });
  },
  
  doFetchCompany: (cuit?, setError?) => async (
    dispatch,
    getState,
  ) => {
    try {
      dispatch({
        type: companyFormActions.FETCH_STARTED,
        payload: {cuit },
      });

      const response = await CompanyService.find(cuit);

      dispatch({
        type: companyFormActions.FETCH_SUCCESS,
        payload: response
      });

    } catch (error) {
       setError(true)
      dispatch({
        type: companyFormActions.FETCH_ERROR,
      });
    }
  },
  
  doFetchClientCompany: () => async (
    dispatch,
    getState,
  ) => {
    try {
      dispatch({
        type: companyFormActions.FETCH_CLIENT_STARTED,
      });

      const response = await CompanyService.findClient();
      
      dispatch({
        type: companyFormActions.FETCH_CLIENT_SUCCESS,
        payload: response.data[0]
      });

    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: companyFormActions.FETCH_CLIENT_ERROR,
      });
    }
  },


};

export default companyFormActions;
