export default class PermissionChecker {
  currentUser;

  constructor(currentUser) {
    this.currentUser = currentUser;
  }

  get currentUserRolesIds() {
    if (!this.currentUser) {
      return [];
    }
    return this.currentUser.roles.map((role) => role.name);
  }

  get currentUserProfile() {
    if (!this.currentUser) {
      return null;
    }
    return this.currentUser.userType;
  }

  match(permission) {
    if (!permission) {
      return true;
    }
    return this.rolesMatchOneOf(permission.allowedRoles) && this.profileMatch(permission.allowedRoles, permission.disallowedProfiles);
  }

  rolesMatchOneOf(arg) {
    if (!this.currentUserRolesIds) {
      return false;
    }

    if (!arg) {
      return false;
    }

    if (Array.isArray(arg)) {
      if (!arg.length) {
        return false;
      }

      return arg.some((role) =>
        this.currentUserRolesIds.includes(role),
      );
    }

    return this.currentUserRolesIds.includes(arg);
  }

  profileMatch(roles, disallowedProfiles) {
    if (!disallowedProfiles || !this.currentUserProfile) {
      return true;
    }
    if(roles.includes(this.currentUser.roles[0].name) && this.currentUser.roles[0].name !== 'ADMIN') {
      return true
    }

    return !disallowedProfiles.includes(this.currentUserProfile);
  }

  get isEmptyPermissions() {
    if (!this.isAuthenticated) {
      return true;
    }

    return !this.currentUser.roles || !this.currentUser.roles.length;
  }

  get isAuthenticated() {
    return (
      Boolean(this.currentUser) &&
      Boolean(this.currentUser.id)
    );
  }
}
