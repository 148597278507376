import Errors from 'src/modules/shared/error/errors';
import service from '../exportService';
import exportBookingsSelectors from './exportBookingsSelectors';

const prefix = 'EXPORT_BOOKINGS';

const exportBookingsActions = {
	FETCH_STARTED: `${prefix}_FETCH_STARTED`,
	FETCH_SUCCESS: `${prefix}_FETCH_SUCCESS`,
	FETCH_ERROR: `${prefix}_FETCH_ERROR`,
	
	FETCH_DETAILS_STARTED: `${prefix}_FETCH_DETAILS_STARTED`,
	FETCH_DETAILS_SUCCESS: `${prefix}_FETCH_DETAILS_SUCCESS`,
	FETCH_DETAILS_ERROR: `${prefix}_FETCH_DETAILS_ERROR`,

	
	FETCH_UNIT_STARTED: `${prefix}_FETCH_UNIT_STARTED`,
	FETCH_UNIT_SUCCESS: `${prefix}_FETCH_UNIT_SUCCESS`,
	FETCH_UNIT_ERROR: `${prefix}_FETCH_UNIT_ERROR`,

	PAGINATION_CHANGED: `${prefix}_PAGINATION_CHANGED`,
	SORTER_CHANGED: `${prefix}_SORTER_CHANGED`,
	CHANGE_FILTER: `${prefix}_CHANGE_FILTER`,
	RESET_OFFSET:`${prefix}_RESET_OFFSET`,
	RESET_PAGINATION: `${prefix}_RESET_PAGINATION`,

	doFetch: (data?,string?) => async (dispatch, getState) => {
		try {
			dispatch({
				type: exportBookingsActions.FETCH_STARTED,
			});

			const response = await service.getAllBookings(
				data,
				exportBookingsSelectors.selectOrderBy(getState()),
				exportBookingsSelectors.selectLimit(getState()),
				exportBookingsSelectors.selectOffset(getState()),
				exportBookingsSelectors.selectFilter(getState())
			);

			dispatch({
				type: exportBookingsActions.FETCH_SUCCESS,
				payload: response,
			});
		} catch (error) {

			Errors.showMessage(error) // chequear este error
			dispatch({
				type: exportBookingsActions.FETCH_ERROR,
			});
		}
	},
	doFetchDetails: (booking?) => async (dispatch, getState) => {
		try {
			dispatch({
				type: exportBookingsActions.FETCH_DETAILS_STARTED,
			});

			const response = await service.getBookingsDetails(booking);
			
			dispatch({
				type: exportBookingsActions.FETCH_DETAILS_SUCCESS,
				payload: response,
			});
		} catch (error) {

			Errors.showMessage(error)
			dispatch({
				type: exportBookingsActions.FETCH_DETAILS_ERROR,
			});
		}
	},
	
	doFetchUnit: (booking?, unit?) => async (dispatch, getState) => {
		try {
			dispatch({
				type: exportBookingsActions.FETCH_UNIT_STARTED,
			});
			const response = await service.getUnit(booking,unit);
			dispatch({
				type: exportBookingsActions.FETCH_UNIT_SUCCESS,
				payload: response,
			});
		} catch (error) {

			Errors.showMessage(error)
			dispatch({
				type: exportBookingsActions.FETCH_UNIT_ERROR,
			});
		}
	},

	doChangePagination: (pagination, companySelected) => async (dispatch) => {
		dispatch({
			type: exportBookingsActions.PAGINATION_CHANGED,
			payload: pagination,
		});

		if(companySelected){
			dispatch(exportBookingsActions.doFetch(companySelected,'pagination'));
		}
	},

	doChangeSort: (sorter) => async (dispatch,getState) => {
		dispatch({
			type: exportBookingsActions.SORTER_CHANGED,
			payload: sorter,
		});

		dispatch(exportBookingsActions.doFetch(null,'sorter'));
	},

	doChangeFilter: (values) => async (dispatch,getState) => {
		const currentState = getState();
		const currentCuitSelected = currentState.exportBookings?.cuit;
		if ( values.cuit && values.cuit !== currentCuitSelected) {
			dispatch(exportBookingsActions.doResetPagination()); //resetea paginacion cad vez que cambia el cuit seleccionado
		  }
		dispatch({
			type: exportBookingsActions.CHANGE_FILTER,
			payload: {values, cuit: values.cuit},
		});
		if(values.cuit){
			dispatch(exportBookingsActions.doFetch(values.cuit,'filter'));
		}
	},

	doResetPagination: () => async (dispatch) => {
		dispatch({
			type: exportBookingsActions.RESET_PAGINATION,
		});

	},

};

export default exportBookingsActions;
