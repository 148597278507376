import authAxios from '../shared/axios/authAxios';
import { TokenAzureAd } from '../auth/TokenAzureAd';
import Message from 'src/view/shared/message';

export default class UsersService {
  static async getAllUsers(orderBy?, limit?, offset?, filter?) {
    const params = {
      sort: orderBy,
      size: limit,
      page: offset,
      filter: filter,
    };
    const response = await authAxios.get('/portal/user/client-company/admin', { params });

    return response.data;
  }

  static async postPositions(positions, setLoading?,setOpenErrorModal?) {
    const token = await TokenAzureAd.getAccessToken();

    const { data} = await authAxios.post(
      '/portal/user/altaMasiva/adminCliente',
      positions.map(position => ({ ...position, token })));
      
    // const { data: arrayData } = data;

    return data;
    
   
  }



}