import { Redirect, Route, useLocation } from "react-router-dom";
import Layout from "src/view/layout/Layout";
import { useIsAuthenticated } from "@azure/msal-react";

function PrivateRoute({
  component: Component,
  currentUser,
  permissionRequired,
  ...rest
}) {
  const location = useLocation();
  const isAuthenticated = useIsAuthenticated();
  return (
    <Route
      {...rest}
      render={(props) => {
        if (!isAuthenticated) {
          return (
            <Redirect
              to={{
                pathname: "/",
                state: { from: location },
              }}
            />
          );
        }

        return (
          <Layout {...props}>
            <Component {...props} title={rest.title} />
          </Layout>
        );
      }}
    />
  );
}

export default PrivateRoute;
