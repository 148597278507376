import actions from 'src/modules/auth/authActions';

const initialData = {
	currentUser: null,
	loadingInit: true,
	loadingUpdateProfile: false,
	loading: false,
	errorMessage: null,
	errorMessageVerifyEmail: null,
	signupMessage: null,
	loadingCuitValidation: false,
	personalData: null,
	businessData: null,
	forgotPassword:null,
	forgotPasswordMessage: '',
	roles: {
		isAdmin: false,
		isConsulta: false,
		isTransaccional: false
	},
	relaciones: {
		isTZ: false,
		isCliente: false,
		isDespachante: false,
		isForwarder: false
	},
	actionSend: false
};

export default (state = initialData, { type, payload }) => {
	
	if (type === actions.ERROR_MESSAGE_CLEARED) {
		return {
			...state,
			errorMessage: null,
		};
	}

	if (type === actions.CURRENT_USER_REFRESH_SUCCESS) {
		return {
			...state,
			currentUser: payload.currentUser || null,
			roles: payload.roles,
			relaciones: payload.relaciones
		};
	}

	if (type === actions.CURRENT_USER_REFRESH_ERROR) {
		return {
			...state,
			currentUser: null,
		};
	}

	if (type === actions.AUTH_START) {
		
		return {
			...state,
			errorMessage:  "",
			loading: true,
		};
	}

	if (type === actions.AUTH_SUCCESS) {
		return {
			...state,
			currentUser: payload.currentUser || null,
			errorMessage: null,
			loading: false,
			roles: payload.roles,
			relaciones: payload.relaciones
		};
	}

	if (type === actions.AUTH_ERROR) {
		return {
			...state,
			currentUser: null,
			errorMessage: "Email o Contraseña no válido",
			loading: false,
		};
	}

	if (type === actions.UPDATE_PROFILE_START) {
		return {
			...state,
			loadingUpdateProfile: true,
		};
	}

	if (type === actions.UPDATE_PROFILE_SUCCESS) {
		return {
			...state,
			loadingUpdateProfile: false,
		};
	}

	if (type === actions.UPDATE_PROFILE_ERROR) {
		return {
			...state,
			loadingUpdateProfile: false,
		};
	}

	if (type === actions.AUTH_INIT_SUCCESS) {
		return {
			...state,
			currentUser: payload.currentUser || null,
			roles: payload.roles,
			relaciones: payload.relaciones,
			loadingInit: false,
		};
	}

	if (type === actions.AUTH_INIT_ERROR) {

		return {
			...state,
			currentUser: null,
			loadingInit: false,
		};
	}

	if (type === actions.UPDATE_SIGNUP_MESSAGE_START) {
		return {
			...state,
			actionSend: false,
			loading: true,
		};
	}

	if (type === actions.UPDATE_SIGNUP_MESSAGE_SUCCESS) {
		return {
			...state,
			loading: false,
			actionSend: true,
			signupMessage: payload,
		};
	}

	if (type === actions.RESET_ACTIONSEND) {
		return {
			...state,
			actionSend: false,
		};
	}

	if (type === actions.UPDATE_SIGNUP_MESSAGE_ERROR) {
		return {
			...state,
			loading: false,
			actionSend: true,
			signupMessage: payload,
		};
	}

	if (type === actions.VALIDATE_USER_START) {
		return {
			...state,
			forgotPassword: true,
		};
	}

	if (type === actions.VALIDATE_USER_SUCCESS) {
		return {
			...state,
			forgotPassword: false,
			forgotPasswordMessage: 'Se ha enviado un correo con los pasos a seguir para cambiar tu contraseña.',
		};
	}

	if (type === actions.VALIDATE_USER_ERROR) {
		return {
			...state,
			forgotPassword: false,
			forgotPasswordMessage: 'Tu Email no pudo ser validado.',
		};
	}

	return state;
};
