const prefix = 'PROVIDERS_DIALOGS';

const providersDialogActions = {
	TOGGLE_ADD: `${prefix}_TOGGLE_ADD`,
	TOGGLE_EDIT: `${prefix}_TOGGLE_EDIT`,
	TOGGLE_CLOSE: `${prefix}_TOGGLE_CLOSE`,

	doToggleAdd: () => async (dispatch) => {
		dispatch({
			type: providersDialogActions.TOGGLE_ADD,
		});
	},

	doToggleEdit: (data) => async (dispatch) => {
		dispatch({
			type: providersDialogActions.TOGGLE_EDIT,
			payload: data,
		});
	},

	doToggleClose: () => async (dispatch) => {
		dispatch({
			type: providersDialogActions.TOGGLE_CLOSE,
		});
	},
};

export default providersDialogActions;