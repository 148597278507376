import UserService from "src/modules/user/userService";
import service from "../usersService";
import Errors from "src/modules/shared/error/errors";
import Message from "src/view/shared/message";
import actions from 'src/modules/users/list/usersListActions'

const prefix = "USERS_FORM";

const usersFormActions = {
  POST_STARTED: `${prefix}_POST_STARTED`,
  POST_SUCCESS: `${prefix}_POST_SUCCESS`,
  POST_ERROR: `${prefix}_POST_ERROR`,

  postPositions:
    (positions, setLoading?, setOpenErrorModal?) =>
    async (dispatch, getState) => {
      try {
        dispatch({
          type: usersFormActions.POST_STARTED,
        });

        const response = await service.postPositions(positions);
        console.log(response)
        const { data: arrayData } = response
        dispatch({
          type: usersFormActions.POST_SUCCESS,
          payload: response,
        });
        dispatch(actions.doFetch());
        setOpenErrorModal({
          status: true,
          data: arrayData,
          csv:response?.csv,
          code:response?.code
        });
      } catch (error) {
        Errors.handle(error);
        dispatch({
          type: usersFormActions.POST_ERROR,
        });
      }
    },
};

export default usersFormActions;
