export let resource = ["home","import","export","export-booking","export-forewarning"];
  
  let key = "resource";
  
  export class authResource{
    static getResource(){
      let value = localStorage.getItem(key)
      if(!value) return []
      let objectValue = JSON.parse(value || "")
      return objectValue;
    }
    
    static setResource(valueResource){
      localStorage.setItem(
        key, 
        JSON.stringify(valueResource.filter(resource => resource !== 'signin'))
      );
    }
    
    static removeResource(){
      localStorage.removeItem(key)
    }
  } 
  