import Errors from 'src/modules/shared/error/errors';
import service from '../relationsService';
import relationsListSelectors from './relationsListSelectors';

const prefix = 'RELATIONS_LIST';

const relationsListActions = {
	FETCH_STARTED: `${prefix}_FETCH_STARTED`,
	FETCH_SUCCESS: `${prefix}_FETCH_SUCCESS`,
	FETCH_ERROR: `${prefix}_FETCH_ERROR`,
	PAGINATION_CHANGED: `${prefix}_PAGINATION_CHANGED`,
	SORTER_CHANGED: `${prefix}_SORTER_CHANGED`,
	CHANGE_FILTER: `${prefix}_CHANGE_FILTER`,
	FETCH_ROLES: `${prefix}_FETCH_ROLES`,
	FETCH_USER_TYPES: `${prefix}_FETCH_USER_TYPES`,
	EDIT_SUCCESS:`${prefix}_EDIT_SUCCESS`,


	doFetch: () => async (dispatch, getState) => {
		try {
			dispatch({
				type: relationsListActions.FETCH_STARTED,
			});

			const response = await service.getAllRelations(
				relationsListSelectors.selectOrderBy(getState()),
				relationsListSelectors.selectLimit(getState()),
				relationsListSelectors.selectOffset(getState()),
				relationsListSelectors.selectFilter(getState())
			);

			dispatch({
				type: relationsListActions.FETCH_SUCCESS,
				payload: response,
			});
		} catch (error) {
			Errors.handle(error);

			dispatch({
				type: relationsListActions.FETCH_ERROR,
			});
		}
	},

	doChangePagination: (pagination) => async (dispatch) => {
		dispatch({
			type: relationsListActions.PAGINATION_CHANGED,
			payload: pagination,
		});

		dispatch(relationsListActions.doFetch());
	},

	doChangeSort: (sorter) => async (dispatch) => {
		dispatch({
			type: relationsListActions.SORTER_CHANGED,
			payload: sorter,
		});

		dispatch(relationsListActions.doFetch());
	},

	doChangeFilter: (values) => async (dispatch) => {
		dispatch({
			type: relationsListActions.CHANGE_FILTER,
			payload: values,
		});

		dispatch(relationsListActions.doFetch());
	},

	doAdd: (data) => async (dispatch) => {
		try {
			await service.addRelation(data);
			dispatch(relationsListActions.doFetch());
		} catch (error) {
			Errors.handle(error);
		}
	},

	doEdit: (data) => async (dispatch) => {
		try {
			await service.editRelation(data);
			dispatch(relationsListActions.doFetch());
			
		} catch (error) {
			Errors.handle(error);
		}
	},

	doDeactivate: (id) => async (dispatch) => {
		try {
			await service.deactivateRelation(id);
			dispatch(relationsListActions.doFetch());
		} catch (error) {
			Errors.handle(error);
		}
	},

	doFetchRoles: () => async (dispatch) => {
		try {
			const response = await service.getRoles();

			dispatch({
				type: relationsListActions.FETCH_ROLES,
				payload: response,
			});
		} catch (error) {
			Errors.handle(error);
		}
	},
};

export default relationsListActions;
