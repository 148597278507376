import actions from './exportBookingsActions';

const INITIAL_PAGE_SIZE = 10;

const initialState = {
	rows: [],
	count: 0,
	loading: false,
	filter: {},
	pagination: {
		current: 1,
		pageSize: INITIAL_PAGE_SIZE,
	},
	sorter: {},
	loadingDetails: false,
	bookingDetails: {},
	cuitSelected: undefined
};

const exportBookingsReducers = (state = initialState, { type, payload }) => {
	if (type === actions.RESET_PAGINATION) {
		return {
			...state,
			pagination: {
				current: 1,
				pageSize: INITIAL_PAGE_SIZE,
			},
		};
	}

	if (type === actions.FETCH_STARTED) {
		return {
			...state,
			loading: true,
			pagination: state.pagination,
		};
	}

	if (type === actions.FETCH_SUCCESS) {
		return {
			...state,
			loading: false,
			rows: payload.data,
			count: payload.total,
		};
	}

	if (type === actions.FETCH_ERROR) {
		return {
			...state,
			loading: false,
			rows: [],
			count: 0,
		};
	}

	if (type === actions.FETCH_DETAILS_STARTED) {
		return {
			...state,
			loadingDetails: true,
		};
	}

	if (type === actions.FETCH_DETAILS_SUCCESS) {
		return {
			...state,
			loadingDetails: false,
			bookingDetails: payload.data,
		};
	}

	if (type === actions.FETCH_DETAILS_ERROR) {
		return {
			...state,
			loadingDetails: false,
			bookingDetails: {},
		};
	}

	if (type === actions.PAGINATION_CHANGED) {
		return {
			...state,
			pagination: payload || {
				current: 1,
				pageSize: INITIAL_PAGE_SIZE,
			},
		};
	}

	if (type === actions.SORTER_CHANGED) {
		return {
			...state,
			sorter: payload || {},
		};
	}

	if (type === actions.CHANGE_FILTER) {
		return {
			...state,
			filter: payload.values,
			cuitSelected: payload.cuit
		};
	}

	return state;
};

export default exportBookingsReducers;
