let notistakEnqueueSnackbar;

export default class Message {
  static registerNotistakEnqueueSnackbar(instance) {
    notistakEnqueueSnackbar = instance;
  }

  static success(arg) {
    notistakEnqueueSnackbar(arg, {variant: 'success'});
  }

  static error(arg) {
    notistakEnqueueSnackbar(arg, { variant: 'error' });
  }

  static info(arg) {
    notistakEnqueueSnackbar(arg, { variant: 'info'});
  }

  static messageType(arg, variant) {
    notistakEnqueueSnackbar(arg, { variant: variant.toLowerCase() });
  }

  static war(arg) {
    notistakEnqueueSnackbar(arg, { variant: 'warning'});
  }
}
