import { msalConfig, silentRequest } from "src/authConfig";
import { PublicClientApplication } from "@azure/msal-browser";
import Errors from "../shared/error/errors";

const myMSALObj = new PublicClientApplication(msalConfig);

const getTokenRedirect = async (request) => {
  try {
    let accounts = myMSALObj.getAllAccounts();
    if(!accounts || accounts.length === 0) return null;

    request.account = accounts[0];
    return await myMSALObj.acquireTokenSilent(request);
  } catch (error) {
    console.error(Errors.selectMessage(error),"slkdj");
    Errors.signout();
  }
};

export class TokenAzureAd {
  static async getAccessToken() {
    try {
      let response = await getTokenRedirect(silentRequest);
      return response && response?.accessToken;
    } catch (error) {
    }
  }

  static async getIdToken() {
    try {
      let response = await getTokenRedirect(silentRequest);
      if(response == null) return null;
      return response && response?.idToken;
    } catch (error) {
    }
  }
}
