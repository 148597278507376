import actions from './relationsListActions';

const INITIAL_PAGE_SIZE = 9;

const initialState = {
	rows: [],
	count: 0,
	loading: false,
	filter: { userType: 'DESPACHANTE', active: 1 },
	pagination: {
		current: 1,
		pageSize: INITIAL_PAGE_SIZE,
	},
	sorter: {},
	roles: [],
	userTypes: [],
	message:'',
};

const relationsListReducer = (state = initialState, { type, payload }) => {
	if (type === actions.FETCH_STARTED) {
		return {
			...state,
			loading: true,
			pagination: state.pagination,
		};
	}

	if (type === actions.FETCH_SUCCESS) {
		return {
			...state,
			loading: false,
			rows: payload.list,
			count: payload.total,
			message:'Relación Guardada Exitosamente'
		};
	}

	if (type === actions.FETCH_ERROR) {
		return {
			...state,
			loading: false,
			rows: [],
			count: 0,
			message:'Error al guardar Relación'
		};
	}

	if (type === actions.PAGINATION_CHANGED) {
		return {
			...state,
			pagination: payload || {
				current: 1,
				pageSize: INITIAL_PAGE_SIZE,
			},
		};
	}

	if (type === actions.SORTER_CHANGED) {
		return {
			...state,
			sorter: payload || {},
		};
	}

	if (type === actions.CHANGE_FILTER) {
		return {
			...state,
			filter: payload,
		};
	}

	if (type === actions.FETCH_ROLES) {
		return {
			...state,
			roles: payload,
		};
	}

	if (type === actions.FETCH_USER_TYPES) {
		return {
			...state,
			userTypes: payload,
		};
	}



	return state;
};

export default relationsListReducer;
